import BinaryModelBase, { BinaryValueType } from '@shared/models/binary'
import ChatMessageModel, { ChatMessageJSONModel } from '@shared/models/chat/chat-message'

export interface PushChatReqJSONModel {
  roomId: string
  msg: ChatMessageJSONModel
}

export default class PushChatReqModel extends BinaryModelBase<PushChatReqModel> {
  /// Public ///

  public constructor() {
    super()

    this.roomId = 0n
    this.msg = new ChatMessageModel()

    this.addField('RoomId', { type: BinaryValueType.UINT64 })
    this.addField('Msg', { type: BinaryValueType.BIN, ctor: ChatMessageModel })
  }

  public getRoomId(): bigint {
    return this.roomId
  }

  public getMsg(): ChatMessageModel {
    return this.msg
  }

  public setRoomId(roomId: bigint): this {
    this.roomId = roomId
    return this
  }

  public setMsg(msg: ChatMessageModel): this {
    this.msg = msg
    return this
  }

  public toJSONObject(): PushChatReqJSONModel {
    const { roomId, msg } = this

    return {
      roomId: roomId.toString(),
      msg: msg.toJSONObject()
    }
  }

  public fromJSONObject(json: PushChatReqJSONModel): this {
    this.validate(json, 'roomId', 'string')
    this.validate(json, 'msg', 'object')

    const { roomId, msg } = json

    this.roomId = BigInt(roomId || '0')
    this.msg.fromJSONObject(msg)

    return this
  }

  /// Private ///

  private roomId: bigint
  private msg: ChatMessageModel

  /// Binary getter/setter ///

  public get RoomId(): bigint {
    return this.roomId
  }

  public get Msg(): ChatMessageModel {
    return this.msg
  }

  public set RoomId(value: bigint) {
    this.roomId = value
  }

  public set Msg(value: ChatMessageModel) {
    this.msg = value
  }
}