import BottomNav from '@/components/navigation/bottom-nav'
import TopNav from '@/components/navigation/top-nav'
import { ReactElement } from 'react'
import VerticalScrollLayout from './vertical-scroll'

export type DualNavLayoutProps = {
  className?: string
  children?: ReactElement[] | ReactElement
}

export default function DualNavLayout({ className, children }: Readonly<DualNavLayoutProps>): ReactElement {
  return (
    <div className='flex flex-col h-full overflow-hidden bg-white dark:bg-dark text-black dark:text-white'>
      {/* Top Navbar */}
      <TopNav />

      {/* Main Content */}
      <VerticalScrollLayout className={className}>{children}</VerticalScrollLayout>

      {/* Bottom Navbar */}
      <BottomNav />
    </div>
  )
}
