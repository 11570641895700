import BinaryModelBase, { BinaryValueType } from '@shared/models/binary'

export interface UserSimpleJSONModel {
  id: string
  thumbnailUrl: string
  displayName: string
}

export default class UserSimpleModel extends BinaryModelBase<UserSimpleModel> {
  /// Public ///

  public constructor() {
    super()

    this.id = ''
    this.thumbnailUrl = ''
    this.displayName = ''

    this.addField('Id', { type: BinaryValueType.STRING })
    this.addField('ThumbnailUrl', { type: BinaryValueType.STRING })
    this.addField('DisplayName', { type: BinaryValueType.STRING })
  }

  public getId(): string {
    return this.id
  }

  public getThumbnailUrl(): string {
    return this.thumbnailUrl
  }

  public getDisplayName(): string {
    return this.displayName
  }

  public setId(id: string): this {
    this.id = id
    return this
  }

  public setThumbnailUrl(thumbnailUrl: string): this {
    this.thumbnailUrl = thumbnailUrl
    return this
  }

  public setDisplayName(displayName: string): this {
    this.displayName = displayName
    return this
  }

  public toJSONObject(): UserSimpleJSONModel {
    const { id, thumbnailUrl, displayName } = this

    return {
      id,
      thumbnailUrl,
      displayName
    }
  }

  public fromJSONObject(json: UserSimpleJSONModel): this {
    this.validate(json, 'id', 'string')
    this.validate(json, 'thumbnailUrl', 'string')
    this.validate(json, 'displayName', 'string')

    const { id, thumbnailUrl, displayName } = json

    this.id = id
    this.thumbnailUrl = thumbnailUrl
    this.displayName = displayName

    return this
  }

  /// Private ///

  private id: string
  private thumbnailUrl: string
  private displayName: string

  /// Binary getter/setter ///

  public get Id(): string {
    return this.id
  }

  public get ThumbnailUrl(): string {
    return this.thumbnailUrl
  }

  public get DisplayName(): string {
    return this.displayName
  }

  public set Id(value: string) {
    this.id = value
  }

  public set ThumbnailUrl(value: string) {
    this.thumbnailUrl = value
  }

  public set DisplayName(value: string) {
    this.displayName = value
  }
}