import BinaryModelBase, { BinaryValueType } from '@shared/models/binary'
import { Retcode } from './index'

/*
0xSTII
S = System
T = Type (1 = Req, 2 = Rsp, 3 = CS Notify, 4 = SC Notify)
I = ID
*/
export enum ApiSocketCmdId {
  CMD_NONE = 0x0000,

  // Chat system
  CMD_CHAT_PULL = 0x2000,
  CMD_CHAT_PULL_REQ = 0x2100,
  CMD_CHAT_PULL_RSP = 0x2200,

  CMD_CHAT_PUSH = 0x2001,
  CMD_CHAT_PUSH_REQ = 0x2101,
  CMD_CHAT_PUSH_RSP = 0x2201,

  CMD_CHAT_DEL = 0x2002,
  CMD_CHAT_DEL_REQ = 0x2102,
  CMD_CHAT_DEL_RSP = 0x2202,
  CMD_CHAT_DEL_SC_NOTIFY = 0x2402
}

export interface ApiSocketRspJSONModel {
  cmdId: ApiSocketCmdId
  retcode: Retcode
  msg: string
  data: object | null
}

export class ApiSocketRspModel<T extends BinaryModelBase = BinaryModelBase> extends BinaryModelBase<ApiSocketRspModel<T>> {
  /// Public ///

  public constructor(ctor?: new () => T) {
    super()

    this.dataCtor = ctor ?? null

    this.cmdId = ApiSocketCmdId.CMD_NONE
    this.retcode = Retcode.RET_SUCC
    this.msg = ''
    this.data = null

    this.addField('CmdId', { type: BinaryValueType.UINT16 })
    this.addField('Retcode', { type: BinaryValueType.INT32 })
    this.addField('Msg', { type: BinaryValueType.STRING })
    this.addField('Data', { type: BinaryValueType.BIN, optional: true, ctor })
  }

  public getCmdId(): ApiSocketCmdId {
    return this.cmdId
  }

  public getRetcode(): Retcode {
    return this.retcode
  }

  public getMsg(): string {
    return this.msg
  }

  public getData(): T | null {
    return this.data
  }

  public setCmdId(value: ApiSocketCmdId): this {
    this.cmdId = value
    return this
  }

  public setRetcode(value: Retcode): this {
    this.retcode = value
    return this
  }

  public setMsg(value: string): this {
    this.msg = value
    return this
  }

  public setData(value: T | null): this {
    this.data = value
    return this
  }

  public toJSONObject(): ApiSocketRspJSONModel {
    const { cmdId, retcode, msg, data } = this

    return {
      cmdId,
      retcode,
      msg,
      data: data?.toJSONObject() ?? null
    }
  }

  public fromJSONObject(json: ApiSocketRspJSONModel): this {
    const { dataCtor } = this

    this.validate(json, 'cmdId', 'number')
    this.validate(json, 'data', 'object', true)

    const { cmdId, retcode, msg, data } = json

    if (Retcode[retcode] == null) throw new Error("invalid value for 'retcode'")

    this.cmdId = cmdId
    this.retcode = retcode
    this.msg = msg

    if (dataCtor == null || data == null) {
      this.data = null
    } else {
      this.data = new dataCtor()
      this.data.fromJSONObject(data)
    }

    return this
  }

  /// Private ///

  private dataCtor: (new () => T) | null

  private cmdId: ApiSocketCmdId
  private retcode: Retcode
  private msg: string
  private data: T | null

  /// Binary getter/setter ///

  public get CmdId(): number {
    return this.cmdId
  }

  public get Retcode(): Retcode {
    return this.retcode
  }

  public get Msg(): string {
    return this.msg
  }

  public get Data(): T | null {
    return this.data
  }

  public set CmdId(value: number) {
    this.cmdId = value
  }

  public set Retcode(value: Retcode) {
    this.retcode = value
  }

  public set Msg(value: string) {
    this.msg = value
  }

  public set Data(value: T | null) {
    this.data = value
  }
}