import ModelBase from '@shared/models/base'

export interface EventAttendAnswerJSONModel {
  question: string
  answer: string
}

export default class EventAttendAnswerModel extends ModelBase {
  /// Public ///
  public constructor() {
    super()

    this.question = ''
    this.answer = ''
  }

  public getQuestion(): string {
    return this.question
  }

  public getAnswer(): string {
    return this.answer
  }

  public setQuestion(question: string): this {
    this.question = question
    return this
  }

  public setEventId(answer: string): this {
    this.answer = answer
    return this
  }

  public toJSONObject(): EventAttendAnswerJSONModel {
    const { question, answer } = this

    return {
      question,
      answer,
    }
  }

  public fromJSONObject(json: EventAttendAnswerJSONModel): this {
    this.validate(json, 'question', 'string')
    this.validate(json, 'answer', 'string')

    const { question, answer } = json

    this.question = question
    this.answer = answer

    return this
  }

  /// Private ///
  private question: string
  private answer: string
}
