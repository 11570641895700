import { FullScreenModalLayout } from '@/components/layout/full-screen-modal'
import Protected from '@/components/protected'
import { AuthState } from '@/constants/auth'
import { ReactElement } from 'react'

export default function SettingsPage(): ReactElement {
  return (
    <Protected state={AuthState.LOGIN} redirect className='h-full'>
      <FullScreenModalLayout closable={true}>
        <div />
      </FullScreenModalLayout>
    </Protected>
  )
}