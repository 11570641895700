export default abstract class ModelBase<TModel extends ModelBase = any> {
  /// Public ///

  public toJSONObject(filter?: (key: keyof TModel) => boolean): Partial<TModel> {
    const obj: Partial<TModel> = {}
    for (const key in <TModel><ModelBase>this) {
      if ((filter != null && !filter(key)) || typeof (<TModel><ModelBase>this)[key] === 'function') continue
      obj[key] = (<TModel><ModelBase>this)[key]
    }
    return obj
  }

  public abstract fromJSONObject(json: object): this

  public toJSONString(): string {
    return JSON.stringify(this.toJSONObject())
  }

  public fromJSONString(json: string): this {
    let jsonObj = {}

    try {
      jsonObj = JSON.parse(json)
    } catch (err) {
      console.warn('json decode error:', (<Error>err).message)
    }

    return this.fromJSONObject(jsonObj)
  }

  /// Protected ///

  protected validate<TJson extends { [key: string]: any }>(
    json: TJson,
    key: keyof TJson,
    expectedType: 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'object' | 'array',
    isOptional = false
  ): void {
    const value = json[key]

    // Check if optional
    if (isOptional && value == null) return

    // Get value type
    const actualType = typeof value

    // Check if type is valid
    if (value != null && (expectedType === 'array' ? Array.isArray(value) : actualType === expectedType)) return

    throw new Error(`invalid type '${actualType}' for '${key.toString()}', expected type '${expectedType}'`)
  }
}