import UserSimpleModel, { UserSimpleJSONModel } from '@shared/models/user/simple'
import BinaryModelBase, { BinaryValueType } from '../binary'

export interface ChatRoomJSONModel {
  id: string
  ownerId: string | null
  memberList: UserSimpleJSONModel[]
}

export default class ChatRoomModel extends BinaryModelBase<ChatRoomModel> {

  /// Public ///

  public constructor() {
    super()

    this.id = 0n
    this.ownerId = null
    this.memberList = []

    this.addField('Id', { type: BinaryValueType.UINT64 })
    this.addField('OwnerId', { type: BinaryValueType.STRING, optional: true })
    this.addField('MemberList', { type: BinaryValueType.ARRAY, value: { type: BinaryValueType.BIN, ctor: UserSimpleModel } })
  }

  public getId(): bigint {
    return this.id
  }

  public getOwnerId(): string | null {
    return this.ownerId
  }

  public getMemberList(): UserSimpleModel[] {
    return [...this.memberList]
  }

  public setId(id: bigint): this {
    this.id = id
    return this
  }

  public setOwnerId(ownerId: string | null): this {
    this.ownerId = ownerId
    return this
  }

  public setMemberList(memberList: UserSimpleModel[]): this {
    this.memberList = memberList
    return this
  }

  public toJSONObject(): ChatRoomJSONModel {
    const { id, ownerId, memberList } = this

    return {
      id: id.toString(),
      ownerId,
      memberList: memberList.map(m => m.toJSONObject())
    }
  }

  public fromJSONObject(json: ChatRoomJSONModel): this {
    this.validate(json, 'id', 'string')
    this.validate(json, 'ownerId', 'string', true)
    this.validate(json, 'memberList', 'array')

    const { id, ownerId, memberList } = json

    if (memberList.find(v => typeof v !== 'object') != null) throw new Error(`invalid array value type 'memberList'`)

    this.id = BigInt(id || '0')
    this.ownerId = ownerId
    this.memberList = memberList.map(m => new UserSimpleModel().fromJSONObject(m))

    return this
  }

  /// Private ///

  private id: bigint
  private ownerId: string | null
  private memberList: UserSimpleModel[]

  /// Binary getter/setter ///

  public get Id(): bigint {
    return this.id
  }

  public get OwnerId(): string | null {
    return this.ownerId
  }

  public get MemberList(): UserSimpleModel[] {
    return this.memberList
  }

  public set Id(value: bigint) {
    this.id = value
  }

  public set OwnerId(value: string | null) {
    this.ownerId = value
  }

  public set MemberList(value: UserSimpleModel[]) {
    this.memberList = value
  }
}