import ModelBase from '@shared/models/base'

export interface UpdateEventReqJSONModel {
  eventId: string
  name: string
  description: string
  images: string
  placeId: string
  locationName: string
  locationLat: number
  locationLng: number
  tags: string
  category: string
  website: string
  beginTime: number
  endTime: number
  price: number
  maxAttendees: number
}

export default class UpdateEventReqModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.eventId = ''
    this.name = ''
    this.description = ''
    this.images = ''
    this.placeId = ''
    this.locationName = ''
    this.locationLat = 0
    this.locationLng = 0
    this.tags = ''
    this.category = ''
    this.website = ''
    this.beginTime = 0
    this.endTime = 0
    this.price = 0
    this.maxAttendees = 0
  }

  public getEventId(): string {
    return this.eventId
  }

  public getName(): string {
    return this.name
  }

  public getDescription(): string {
    return this.description
  }

  public getImages(): string {
    return this.images
  }

  public getPlaceId(): string {
    return this.placeId
  }

  public getLocationName(): string {
    return this.locationName
  }

  public getLocationLat(): number {
    return this.locationLat
  }

  public getLocationLng(): number {
    return this.locationLng
  }

  public getTags(): string {
    return this.tags
  }

  public getCategory(): string {
    return this.category
  }

  public getWebsite(): string {
    return this.website
  }

  public getBeginTime(): Date {
    return new Date(this.beginTime)
  }

  public getEndTime(): Date {
    return new Date(this.endTime)
  }

  public getPrice(): number {
    return this.price
  }

  public getMaxAttendees(): number {
    return this.maxAttendees
  }

  public toJSONObject(): UpdateEventReqJSONModel {
    const {
      eventId,
      name,
      description,
      images,
      placeId,
      locationName,
      locationLat,
      locationLng,
      tags,
      category,
      website,
      beginTime,
      endTime,
      price,
      maxAttendees
    } = this

    const json: UpdateEventReqJSONModel = {
      eventId,
      name,
      description,
      images,
      placeId,
      locationName,
      locationLat,
      locationLng,
      tags,
      category,
      website,
      beginTime,
      endTime,
      price,
      maxAttendees
    }

    return json
  }

  public fromJSONObject(json: UpdateEventReqJSONModel): this {
    this.validate(json, 'eventId', 'string')
    this.validate(json, 'name', 'string')
    this.validate(json, 'description', 'string')
    this.validate(json, 'images', 'string')
    this.validate(json, 'placeId', 'string')
    this.validate(json, 'locationName', 'string')
    this.validate(json, 'locationLat', 'number')
    this.validate(json, 'locationLng', 'number')
    this.validate(json, 'tags', 'string')
    this.validate(json, 'category', 'string')
    this.validate(json, 'website', 'string')
    this.validate(json, 'beginTime', 'number')
    this.validate(json, 'endTime', 'number')
    this.validate(json, 'price', 'number')
    this.validate(json, 'maxAttendees', 'number')

    const {
      eventId,
      name,
      description,
      images,
      placeId,
      locationName,
      locationLat,
      locationLng,
      tags,
      category,
      website,
      beginTime,
      endTime,
      price,
      maxAttendees
    } = json

    this.eventId = eventId
    this.name = name
    this.description = description
    this.images = images
    this.placeId = placeId
    this.locationName = locationName
    this.locationLat = locationLat
    this.locationLng = locationLng
    this.tags = tags
    this.category = category
    this.website = website
    this.beginTime = beginTime
    this.endTime = endTime
    this.price = price
    this.maxAttendees = maxAttendees

    return this
  }

  /// Private ///

  private eventId: string
  private name: string
  private description: string
  private images: string
  private placeId: string
  private locationName: string
  private locationLat: number
  private locationLng: number
  private tags: string
  private category: string
  private website: string
  private beginTime: number
  private endTime: number
  private price: number
  private maxAttendees: number
}