import { faCheck, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Text, Center } from '@mantine/core'
import { useTranslation } from 'react-i18next'

interface PasswordRequirementProps {
  meets: boolean
  label: string
}

const PasswordRequirement: React.FC<PasswordRequirementProps> = ({ meets, label }) => {
  const { t } = useTranslation('auth')

  return (
    <Text component="div" c={meets ? 'teal' : 'red'} mt={5} size="sm" className="font-sans">
      <Center inline>
        {meets ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faX} />}
        <Box ml={7}>{t(label)}</Box>
      </Center>
    </Text>
  )
}

export default PasswordRequirement
