export function SnakeToCamel(n: string): string {
  return n.replace(/_[a-z]/g, s => s[1].toUpperCase())
}

export function SnakeToPascal(n: string): string {
  n = SnakeToCamel(n)
  return n[0].toUpperCase() + n.slice(1)
}

export function KebabToCamel(n: string): string {
  return SnakeToCamel(n.replace(/-/g, '_'))
}

export function KebabToPascal(n: string): string {
  return SnakeToPascal(n.replace(/-/g, '_'))
}

export function CamelToSnake(n: string): string {
  const prevUSCount = /^_+/.exec(n)?.[0]?.length ?? 0
  n = n.replace(/[A-Z]+/g, s => '_' + s.toLowerCase())
  const currUSCount = /^_+/.exec(n)?.[0]?.length ?? 0

  return n.slice(currUSCount - prevUSCount)
}

export function PascalToSnake(n: string): string {
  return n[0].toLowerCase() + CamelToSnake(n.slice(1))
}

export function CamelToKebab(n: string): string {
  return CamelToSnake(n).replace(/_/g, '-')
}

export function PascalToKebab(n: string): string {
  return PascalToSnake(n).replace(/_/g, '-')
}