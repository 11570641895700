import { ReactElement } from 'react'

export type VerticalScrollLayoutProps = {
  className?: string
  children?: ReactElement[] | ReactElement
}

export default function VerticalScrollLayout({ className, children }: Readonly<VerticalScrollLayoutProps>): ReactElement {
  return (
    <div className='h-full overflow-x-hidden overflow-y-auto'>
      <div className={`min-h-full h-fit container mx-auto p-0 sm:p-4 ${className ?? ''}`}>
        {children}
      </div>
    </div>
  )
}