import Alert, { AlertType } from '@/components/alert'
import DualNavLayout from '@/components/layout/dual-nav'
import EventCardGrid from '@/components/profile/event-card-grid'
import MemberCard from '@/components/profile/member-card'
import Protected from '@/components/protected'
import { AuthState } from '@/constants/auth'
import { Retcode } from '@/models/api'
import classes from '@/styles/profile-tabs.module.scss'
import Api from '@/utils/api'
import Auth from '@/utils/auth'
import { getErrorMessage } from '@/utils/error'
import { faCalendar, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Card, Grid, Group, SimpleGrid, Stack, Switch, Tabs, Text } from '@mantine/core'
import GetEventListRspModel from '@shared/models/api/response/get-event-list'
import EventModel from '@shared/models/event'
import UserModel from '@shared/models/user'
import { ReactElement, useEffect, useState } from 'react'
import { fetchEventImageUrl } from '@/utils/firebase'
import DateTime from '@/utils/datetime'
import { useTheme } from '@/context/theme'
import { useTranslation } from 'react-i18next'
import i18n from '@/i18n'

type Stat = {
  value: string
  label: string
}

type Stats = Record<string, Stat[]>

const DEMO_STATS: Stats = {
  NJgzwsIz93Rl4p6j8Kk5C5G0HjC3: [
    // sunnywong.family
    { value: '0', label: 'Followers' },
    { value: '5', label: 'Follows' },
    // { value: '8', label: 'Events' },
  ],
  pkXtMSNMJMV1CJr31RPcLdpxZLd2: [
    // yiu123456789889
    { value: '1', label: 'Followers' },
    { value: '3', label: 'Follows' },
    // { value: '8', label: 'Events' },
  ],
  aHYzLUT93MMriRjxBL7MqF0RWWQ2: [
    // pip2d06b
    { value: '4', label: 'Followers' },
    { value: '6', label: 'Follows' },
    // { value: '8', label: 'Events' },
  ],
  AVXLKFrfImWesioRuntZgaQYBVM2: [
    // apip2d06ba
    { value: '9', label: 'Followers' },
    { value: '1', label: 'Follows' },
    // { value: '8', label: 'Events' },
  ],
  rsnMsCUOD0NPSgCG95065SsU7N72: [
    // hhin0001
    { value: '2', label: 'Followers' },
    { value: '12', label: 'Follows' },
    // { value: '8', label: 'Events' },
  ],
  mvnGfKJMqnOZ4VKPfmLYLtOVLC42: [
    // lighteninggames
    { value: '8', label: 'Followers' },
    { value: '16', label: 'Follows' },
    // { value: '8', label: 'Events' },
  ],
  xRsLMrAq9kNgkx2LR7F0WqXNX1y1: [
    // 01game01play
    { value: '34', label: 'Followers' },
    { value: '66', label: 'Follows' },
    // { value: '8', label: 'Events' },
  ],
  '8oEhSxHYXkgKrhTtLtDkZPTlfaZ2': [
    // 220068195@stu.vtc.edu.hk
    { value: '12', label: 'Followers' },
    { value: '8', label: 'Follows' },
    // { value: '8', label: 'Events' },
  ],
}

const DEMO_DATA = [
  {
    organizerId: 'pkXtMSNMJMV1CJr31RPcLdpxZLd2', // yiu123456789889
    organizerName: 'Badminton In HongKong (P.O.B.C.)',
    role: 'Event Organizer',
    icon: 'assets/images/logo.png',
    url: '/profile/badminton',
  },
  {
    organizerId: 'aHYzLUT93MMriRjxBL7MqF0RWWQ2', //
    organizerName: 'Hong Kong Machine Learning',
    role: 'Co-Organizer',
    icon: 'assets/images/logo.png',
    url: '/profile/hkml',
  },
  {
    organizerId: 'xRsLMrAq9kNgkx2LR7F0WqXNX1y1', //
    organizerName: 'OneCodeCamp Asia',
    role: 'Volunteer Coordinator',
    icon: 'assets/images/logo.png',
    url: '/profile/onecodecamp',
  },
  {
    organizerId: 'mvnGfKJMqnOZ4VKPfmLYLtOVLC42', //
    organizerName: 'HK Web Dev',
    role: 'Organizer',
    icon: 'assets/images/logo.png',
    url: '/profile/hkwebdev',
  },
  {
    organizerId: 'rsnMsCUOD0NPSgCG95065SsU7N72', //
    organizerName: 'Fruits in Suits LGBT Social & Business Networking',
    role: 'Member',
    icon: 'assets/images/logo.png',
    url: '/profile/fruitsinsuits',
  },
]

export default function ProfilePage(): ReactElement {
  const { t } = useTranslation('common')
  const [alert, setAlert] = useState<[AlertType, string] | null>(null)
  const [currUser, setCurrUser] = useState<UserModel | null>(null)
  const [selfEventList, setSelfEventList] = useState<EventModel[]>([])
  const [joinedEventList, setJoinedEventList] = useState<EventModel[]>([])
  const [iconUrl, setIconUrl] = useState<string>('')
  const [items, setItems] = useState<ReactElement[]>([])
  const joinTime = new DateTime(currUser?.getCreateTime())
  const { theme, toggleTheme } = useTheme()
  const [checked, setChecked] = useState(false)

  async function onLogout(): Promise<void> {
    try {
      await Auth.logout()
    } catch (err) {
      // TODO: Show error message
    }
  }

  useEffect(() => {
    Auth.getAuthState().then(() => {
      setCurrUser(Auth.currentUser)
      Api.sendRequest('/event/getSelfEventList', GetEventListRspModel)
        .then((rsp) => {
          if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

          const data = rsp.getData()
          if (data == null) throw new Error('data == null')

          setSelfEventList(data.getEventList())
        })
        .catch((err) => setAlert(['danger', getErrorMessage(err)]))

      Api.sendRequest('/event/getJoinedEventList', GetEventListRspModel)
        .then((rsp) => {
          if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

          const data = rsp.getData()
          if (data == null) throw new Error('data == null')

          setJoinedEventList(data.getEventList())
        })
        .catch((err) => setAlert(['danger', getErrorMessage(err)]))

      const userStats = DEMO_STATS[Auth.currentUser?.getId() ?? '']
      setItems(
        userStats?.map((stat, index) => (
          <div key={index}>
            <Text ta="center" fz="lg" fw={500}>
              {stat.value}
            </Text>
            <Text ta="center" fz="sm" c="dimmed" lh={1}>
              {stat.label}
            </Text>
          </div>
        )) ?? [],
      )
    })
  }, [])

  // Fetch icon url
  useEffect(() => {
    if (currUser == null) return
    const loadImage = async () => {
      const url = await fetchEventImageUrl(currUser.getThumbnailUrl())
      if (url != null) setIconUrl(url)
    }

    loadImage()
  }, [currUser])

  useEffect(() => {
    if (checked) {
      i18n.changeLanguage('zh-TW')
    } else {
      i18n.changeLanguage('en-US')
    }
  }, [checked])

  const memberCards = DEMO_DATA.map((item) => <MemberCard key={item.organizerId} {...item} />)

  return (
    <Protected state={AuthState.LOGIN} redirect className="h-full">
      <DualNavLayout className="container max-w-none py-6 mx-auto px-6 sm:px-14 md:px-20 lg:px-36 xl:px-80">
        {alert == null ? <div /> : <Alert type={alert[0]} message={alert[1]} />}
        <Grid>
          <Grid.Col span={{ base: 12, xs: 5, md: 3 }}>
            <Card withBorder radius="md" className="py-4 px-8 sm:px-2">
              <Card.Section h={140} className="bg-cover bg-center" />
              {iconUrl ? (
                <Avatar src={iconUrl} size={80} radius={80} mx="auto" mt={-30} />
              ) : (
                <Avatar size={80} radius={40} mx="auto" mt={-30}>
                  <FontAwesomeIcon icon={faUser} size={'xl'} />
                </Avatar>
              )}
              <Text ta="center" fz="lg" fw={500} mt="sm">
                {currUser?.getDisplayName() ?? 'Guest'}
              </Text>
              <Text ta="center" fz="sm" c="dimmed">
                {/* Placeholder text */}
              </Text>
              <Group mt="md" justify="center" className="gap-4 sm:gap-3 md:gap-8">
                {items}
                <div key={'event'}>
                  <Text ta="center" fz="lg" fw={500}>
                    {selfEventList.length}
                  </Text>
                  <Text ta="center" fz="sm" c="dimmed" lh={1}>
                    {'Event'}
                  </Text>
                </div>
              </Group>
              <Stack mt="xl" justify="center" align="center" gap={20} className="mb-3">
                <Group>
                  <span className="font-sans text-base text-gray-900 dark:text-gray-200">{t('topNav.lang')}</span>
                  <Switch checked={checked} onChange={(event) => setChecked(event.currentTarget.checked)}>
                    <span
                      className={`${
                        theme === 'dark' ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-primary-500 transition`}
                    />
                  </Switch>
                </Group>
                <Group>
                  <span className="font-sans text-base text-gray-900 dark:text-gray-200">{t('topNav.darkmode')}</span>
                  <Switch checked={theme === 'dark'} onChange={toggleTheme}>
                    <span
                      className={`${
                        theme === 'dark' ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-primary-500 transition`}
                    />
                  </Switch>
                </Group>
              </Stack>

              <Button radius="md" variant="default" onClick={onLogout}>
                Logout
              </Button>
            </Card>

            <Card withBorder radius="md" className="mt-8 py-4 px-8 sm:px-2">
              <Tabs variant="unstyled" defaultValue="ongoing" classNames={classes}>
                <Tabs.List grow>
                  <Tabs.Tab value="ongoing">Ongoing</Tabs.Tab>
                  <Tabs.Tab value="history">History</Tabs.Tab>
                </Tabs.List>

                {/* <Tabs.Panel value="ongoing">Gallery tab content</Tabs.Panel> */}

                {/* <Tabs.Panel value="history">Messages tab content</Tabs.Panel> */}
              </Tabs>
            </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 7, md: 9 }}>
            <Text className="font-sans text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-2">About</Text>
            <Group gap="xs" className="mb-6">
              <div className="flex justify-center items-center h-8 w-8 rounded-md dark:bg-white/10">
                <FontAwesomeIcon icon={faCalendar} size="lg" />
              </div>
              <Stack gap="0" align="flex-start">
                <Text className="text-sm md:text-base font-sans">Joined on {joinTime.toFormatString('MMMM YYYY')}</Text>
              </Stack>
            </Group>

            {/* Your Events */}
            <div className="mb-6">
              <Text className="font-sans text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-2">
                Your Event ({selfEventList.length})
              </Text>
              <EventCardGrid cols={{ base: 1, md: 2 }} type="self" eventList={selfEventList} />
            </div>

            {/* Joined Events */}
            <div className="mb-6">
              <Text className="font-sans text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-2">
                Joined Event ({joinedEventList.length})
              </Text>
              <EventCardGrid cols={{ base: 1, md: 2 }} type="joined" eventList={joinedEventList} />
            </div>

            {/* Members */}
            <div className="mb-6">
              <Text className="font-sans text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-2">
                Member ({DEMO_DATA.length})
              </Text>
              <SimpleGrid cols={{ base: 1, md: 2 }}>{memberCards}</SimpleGrid>
            </div>
          </Grid.Col>
        </Grid>
      </DualNavLayout>
    </Protected>
  )
}
