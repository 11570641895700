import { ChatContext } from '@/context/chat'
import Auth from '@/utils/auth'
import { faCaretDown, faRepeat } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Group, ScrollArea, Stack, Text } from '@mantine/core'
import ChatMessageModel from '@shared/models/chat/chat-message'
import { ReactElement, RefObject, useContext, useEffect, useRef, useState } from 'react'
import ChatMessage from './chat-message'
import { ChatMessageActionType } from './chat-message-action'
import { useTranslation } from 'react-i18next'

export type ChatMessagesWrapperProps = {
  topRef: RefObject<HTMLDivElement>
  bottomRef: RefObject<HTMLDivElement>
}

export default function ChatMessagesWrapper({ topRef, bottomRef }: Readonly<ChatMessagesWrapperProps>): ReactElement {
  const { t } = useTranslation('chat')
  const [isShowScrollToBottom, setIsShowScrollToBottom] = useState<boolean>(false)
  const chatMessageListViewportRef = useRef<HTMLDivElement>(null)

  const {
    roomId,
    isLoaded,
    firstSeqId, lastSeqId,
    minLoadedSeqId,
    sendMessageList,
    failMessageList,
    recvMessageList,
    replyMessage,
    setReplyMessage,
    deleteMessage,
    resendMessage,
    resetPullFreq,
    getScrollDistanceFromBottom,
    isAtTop, isAtBottom, scrollToBottom
  } = useContext(ChatContext)

  function onAction(actionType: ChatMessageActionType, message: ChatMessageModel): void {
    switch (actionType) {
      case 'delete':
        if (message.getSender()?.getId() !== Auth.currentUser?.getId()) {
          console.warn('attempt to delete other people message')
          break
        }

        deleteMessage(message)
        break
      case 'reply':
        setReplyMessage(message)
        break
      default:
        console.error('invalid action type:', actionType)
        break
    }
  }

  function onScroll(): void {
    setIsShowScrollToBottom(getScrollDistanceFromBottom() > 4e3 || (recvMessageList.length > 0 && recvMessageList.find(msg => (msg.getId() ?? 0n) >= lastSeqId) == null))
    if (isAtTop() || isAtBottom()) resetPullFreq()
  }

  useEffect(() => {
    setTimeout(() => {
      // STUPID fix for your STUPID lib issue, who said lib is good?
      const brokenElem = chatMessageListViewportRef.current?.children[0] as HTMLDivElement
      if (brokenElem != null && brokenElem.style.display === 'table') brokenElem.style.display = 'block'
    }, 100)
  }, [sendMessageList, recvMessageList, recvMessageList])

  return (
    <>
      <ScrollArea
        className='grow'
        type='always'
        scrollbarSize={2}
        scrollbars='y'
        onScrollPositionChange={onScroll}
        viewportRef={chatMessageListViewportRef}
      >
        <Stack gap={0}>
          <Text ref={topRef} className='pt-4 w-full text-center font-sans' size='md' c='dimmed'>
            {
              (roomId == null && t('select')) ||
              (isLoaded && firstSeqId === minLoadedSeqId) && (
                recvMessageList.length > 0 ?
                  t('alreadyOnTop') :
                  (!isShowScrollToBottom && t('saySomething'))
              ) || t('loading')
            }
          </Text>
          {roomId != null && recvMessageList.map(message => (
            <ChatMessage key={message.getId() ?? `recv-${Math.floor(Math.random() * 1e3)}`} highlight={replyMessage?.getId() === message.getId()} message={message} onAction={onAction} />
          ))}
          {roomId != null && sendMessageList.map(message => (
            <ChatMessage key={message.getId() ?? `send-${Math.floor(Math.random() * 1e3)}`} fgColor='dimmed' message={message} />
          ))}
          {roomId != null && failMessageList.map(message => (
            <ChatMessage key={message.getId() ?? `fail-${Math.floor(Math.random() * 1e3)}`} fgColor='red' message={message} />
          ))}
        </Stack>
        <div ref={bottomRef}></div>
      </ScrollArea>
      {failMessageList.length > 0 && (
        <ActionIcon className='w-full' color='red' radius="xl" onClick={resendMessage}>
          <Group className='w-full px-2' justify='space-between'>
            <Text size='sm' className='font-sans'>{t('failToSend')}</Text>
            <Group align='center' gap={8}>
              <Text size='sm' className='font-sans' fw={500}>{t('retry')}</Text>
              <FontAwesomeIcon icon={faRepeat} />
            </Group>
          </Group>
        </ActionIcon>
      )}
      {isLoaded && isShowScrollToBottom && (
        <ActionIcon className='w-full bg-slate-400 dark:bg-slate-800 hover:bg-primary-700 dark:hover:bg-primary-700' radius="xl" onClick={() => scrollToBottom(false, true)}>
          <Group className='w-full px-2' justify='space-between'>
            <Text size='sm' className='font-sans'>{t("olderMsg")}</Text>
            <Group align='center' gap={8}>
              <Text size='sm' className='font-sans' fw={500}>{t('jumpToPresent')}</Text>
              <FontAwesomeIcon icon={faCaretDown} />
            </Group>
          </Group>
        </ActionIcon>
      )}
    </>
  )
}