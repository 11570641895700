import ModelBase from '@shared/models/base'

export interface GetCsrfTokenRspJSONModel {
  csrfToken: string
}

export default class GetCsrfTokenRspModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.csrfToken = ''
  }

  public getCsrfToken(): string {
    return this.csrfToken
  }

  public setCsrfToken(csrfToken: string): this {
    this.csrfToken = csrfToken
    return this
  }

  public toJSONObject(): GetCsrfTokenRspJSONModel {
    const { csrfToken } = this

    return {
      csrfToken
    }
  }

  public fromJSONObject(json: GetCsrfTokenRspJSONModel): this {
    this.validate(json, 'csrfToken', 'string')

    const { csrfToken } = json

    this.csrfToken = csrfToken

    return this
  }

  /// Private ///

  private csrfToken: string
}