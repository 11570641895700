import ChatBox from '@/components/chat/chat-box'
import ChatMessagesWrapper from '@/components/chat/chat-messages-wrapper'
import ChatRoomEntry from '@/components/chat/chat-room-entry'
import DualNavLayout from '@/components/layout/dual-nav'
import Protected from '@/components/protected'
import { AuthState } from '@/constants/auth'
import { ChatContext, IChatContext } from '@/context/chat'
import { ApiSocketReqModel, Retcode } from '@/models/api'
import Auth from '@/utils/auth'
import DateTime from '@/utils/datetime'
import { Socket } from '@/utils/socket'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Alert, Group, ScrollArea, Stack, Text } from '@mantine/core'
import DelChatNotifyModel from '@shared/models/api/notify/del-chat'
import DelChatReqModel from '@shared/models/api/request/del-chat'
import PullChatReqModel from '@shared/models/api/request/pull-chat'
import PushChatReqModel from '@shared/models/api/request/push-chat'
import PullChatRspModel from '@shared/models/api/response/pull-chat'
import { ApiSocketCmdId } from '@shared/models/api/socket'
import ChatMessageModel from '@shared/models/chat/chat-message'
import ChatRoomModel from '@shared/models/chat/chat-room'
import UserSimpleModel from '@shared/models/user/simple'
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

export default function ChatRoomPage(): ReactElement {
  const { t } = useTranslation('chat')
  const navigate = useNavigate()

  const { id } = useParams()
  const [isChatRoomListOpen, setIsChatRoomListOpen] = useState<boolean>(false)

  const chatRoomListViewportRef = useRef<HTMLDivElement>(null)

  /// Socket ///
  const socket = useMemo(() => new Socket('/chat/live'), [])

  /// Pull state ///
  const updateTimerRef = useRef<number>(-1)
  const lastPullRef = useRef<number>(0)
  const pullIntervalRef = useRef<number>(500)
  const isPullingRef = useRef<boolean>(false)

  /// Chat context ///
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [chatRoomList, setChatRoomList] = useState<ChatRoomModel[]>([])
  const [firstSeqId, setFirstSeqId] = useState<bigint>(-1n)
  const [lastSeqId, setLastSeqId] = useState<bigint>(-1n)
  const [minLoadedSeqId, setMinLoadedSeqId] = useState<bigint>(0n)
  const [maxLoadedSeqId, setMaxLoadedSeqId] = useState<bigint>(0n)
  const [sendMessageList, setSendMessageList] = useState<ChatMessageModel[]>([])
  const [failMessageList, setFailMessageList] = useState<ChatMessageModel[]>([])
  const [recvMessageList, setRecvMessageList] = useState<ChatMessageModel[]>([])
  const [replyMessage, setReplyMessage] = useState<ChatMessageModel | null>(null)

  /// Chat context reference ///
  const roomIdRef = useRef<bigint | null>(null)
  const chatRoomListRef = useRef<ChatRoomModel[]>([])
  const lastSeqIdRef = useRef<bigint>(0n)
  const minLoadedSeqIdRef = useRef<bigint>(0n)
  const maxLoadedSeqIdRef = useRef<bigint>(0n)
  const sendMessageListRef = useRef<ChatMessageModel[]>([])
  const failMessageListRef = useRef<ChatMessageModel[]>([])
  const recvMessageListRef = useRef<ChatMessageModel[]>([])
  const replyMessageRef = useRef<ChatMessageModel | null>(null)
  const topRef = useRef<HTMLDivElement>(null)
  const bottomRef = useRef<HTMLDivElement>(null)
  const scrollToBottomWaitRef = useRef<number>(-1)

  roomIdRef.current = (id == null || !/^\d+$/.test(id.trim())) ? null : BigInt(id)
  chatRoomListRef.current = chatRoomList
  lastSeqIdRef.current = lastSeqId
  minLoadedSeqIdRef.current = minLoadedSeqId
  maxLoadedSeqIdRef.current = maxLoadedSeqId
  sendMessageListRef.current = sendMessageList
  failMessageListRef.current = failMessageList
  recvMessageListRef.current = recvMessageList
  replyMessageRef.current = replyMessage

  /// Message list functions ///
  function deleteMessageData(messageId: bigint): void {
    const newRecvMessageList = [...recvMessageListRef.current]

    // Remove all messages & reply messages data by id
    for (const message of newRecvMessageList) {
      let curMessage: ChatMessageModel | null = message
      while (curMessage != null) {
        if (curMessage.getId() === messageId) curMessage.setTextData(null)
        curMessage = curMessage.getReplyData()
      }
    }

    // Update recv message list
    setRecvMessageList(newRecvMessageList)
  }

  function insertMessages(messageList: ChatMessageModel[]): void { // NOSONAR
    const firstInsert = recvMessageListRef.current.length === 0
    const newRecvMessageList = [...recvMessageListRef.current]

    let hasNewMessage = false
    let minSeqId = 0n
    for (const message of messageList) {
      const seqId = message.getId() ?? 0n

      // Update min seq id
      if (minSeqId === 0n || seqId < minSeqId) minSeqId = seqId

      // Insert message by seq id
      let inserted = false
      for (let i = newRecvMessageList.length - 1; i >= 0; i--) {
        const recvMsg = newRecvMessageList[i]
        const recvMsgId = recvMsg.getId() ?? 0n

        if (seqId >= recvMsgId) {
          if (seqId !== recvMsgId) newRecvMessageList.splice(i + 1, 0, message)
          inserted = true
          break
        }
      }

      // Insert before all messages if not inserted in the middle
      if (!inserted) newRecvMessageList.unshift(message)

      // Recv new message, set new message flag
      if (lastSeqIdRef.current < seqId) hasNewMessage = true
    }

    // Update recv message list
    setRecvMessageList(newRecvMessageList)

    // Scroll below loading text to prevent browser from auto scrolling to top
    if (isAtTop()) scrollToTop()

    // Auto scroll on new message
    if (hasNewMessage && isAtBottom()) scrollToBottom(!firstInsert, false)
  }

  function unloadTop(): void {
    const threshold = recvMessageListRef.current.length - 60
    const newRecvMessageList = recvMessageListRef.current.filter((_, i) => i > threshold)

    let minSeqId = 0n
    for (const message of newRecvMessageList) {
      const seqId = message.getId() ?? 0n
      if (minSeqId !== 0n && minSeqId <= seqId) continue

      minSeqId = seqId
    }

    setRecvMessageList(newRecvMessageList)
    setMinLoadedSeqId(minSeqId)
  }

  function unloadBottom(): void {
    const newRecvMessageList = recvMessageListRef.current.filter((_, i) => i < 60)

    let maxSeqId = 0n
    for (const message of newRecvMessageList) {
      const seqId = message.getId() ?? 0n
      if (maxSeqId !== 0n && seqId <= maxSeqId) continue

      maxSeqId = seqId
    }

    setRecvMessageList(newRecvMessageList)
    setMaxLoadedSeqId(maxSeqId)
  }

  function unloadAll() {
    setRecvMessageList([])
    setFirstSeqId(-1n)
    setLastSeqId(-1n)
    setMinLoadedSeqId(0n)
    setMaxLoadedSeqId(0n)
  }

  /// Scroll functions ///
  function getScrollContainer(): HTMLDivElement | null {
    const elem = topRef.current
    if (elem == null) return null

    let parentElem = elem.parentElement
    while (parentElem != null && parentElem.style.overflowY !== 'scroll') parentElem = parentElem.parentElement

    return parentElem as HTMLDivElement
  }

  function getScrollInnerContainer(): HTMLDivElement | null {
    return getScrollContainer()?.children[0] as HTMLDivElement ?? null
  }

  function getScrollDistanceFromTop(): number {
    const topRect = topRef.current?.getBoundingClientRect()
    if (topRect == null) return 0

    const scrollContainerRect = getScrollContainer()?.getBoundingClientRect()
    if (scrollContainerRect == null) return 0

    return scrollContainerRect.top - (topRect.top + topRect.height)
  }

  function getScrollDistanceFromBottom(): number {
    const bottomRect = bottomRef.current?.getBoundingClientRect()
    if (bottomRect == null) return 0

    const scrollContainerRect = getScrollContainer()?.getBoundingClientRect()
    if (scrollContainerRect == null) return 0

    return bottomRect.bottom - scrollContainerRect.height
  }

  function isAtTop(): boolean {
    const topElem = topRef.current
    if (topElem == null) return false

    return getScrollDistanceFromTop() <= 0
  }

  function isAtBottom(): boolean {
    const topRect = topRef.current?.getBoundingClientRect()
    if (topRect == null) return false

    const scrollContainerRect = getScrollContainer()?.getBoundingClientRect()
    const scrollInnerContainerRect = getScrollInnerContainer()?.getBoundingClientRect()
    if (scrollContainerRect == null || scrollInnerContainerRect == null) return false

    return ((scrollInnerContainerRect.height - scrollContainerRect.height) - getScrollDistanceFromTop()) < (topRect.height + 10)
  }

  function scrollToTop() {
    const topRect = topRef.current?.getBoundingClientRect()
    if (topRect == null) return

    getScrollContainer()?.scroll({ top: topRect.height + 5 })
  }

  function scrollToBottomInternal(elem: HTMLDivElement, smooth: boolean) {
    if (recvMessageListRef.current.find(msg => (msg.getId() ?? 0n) >= lastSeqIdRef.current) == null) unloadAll()

    elem.scrollIntoView({ behavior: smooth ? 'smooth' : 'instant' })
  }

  function scrollToBottom(smooth: boolean, immediate: boolean): void {
    const elem = bottomRef.current
    if (elem == null) return

    if (immediate) {
      scrollToBottomInternal(elem, smooth)
      return
    }

    if (scrollToBottomWaitRef.current >= 0) return

    const prevPos = elem.getBoundingClientRect().bottom
    scrollToBottomWaitRef.current = window.setInterval(() => {
      const currPos = elem.getBoundingClientRect().bottom
      if (currPos === prevPos) return

      scrollToBottomInternal(elem, smooth)
      clearInterval(scrollToBottomWaitRef.current)
      scrollToBottomWaitRef.current = -1
    }, 1)
  }

  /// API functions ///
  function resetPullFreq(): void {
    pullIntervalRef.current = 500
  }

  function reducePullFreq(): void {
    pullIntervalRef.current = Math.min(5000, pullIntervalRef.current * 1.25)
  }

  function sendMessage(messageData: string, replyOverride?: ChatMessageModel | null): void {
    // Return if not in any chat room
    if (roomIdRef.current == null) return

    // Construct new message
    const newMessage = new ChatMessageModel().setReplyData(replyOverride ?? replyMessageRef.current ?? null).setTextData(messageData)

    // Construct preview message
    const previewMessage = new ChatMessageModel().fromJSONObject(newMessage.toJSONObject())

    // Set preview message id
    previewMessage.setId(-((roomIdRef.current << 64n) | BigInt(Date.now())))

    // Set sent time for preview message
    previewMessage.setSentTime(new DateTime().addTimezoneOffset())

    // Set sender for preview message
    const currentUser = Auth.currentUser
    if (currentUser != null) previewMessage.setSender(new UserSimpleModel().fromJSONObject(currentUser.toJSONObject()))

    // Clear reply message
    setReplyMessage(null)

    // Send push chat request
    socket.sendReq(ApiSocketCmdId.CMD_CHAT_PUSH, new PushChatReqModel().setRoomId(roomIdRef.current).setMsg(newMessage))
      .then(rsp => {
        if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

        // Clear failed message list
        setFailMessageList([])

        // Set seq id to indicate message has been sent
        newMessage.setId(-1n)

        // Show message being sent ASAP
        resetPullFreq()
      })
      .catch(() => {
        // Move message to failed message list
        setSendMessageList(sendMessageListRef.current.filter(message => message !== previewMessage))
        setFailMessageList([...failMessageListRef.current, previewMessage])

        scrollToBottom(true, false)
      })

    // Add message to sending message list & scroll to bottom of chat
    setSendMessageList([...sendMessageListRef.current, previewMessage])
    scrollToBottom(true, false)
  }

  function deleteMessage(message: ChatMessageModel): void {
    const messageId = message.getId()

    // Return if not in any chat room or message don't have id
    if (roomIdRef.current == null || messageId == null) return

    socket.sendReq(ApiSocketCmdId.CMD_CHAT_DEL, new DelChatReqModel().setRoomId(roomIdRef.current).setMsgId(messageId))
      .then(rsp => {
        if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

        resetPullFreq()
      }).catch(err => {
        console.warn('failed to delete message:', err)
      })
  }

  function resendMessage(): void {
    // Return if not in any chat room
    if (roomIdRef.current == null) return

    // Get resend message list
    const resendMessageList = failMessageListRef.current

    // Clear failed message list
    setFailMessageList([])

    // Resend failed messages
    for (const message of resendMessageList) {
      const seqId = message.getId()
      if (seqId == null || seqId >= 0n) continue

      // Check if room id matches
      const roomId = (-seqId) >> 64n
      if (roomId !== roomIdRef.current) continue

      // Get message data
      const messageData = message.getTextData()
      if (messageData == null) return

      // Send message
      sendMessage(messageData, message.getReplyData())
    }
  }

  function onChatRoomListRsp(roomList: ChatRoomModel[] | null): void {
    if (roomList == null || roomList.length === 0) return

    // STUPID fix for your STUPID lib issue, who said lib is good?
    const brokenElem = chatRoomListViewportRef.current?.children[0] as HTMLDivElement
    if (brokenElem != null && brokenElem.style.display === 'table') brokenElem.style.display = 'block'

    // Update chat room list
    setChatRoomList(roomList)
  }

  function onChatMessageListRsp(messageList: ChatMessageModel[] | null): void { // NOSONAR
    if (messageList == null || messageList.length === 0) {
      if (isAtTop()) {
        // Reached top, no more chat history, set min loaded seq id as first
        setFirstSeqId(minLoadedSeqIdRef.current)
      } else {
        // Reached bottom, no new message yet, pull less frequently
        reducePullFreq()
      }
      return
    }

    // Find min/max message id
    let minSeqId = 0n
    let maxSeqId = 0n
    for (const message of messageList) {
      const seqId = message.getId() ?? 0n

      if (minSeqId === 0n || seqId < minSeqId) minSeqId = seqId
      if (maxSeqId === 0n || maxSeqId < seqId) maxSeqId = seqId
    }

    // Insert new messages
    insertMessages(messageList)

    // Remove all sent messages
    setSendMessageList(sendMessageListRef.current.filter(message => message.getId() == null))

    // Update chat context
    if (lastSeqIdRef.current < 0n || lastSeqIdRef.current < maxSeqId) setLastSeqId(maxSeqId)
    if (minLoadedSeqIdRef.current === 0n || minLoadedSeqIdRef.current > minSeqId) setMinLoadedSeqId(minSeqId)
    if (maxLoadedSeqIdRef.current === 0n || maxLoadedSeqIdRef.current < maxSeqId) setMaxLoadedSeqId(maxSeqId)

    // Might have new message, pull more frequently
    resetPullFreq()
  }

  function onDelChatNotify(roomId: bigint, messageId: bigint): void {
    if (roomId !== roomIdRef.current) return

    deleteMessageData(messageId)
  }

  async function pullChat(): Promise<void> { // NOSONAR
    if (isPullingRef.current || Date.now() - lastPullRef.current < pullIntervalRef.current) return
    isPullingRef.current = true

    try {
      const reqData = new PullChatReqModel().setRoomId(roomIdRef.current ?? -1n)

      // Set first room id
      reqData.setFirstRoomId(chatRoomListRef.current[0]?.getId() ?? 0n)

      if (isAtBottom()) { // At bottom, load new messages
        // Pull messages after last loaded message
        if (maxLoadedSeqIdRef.current != 0n) reqData.setBeginSeqId(maxLoadedSeqIdRef.current + 1n)

        // Too many messages loaded, since we are at the bottom, remove messages from the top
        if (recvMessageListRef.current.length > 120) unloadTop()
      } else if (isAtTop()) { // At top, load chat history
        // Pull 50 messages before the first loaded message
        if (minLoadedSeqIdRef.current != 0n) {
          reqData.setBeginSeqId(minLoadedSeqIdRef.current - 50n)
          reqData.setEndSeqId(minLoadedSeqIdRef.current - 1n)
        }

        // Too many messages loaded, since we are at the top, remove messages from the bottom
        if (recvMessageListRef.current.length > 120) unloadBottom()
      } else {
        // In the middle, only pull chat room list
        reqData.setBeginSeqId(-1n)
        reqData.setEndSeqId(-1n)
      }

      // Send pull chat request & wait for response
      const rsp = await socket.sendReq(ApiSocketCmdId.CMD_CHAT_PULL, reqData, PullChatRspModel)
      if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

      const rspData = rsp.getData()
      if (rspData == null) throw new Error('data == null')

      // Check for room id mismatch
      if (roomIdRef.current != null && roomIdRef.current !== reqData.getRoomId()) return

      // Handle response
      if (reqData.getFirstRoomId() >= 0n) onChatRoomListRsp(rspData.getRoomList())
      if (reqData.getBeginSeqId() >= 0n && reqData.getEndSeqId() >= 0n) onChatMessageListRsp(rspData.getMessageList())

      // Set loaded flag
      setIsLoaded(true)
    } catch (err) {
      console.error(err)
      reducePullFreq()
    } finally {
      lastPullRef.current = Date.now()
      isPullingRef.current = false
    }
  }

  useEffect(() => {
    socket.onNotify((cmdId, notifyBuf) => {
      switch (cmdId) { // NOSONAR
        case ApiSocketCmdId.CMD_CHAT_DEL: {
          const notify = new ApiSocketReqModel(DelChatNotifyModel)
          if (!notify.deserialize(notifyBuf)) break

          const data = notify.getData()
          if (data == null) break

          onDelChatNotify(data.getRoomId(), data.getMsgId())
          break
        }
        default:
          console.warn('unknown notify cmd:', cmdId)
          break
      }
    })

    socket.open()

    // Open chat room list if room id is not set
    setIsChatRoomListOpen(id == null)

    // Page just loaded, fetch data ASAP
    resetPullFreq()

    if (updateTimerRef.current >= 0) return

    updateTimerRef.current = window.setInterval(pullChat, 10)

    return () => {
      socket.close()

      if (updateTimerRef.current < 0) return

      // Cleanup
      window.clearInterval(updateTimerRef.current)
      unloadAll()
      setSendMessageList([])
      setFailMessageList([])
      setRecvMessageList([])
      setIsLoaded(false)

      updateTimerRef.current = -1
    }
  }, [id])

  return (
    <Protected state={AuthState.LOGIN} redirect className='h-full'>
      <ChatContext.Provider
        value={useMemo<IChatContext>(() => ({
          roomId: roomIdRef.current,
          isLoaded,
          firstSeqId, lastSeqId,
          minLoadedSeqId, maxLoadedSeqId,
          sendMessageList,
          failMessageList,
          recvMessageList,
          replyMessage,
          setFirstSeqId, setLastSeqId,
          setMinLoadedSeqId, setMaxLoadedSeqId,
          setReplyMessage,
          deleteMessage,
          resendMessage, reducePullFreq, resetPullFreq,
          getScrollDistanceFromBottom,
          isAtTop, isAtBottom, scrollToBottom
        }), [
          id,
          isLoaded,
          firstSeqId, lastSeqId,
          minLoadedSeqId, maxLoadedSeqId,
          sendMessageList,
          failMessageList,
          recvMessageList,
          replyMessage
        ])}
      >
        <DualNavLayout className='max-w-full w-full h-full'>
          <Group className='h-full flex-nowrap'>
            <ScrollArea
              className={`h-full shrink-0 ${isChatRoomListOpen ? 'basis-full md:basis-64' : 'hidden md:block basis-64'}`}
              type='always'
              scrollbarSize={2}
              scrollbars='y'
              viewportRef={chatRoomListViewportRef}
            >
              <Stack>
                {
                  chatRoomList.length === 0 ? (
                    <Text className='text-center font-sans'>{isLoaded ? t('dontHaveChat') : t('loading')}</Text>
                  ) : (
                    chatRoomList.map(room => (
                      <ChatRoomEntry
                        key={room.getId().toString()}
                        room={room}
                        onClick={() => room.getId() === roomIdRef.current ? setIsChatRoomListOpen(false) : navigate(`/chat/${room.getId().toString()}`)}
                      />
                    ))
                  )
                }
              </Stack>
            </ScrollArea>
            <Stack className={`h-full grow overflow-hidden ${isChatRoomListOpen ? 'hidden md:flex' : ''}`} gap={0}>
              <Group className='w-full flex-nowrap min-h-12'>
                <ActionIcon className='md:hidden' variant='outline' size='xl' onClick={() => setIsChatRoomListOpen(true)}>
                  <FontAwesomeIcon icon={faBars} />
                </ActionIcon>
                <Text className='grow overflow-hidden text-center text-ellipsis font-sans'>
                  {
                    chatRoomList
                      .find(room => room.getId() === roomIdRef.current)?.getMemberList()
                      .filter(member => member.getId() !== Auth.currentUser?.getId())
                      .map(member => member.getDisplayName())
                      .join(', ')
                  }
                </Text>
              </Group>
              <Stack className='min-h-0 grow border-t-2 border-gray-300 dark:border-gray-500' gap={0}>
                <ChatMessagesWrapper topRef={topRef} bottomRef={bottomRef} />
                {
                  replyMessage != null && (
                    <Alert
                      className='shrink-0 font-sans'
                      title={`${t('replyingTo')} ${replyMessage.getSender()?.getDisplayName() ?? t('guest')}`}
                      color='gray'
                      p='xs'
                      radius={0}
                      withCloseButton
                      onClose={() => setReplyMessage(null)}
                    >
                      {replyMessage.getTextData()}
                    </Alert>
                  )
                }
              </Stack>
              <ChatBox disabled={id == null} onSubmit={sendMessage} />
            </Stack>
          </Group>
        </DualNavLayout>
      </ChatContext.Provider>
    </Protected>
  )
}
