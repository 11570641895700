import {
  faFilm,
  faMusic,
  faDumbbell,
  faLaughBeam,
  faRunning,
  faSwimmer,
  faBicycle,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons'

export const currency = [
  { value: 'hkd', label: '🇭🇰 HKD' },
  { value: 'eur', label: '🇪🇺 EUR' },
  { value: 'usd', label: '🇺🇸 USD' },
  { value: 'cad', label: '🇨🇦 CAD' },
  { value: 'gbp', label: '🇬🇧 GBP' },
  { value: 'aud', label: '🇦🇺 AUD' },
]

export enum Currency {
  hkd = '🇭🇰 HKD',
  eur = '🇪🇺 EUR',
  usd = '🇺🇸 USD',
  cad = '🇨🇦 CAD',
  gbp = '🇬🇧 GBP',
  aud = '🇦🇺 AUD',
}

export const defaultIconUrl = 'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-1.png'

export type Category = {
  label: string
  icon: any
}

export const categories: Category[] = [
  {
    label: 'Movies',
    icon: faFilm,
  },
  {
    label: 'Music',
    icon: faMusic,
  },
  {
    label: 'Sports',
    icon: faDumbbell,
  },
  {
    label: 'Comedy',
    icon: faLaughBeam,
  },
  {
    label: 'Triathlon',
    icon: faRunning,
  },
  {
    label: 'Duathlon',
    icon: faRunning,
  },
  {
    label: 'Aquathlon',
    icon: faSwimmer,
  },
  {
    label: 'Aquabike',
    icon: faBicycle,
  },
  {
    label: 'Other',
    icon: faQuestion,
  },
]

export const TAGS = [
  'Movies',
  'Music',
  'Sports',
  'Comedy',
  'Triathlon',
  'Duathlon',
  'Aquathlon',
  'Aquabike',
  'Other',
]