import i18n from '@/i18n'
import { KebabToCamel } from '@/utils/naming'
import { FirebaseError } from 'firebase/app'

function getFirebaseErrorMessage(err: FirebaseError): string {
  return i18n.t([
    `firebase.${err.code.split('/').map(KebabToCamel).join('.')}`,
    'generic.unspecific'
  ], { ns: 'error', errMsg: err.message })
}

export function getErrorMessage(err: unknown): string {
  if (err instanceof FirebaseError) return getFirebaseErrorMessage(err)

  const msg = String(err instanceof Error ? err.message : err)
  return i18n.t([
    msg,
    'generic.unspecific'
  ], { ns: 'error', errMsg: msg })
}

export function normalizeError(err: unknown): Error {
  return new Error(getErrorMessage(err))
}