import ModelBase from '@shared/models/base'

export interface CreateUserReqJSONModel {
  thumbnailUrl: string
  displayName: string
  phoneNumber: string
}

export default class CreateUserReqModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.thumbnailUrl = ''
    this.displayName = ''
    this.phoneNumber = ''
  }

  public getThumbnailUrl(): string {
    return this.thumbnailUrl
  }

  public getDisplayName(): string {
    return this.displayName
  }

  public getPhoneNumber(): string {
    return this.phoneNumber
  }

  public setThumbnailUrl(thumbnailUrl: string): this {
    this.thumbnailUrl = thumbnailUrl
    return this
  }

  public setDisplayName(displayName: string): this {
    this.displayName = displayName
    return this
  }

  public setPhoneNumber(phoneNumber: string): this {
    this.phoneNumber = phoneNumber
    return this
  }

  public toJSONObject(): CreateUserReqJSONModel {
    const { displayName, phoneNumber, thumbnailUrl } = this

    return {
      thumbnailUrl,
      displayName,
      phoneNumber
    }
  }

  public fromJSONObject(json: CreateUserReqJSONModel): this {
    this.validate(json, 'thumbnailUrl', 'string')
    this.validate(json, 'displayName', 'string')
    this.validate(json, 'phoneNumber', 'string')

    const { thumbnailUrl, displayName, phoneNumber } = json

    this.thumbnailUrl = thumbnailUrl
    this.displayName = displayName
    this.phoneNumber = phoneNumber

    return this
  }

  /// Private ///

  private thumbnailUrl: string
  private displayName: string
  private phoneNumber: string
}