import ModelBase from '@shared/models/base'

export interface UpdateAttendStatusReqJSONModel {
  eventId: string
  userId: string
}

export default class UpdateAttendStatusReqModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.eventId = ''
    this.userId = ''
  }

  public getEventId(): string {
    return this.eventId
  }

  public getUserId(): string {
    return this.userId
  }

  public setEventId(eventId: string): this {
    this.eventId = eventId
    return this
  }

  public setUserId(userId: string): this {
    this.userId = userId
    return this
  }

  public toJSONObject(): UpdateAttendStatusReqJSONModel {
    const { eventId, userId } = this

    return {
      eventId,
      userId
    }
  }

  public fromJSONObject(json: UpdateAttendStatusReqJSONModel): this {
    this.validate(json, 'eventId', 'string')
    this.validate(json, 'userId', 'string')
    const { eventId, userId } = json

    this.eventId = eventId
    this.userId = userId

    return this
  }

  /// Private ///

  private eventId: string
  private userId: string
}