import Protected from '@/components/protected'
import { AuthState } from '@/constants/auth'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert, { AlertType } from '@/components/alert'
import { ResetPasswordFormData, ResetPasswordFormProvider, useResetPasswordForm } from '@/context/reset-password-form'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Auth from '@/utils/auth'
import { getErrorMessage } from '@/utils/error'
import { modals } from '@mantine/modals'
import { Text, TextInput } from '@mantine/core'

export interface LoginPageState {
  redirect?: string
}

export default function ResetPasswordPage(): ReactElement {
  const navigate = useNavigate()
  const { t } = useTranslation('auth')
  const [alert, setAlert] = useState<[AlertType, string] | null>(null)
  const [disable, setDisable] = useState(false)

  const form = useResetPasswordForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  async function onSubmit(value: ResetPasswordFormData) {
    try {
      setDisable(true)

      const { email } = value

      // Check for empty field(s)
      if (!email) throw new Error('generic.missingField')

      // Reset password
      const result = await Auth.resetPassword(email)
      if (result) {
        // goto login page
        modals.openConfirmModal({
          title: 'Go to login page',
          centered: true,
          size: 'md',
          radius: 'md',
          children: (
            <Text size="md">
              <p>Password reset email sent successfully. Please check your email for password reset instructions.</p>
              <p>Click on the button below to go to the login page.</p>
            </Text>
          ),
          labels: { confirm: t('label.login'), cancel: 'Close' },

          confirmProps: { className: 'bg-indigo-600 hover:bg-indigo-700' },
          onCancel: () => console.log('Close'),
          onConfirm: () => navigate('/login'),
        })
      } else {
        setAlert(['danger', 'Failed to send password reset email, Please try again later.'])
      }
    } catch (err) {
      // Show error message
      setAlert(['danger', getErrorMessage(err)])
      setDisable(false)
    }
  }

  return (
    <Protected state={AuthState.LOGOUT} redirect className='h-full'>
      <div className='h-full w-full inline-block overflow-auto bg-light dark:bg-dark'>
        <div className='p-4 rounded max-w-md mx-auto mt-10'>
          <ResetPasswordFormProvider form={form}>
            <form className="space-y-4" onSubmit={form.onSubmit((v) => onSubmit(v) as unknown)}>
              <h1 className="text-3xl font-bold text-black dark:text-white">{t('mainHeading.reset')}</h1>
              <h6 className="text-lg text-black dark:text-white">{t('subHeading.reset')}</h6>
              {alert != null && <Alert type={alert[0]} message={alert[1]} />}
              <div className="space-y-2">
                <TextInput
                  size="md"
                  type="email"
                  label={t('label.email')}
                  leftSection={<FontAwesomeIcon icon={faEnvelope} />}
                  placeholder={t('placeholder.email')}
                  autoComplete="email"
                  {...form.getInputProps('email')}
                />
              </div>
              <div>
                <button
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${disable ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'
                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  type="submit"
                  disabled={disable}
                >
                  {t('action.reset')}
                </button>
              </div>
              <p className="text-center text-base mt-4 text-black dark:text-gray-100">
                {t('guide.reset.back')}{' '}
                <Link to="/login" className="text-indigo-600 hover:text-indigo-500">
                  {t('label.login')}
                </Link>
              </p>
            </form>
          </ResetPasswordFormProvider>
        </div>
      </div>
    </Protected>
  )
}
