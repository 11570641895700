import { ReactNode, useEffect, useState, useCallback } from 'react'
import { CheckIcon, Combobox, Group, Pill, PillsInput, useCombobox } from '@mantine/core'

export type MultiSelectCreatableProps = {
  options: string[]
  label: ReactNode
  placeholder?: string
  initValue?: string[] // Optional initial value prop
  onChange: (value: string[]) => void
  classNames?: object
}

const MultiSelectCreatable: React.FC<MultiSelectCreatableProps> = ({
  options,
  label,
  placeholder,
  initValue = [], // Default to an empty array if not provided
  onChange,
  classNames = {},
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  })

  const [search, setSearch] = useState('')
  const [data, setData] = useState<string[]>(options)
  const [value, setValue] = useState<string[]>(initValue) // Initialize state with initValue

  const exactOptionMatch = data.some((item) => item === search)

  // Memoize the onChange call to ensure it only changes if necessary
  const handleChange = useCallback(() => {
    onChange(value)
  }, [value, onChange])

  // Effect hook to call onChange prop whenever value changes
  useEffect(() => {
    handleChange() // Call the memoized function with the current value
  }, [handleChange])

  const handleValueSelect = (val: string) => {
    setSearch('')

    if (val === '$create') {
      setData((current) => [...current, search])
      setValue((current) => [...current, search])
    } else {
      setValue((current) => (current.includes(val) ? current.filter((v) => v !== val) : [...current, val]))
    }
  }

  const handleValueRemove = (val: string) => setValue((current) => current.filter((v) => v !== val))

  const values = value.map((item) => (
    <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {item}
    </Pill>
  ))

  const optionsDisplay = data
    .filter((item) => item.toLowerCase().includes(search.trim().toLowerCase()))
    .map((item) => (
      <Combobox.Option value={item} key={item} active={value.includes(item)}>
        <Group gap="sm">
          {value.includes(item) ? <CheckIcon size={12} /> : null}
          <span>{item}</span>
        </Group>
      </Combobox.Option>
    ))

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
      <Combobox.DropdownTarget>
        <PillsInput
          onClick={() => combobox.openDropdown()}
          label={label}
          classNames={classNames}
        >
          <Pill.Group>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder={placeholder ?? 'Select items'}
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex()
                  setSearch(event.currentTarget.value)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault()
                    handleValueRemove(value[value.length - 1])
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>
          {optionsDisplay}

          {!exactOptionMatch && search.trim().length > 0 && (
            <Combobox.Option value="$create">+ Create {search}</Combobox.Option>
          )}

          {exactOptionMatch && search.trim().length > 0 && optionsDisplay.length === 0 && (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default MultiSelectCreatable
