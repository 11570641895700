import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faApple, faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mantine/core'
import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export type ThirdPartyButtonProps = {
  provider: string
  onClick?: () => void
}

export default function ThirdPartyButton({ provider, ...props }: Readonly<ThirdPartyButtonProps>): ReactElement | null {
  const { t } = useTranslation('auth')

  let icon: IconDefinition
  let text: string

  switch (provider.toLowerCase()) {
    case 'apple':
      icon = faApple
      text = 'Apple ID'
      break
    case 'facebook':
      icon = faFacebook
      text = 'Facebook'
      break
    case 'google':
      icon = faGoogle
      text = 'Google'
      break
    default:
      console.warn('not supported third party login provider:', provider)
      return null
  }

  return (
    <Button
      {...props}
      leftSection={<FontAwesomeIcon icon={icon} size="lg" />}
      className="bg-palette3-600 hover:bg-palette3-700 rounded-md font-sans font-semibold"
    >
      <Trans i18nKey="action.thirdParty" t={t} values={{ text }}>
        ? {{text}}
      </Trans>
    </Button>
  )
}
