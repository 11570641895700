import Alert, { AlertType } from '@/components/alert'
import DualNavLayout from '@/components/layout/dual-nav'
import Protected from '@/components/protected'
import { AuthState } from '@/constants/auth'
import { ApiRspModel, Retcode } from '@/models/api'
import Api from '@/utils/api'
import { getErrorMessage } from '@/utils/error'
import { Button, Container, Group, Input, Text, Textarea } from '@mantine/core'
import { modals } from '@mantine/modals'
import DebugSqlReqModel from '@shared/models/api/request/debug-sql'
import DebugBuildRspModel from '@shared/models/api/response/debug-build'
import { ReactElement, useEffect, useState } from 'react'

enum DebugActionType {
  SERVER_RELOAD,
  SERVER_RESTART,
  SERVER_STOP,
  SERVER_BUILD,
  SERVER_SQL,
}

export function DebugPage(): ReactElement {
  const [alert, setAlert] = useState<[AlertType, string] | null>(null)
  const [buildLog, setBuildLog] = useState<string>('')
  const [sqlQuery, setSqlQuery] = useState<string>('')
  const DELETE_QUERY = 'DELETE FROM `t_event` WHERE `id` = ""'
  const INSERT_USERS_QUERY = [
    "INSERT INTO `t_user` (`id`, `thumbnail_url`, `display_name`, `phone_number`, `created_time`) VALUES ('AVXLKFrfImWesioRuh56tntZgaQYBVM2', 'users_icon/5f4c787c-5e2c-4db3-805d-ff1391dbdbafdickhead_24.png', 'Ben', '+85256044561', '2024-04-16 23:03:46'), ('AVXLKFrfImWesioRujygntZgaQYBVM2', 'users_icon/5f4c787c-5e2c-4db3-805d-ff1391dbdbafdickhead_24.png', 'Alan', '+85296541252', '2024-04-16 23:03:46'), ('AVXLKFrfImWesioRunagtZgaQYBVM2', 'users_icon/5f4c787c-5e2c-4db3-805d-ff1391dbdbafdickhead_24.png', 'CK', '+85268484512', '2024-04-16 23:03:46'), ('AVXLKFrfImWesioRunfawtZgaQYBVM2', 'users_icon/5f4c787c-5e2c-4db3-805d-ff1391dbdbafdickhead_24.png', 'BW', '+85259878452', '2024-04-16 23:03:46');",
  ]

  // 27d62550-4f00-4dd1-8b1d-35c280bb0f2c
  const INSERT_ATTENDEE_QUERY = 'INSERT INTO `t_event_attendee` (`id`, `event_id`, `user_id`, `attend_time`, `answer_data`, `status`) VALUES (1152921504606848000, \'27d62550-4f00-4dd1-8b1d-35c280bb0f2c\', \'AVXLKFrfImWesioRunagtZgaQYBVM2\', \'2024-04-17 15:20:15\', \'[{"question":"What is your name?","answer":"CK"},{"question":"What is your phone number?","answer":"+85268484512"}]\', \'STATUS_PENDING\'), (1152921504606848001, \'27d62550-4f00-4dd1-8b1d-35c280bb0f2c\', \'AVXLKFrfImWesioRujygntZgaQYBVM2\', \'2024-04-17 15:20:15\', \'[{"question":"What is your name?","answer":"Alan"},{"question":"What is your phone number?","answer":"+85296541252"}]\', \'STATUS_PENDING\'), (1152921504606848002, \'27d62550-4f00-4dd1-8b1d-35c280bb0f2c\', \'AVXLKFrfImWesioRunfawtZgaQYBVM2\', \'2024-04-17 15:20:15\', \'[{"question":"What is your name?","answer":"BW"},{"question":"What is your phone number?","answer":"+85259878452"}]\', \'STATUS_PENDING\'), (1152921504606848003, \'27d62550-4f00-4dd1-8b1d-35c280bb0f2c\', \'AVXLKFrfImWesioRuh56tntZgaQYBVM2\', \'2024-04-17 15:20:15\', \'[{"question":"What is your name?","answer":"Ben"},{"question":"What is your phone number?","answer":"+85256044561"}]\', \'STATUS_PENDING\');'
  

  async function doDebugAction(actionType: DebugActionType): Promise<void> {
    try {
      if (process.env.NODE_ENV !== 'development') {
        setAlert(['danger', 'Cannot use debug action on non development build'])
        return
      }

      let rsp: ApiRspModel
      switch (actionType) {
        case DebugActionType.SERVER_RELOAD:
          rsp = await Api.sendCsrfRequest('/debug/reload')
          break
        case DebugActionType.SERVER_STOP:
          rsp = await Api.sendCsrfRequest('/debug/stop')
          break
        case DebugActionType.SERVER_BUILD:
          setBuildLog('Building...')
          rsp = await Api.sendCsrfRequest('/debug/build', DebugBuildRspModel)
          setBuildLog((rsp.getData() as DebugBuildRspModel)?.getDetail().replace(/\x1b\[.*?m/g, '') ?? '...') // NOSONAR
          if (rsp.getRetcode() !== Retcode.RET_SUCC) break
          showRestartPrompt()
          break
        case DebugActionType.SERVER_RESTART:
          rsp = await Api.sendCsrfRequest('/debug/restart')
          break
        case DebugActionType.SERVER_SQL:
          rsp = await Api.sendCsrfRequest('/debug/sql', undefined, new DebugSqlReqModel().setQuery(sqlQuery))
          break
        default:
          setAlert(['danger', 'Invalid debug action'])
          return
      }

      setAlert([rsp.getRetcode() === Retcode.RET_SUCC ? 'success' : 'danger', rsp.getMsg()])
    } catch (err) {
      setAlert(['danger', getErrorMessage(err)])
    }
  }

  function showRestartPrompt() {
    modals.openConfirmModal({
      title: 'Restart Server?',
      children: <Text size="sm">Do you want to restart server?</Text>,
      withCloseButton: false,
      labels: { confirm: 'Yes', cancel: 'No' },
      onConfirm: () => {
        doDebugAction(DebugActionType.SERVER_RESTART)
      },
    })
  }

  return (
    <Protected state={AuthState.DEBUG} redirect className="h-full">
      <DualNavLayout>
        <Container className="flex flex-col space-y-4">
          {alert == null ? <div /> : <Alert type={alert[0]} message={alert[1]} />}
          <h2 className="text-2xl font-bold">Action</h2>
          <div className="flex space-x-4">
            <Button className="bg-cyan-400/30" onClick={doDebugAction.bind(null, DebugActionType.SERVER_RELOAD)}>
              Reload Server
            </Button>
            <Button className="bg-cyan-400/30" onClick={doDebugAction.bind(null, DebugActionType.SERVER_RESTART)}>
              Restart Server
            </Button>
            <Button className="bg-cyan-400/30" onClick={doDebugAction.bind(null, DebugActionType.SERVER_STOP)}>
              Stop Server
            </Button>
          </div>
          <h2 className="text-2xl font-bold">Build</h2>
          <div className="flex flex-col space-y-4">
            <Textarea readOnly rows={10} value={buildLog} />
            <Button className="bg-cyan-400/30" onClick={doDebugAction.bind(null, DebugActionType.SERVER_BUILD)}>
              Build Server
            </Button>
          </div>
          <h2 className="text-2xl font-bold">SQL</h2>
          <div className="flex space-x-4">
            <Input className="grow" value={sqlQuery} onChange={(e) => setSqlQuery(e.target.value)} />
            <Button className="bg-cyan-400/30" onClick={doDebugAction.bind(null, DebugActionType.SERVER_SQL)}>
              Execute
            </Button>
          </div>

          <Group>
            <Button className="bg-red-400/30 hover:bg-red-500/30" onClick={() => setSqlQuery(DELETE_QUERY)}>
              Delete Event
            </Button>
            <Button className="bg-red-400/30 hover:bg-red-500/30" onClick={() => setSqlQuery(INSERT_ATTENDEE_QUERY)}>
              Insert Attendee
            </Button>
          </Group>
        </Container>
      </DualNavLayout>
    </Protected>
  )
}
