import ModelBase from '@shared/models/base'
import UserModel, { UserJSONModel } from '@shared/models/user'

export interface GetUserRspJSONModel {
  user: UserJSONModel
}

export default class GetUserRspModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.user = new UserModel()
  }

  public getUser(): UserModel {
    return this.user
  }

  public getDisplayName(): string {
    return this.user.getDisplayName()
  }

  public getThumbnailUrl(): string {
    return this.user.getThumbnailUrl()
  }

  public getCreateTime(): Date {
    return this.user.getCreateTime()
  }

  public setUser(user: UserModel): this {
    this.user = user
    return this
  }

  public toJSONObject(): GetUserRspJSONModel {
    const { user } = this

    return {
      user: user.toJSONObject(),
    }
  }

  public fromJSONObject(json: GetUserRspJSONModel): this {
    this.validate(json, 'user', 'object')

    const { user } = json

    this.user.fromJSONObject(user)

    return this
  }

  /// Private ///

  private user: UserModel
}
