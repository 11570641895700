import DualNavLayout from '@/components/layout/dual-nav'
import Protected from '@/components/protected'
import { AuthState } from '@/constants/auth'

// Event type definition
interface Event {
  name: string
  description: string
  startTime: string
  endTime: string
  thumbnailUrl: string
  venue: {
    name: string
    phone: string
    website: string
    rating: number
    address: string
    country: string
  }
  tags: string[]
  language: string
}

// Mock event data
const mockEvent: Event = {
  name: 'Tech Conference 2024',
  description: 'An annual tech conference focusing on the latest trends in technology.',
  startTime: '2024-04-20T09:00',
  endTime: '2024-04-20T17:00',
  thumbnailUrl: 'https://via.placeholder.com/150',
  venue: {
    name: 'Tech Arena',
    phone: '+123456789',
    website: 'https://techconference.com',
    rating: 4.5,
    address: '123 Tech Street',
    country: 'Techland',
  },
  tags: ['Technology', 'Innovation', 'Networking'],
  language: 'English',
}

function EventListPage() {
  const event = mockEvent

  return (
    <Protected state={AuthState.LOGIN} redirect className='h-full'>
      <DualNavLayout>
        <div className="bg-white dark:bg-dark flex flex-col">
          <div className="container mx-auto p-4">
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="flex flex-col md:flex-row items-center">
                  <p className="text-xl md:text-3xl font-bold mb-2 md:mb-0 text-black dark:text-white overflow-hidden overflow-ellipsis">{event.name}</p>
                  {/* <p className="text-sm text-gray-500 dark:text-gray-400 md:ml-4">- {event.language}</p> */}
                  <p className="bg-gray-200 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300 md:ml-4">{event.language}</p>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Organized by: Tech Arena</p>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mt-4">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="flex flex-col md:flex-row items-center">
                  <img src={event.thumbnailUrl} alt="Event thumbnail" className="w-24 h-24 rounded-lg" />
                  <div className="md:ml-4 mt-4 md:mt-0">
                    <p className="text-lg font-bold text-black dark:text-white">{event.venue.name}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{event.venue.address}, {event.venue.country}</p>
                  </div>
                </div>
                <a href={event.venue.website} target="_blank" rel="noreferrer" className="text-sm text-blue-500 dark:text-blue-300">View website</a>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mt-4">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="flex flex-col md:flex-row items-center">
                  <img src={event.thumbnailUrl} alt="Event thumbnail" className="w-24 h-24 rounded-lg" />
                  <div className="md:ml-4 mt-4 md:mt-0">
                    <p className="text-lg font-bold text-black dark:text-white">{event.venue.name}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{event.venue.address}, {event.venue.country}</p>
                  </div>
                </div>
                <a href={event.venue.website} target="_blank" rel="noreferrer" className="text-sm text-blue-500 dark:text-blue-300">View website</a>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mt-4">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="flex flex-col md:flex-row items-center">
                  <img src={event.thumbnailUrl} alt="Event thumbnail" className="w-24 h-24 rounded-lg" />
                  <div className="md:ml-4 mt-4 md:mt-0">
                    <p className="text-lg font-bold text-black dark:text-white">{event.venue.name}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{event.venue.address}, {event.venue.country}</p>
                  </div>
                </div>
                <a href={event.venue.website} target="_blank" rel="noreferrer" className="text-sm text-blue-500 dark:text-blue-300">View website</a>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mt-4">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="flex flex-col md:flex-row items-center">
                  <img src={event.thumbnailUrl} alt="Event thumbnail" className="w-24 h-24 rounded-lg" />
                  <div className="md:ml-4 mt-4 md:mt-0">
                    <p className="text-lg font-bold text-black dark:text-white">{event.venue.name}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{event.venue.address}, {event.venue.country}</p>
                  </div>
                </div>
                <a href={event.venue.website} target="_blank" rel="noreferrer" className="text-sm text-blue-500 dark:text-blue-300">View website</a>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mt-4">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="flex flex-col md:flex-row items-center">
                  <img src={event.thumbnailUrl} alt="Event thumbnail" className="w-24 h-24 rounded-lg" />
                  <div className="md:ml-4 mt-4 md:mt-0">
                    <p className="text-lg font-bold text-black dark:text-white">{event.venue.name}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{event.venue.address}, {event.venue.country}</p>
                  </div>
                </div>
                <a href={event.venue.website} target="_blank" rel="noreferrer" className="text-sm text-blue-500 dark:text-blue-300">View website</a>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mt-4">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="flex flex-col md:flex-row items-center">
                  <img src={event.thumbnailUrl} alt="Event thumbnail" className="w-24 h-24 rounded-lg" />
                  <div className="md:ml-4 mt-4 md:mt-0">
                    <p className="text-lg font-bold text-black dark:text-white">{event.venue.name}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{event.venue.address}, {event.venue.country}</p>
                  </div>
                </div>
                <a href={event.venue.website} target="_blank" rel="noreferrer" className="text-sm text-blue-500 dark:text-blue-300">View website</a>
              </div>
            </div>
          </div>
        </div>
      </DualNavLayout>
    </Protected>
  )
}

export default EventListPage
