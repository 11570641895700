import Auth from '@/utils/auth'
import { faEllipsisV, faReply, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Menu } from '@mantine/core'
import ChatMessageModel from '@shared/models/chat/chat-message'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export type ChatMessageActionType = 'delete' | 'reply'

export type ChatMessageActionProps = {
  message: ChatMessageModel
  onAction?: (actionType: ChatMessageActionType, message: ChatMessageModel) => void
}

export default function ChatMessageAction({
  message,
  onAction,
}: Readonly<ChatMessageActionProps>): ReactElement | null {
  const { t } = useTranslation('chat')
  const seqId = message.getId()
  if (seqId == null || seqId < 0n) return null

  const isMyMsg = Auth.currentUser?.getId() === message.getSender()?.getId()

  return (
    <Menu position="top" arrowPosition="center">
      <Menu.Target>
        <ActionIcon
          className="bg-gray-400 dark:bg-gray-800 hover:bg-primary-700 dark:hover:bg-primary-700"
          p={18}
          radius="xl"
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {isMyMsg ? (
          <Menu.Item onClick={() => onAction?.('delete', message)} color="red" className='font-sans'>
            <FontAwesomeIcon className="pr-2" icon={faTrash} />
            {t('action.delete')}
          </Menu.Item>
        ) : null}
        <Menu.Item onClick={() => onAction?.('reply', message)} className='font-sans'>
          <FontAwesomeIcon className="pr-2" icon={faReply} />
          {t('action.reply')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
