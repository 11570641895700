import TopNavLayout from '@/components/layout/top-nav'
import Protected from '@/components/protected'
import { firebaseAuth } from '@/configs/firebase'
import { AuthState } from '@/constants/auth'
import Auth from '@/utils/auth'
import { getErrorMessage } from '@/utils/error'
import { MouseEvent, ReactElement, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export default function VerifyPage(): ReactElement {
  const [alert, setAlert] = useState<[string, string] | null>(null)
  const [emailSent, setEmailSent] = useState(false)
  const [disable, setDisable] = useState(false)

  const { t } = useTranslation('auth')

  // Get current user data
  const user = firebaseAuth.currentUser
  if (user == null) {
    Auth.logout()
    return <Protected state={AuthState.VERIFY} redirect><div /></Protected>
  }

  async function onResend(evt: MouseEvent<HTMLButtonElement>): Promise<void> {
    try {
      evt.preventDefault()
      setDisable(true)

      // Resend verify email
      await Auth.resendVerification()

      setEmailSent(true)
      setAlert(['success', t('subHeading.verify', { replace: { email: user?.email } })])
    } catch (err) {
      // Show error message
      setAlert(['danger', getErrorMessage(err)])
      setDisable(false)
    }
  }

  return (
    <Protected state={AuthState.VERIFY} redirect className='h-full'>
      <TopNavLayout>
        <div className='max-w-lg mx-auto my-8 p-4 border rounded shadow flex flex-col items-center justify-around'>
          <h1 className='text-xl font-bold my-2'>{t('mainHeading.verify')}</h1>
          <hr className='w-full' />
          {alert != null && (
            <div className={`w-full text-center p-4 mb-2 rounded ${alert[0] === 'danger' ? 'bg-red-100 text-red-700 border-red-500' : 'bg-blue-100 text-blue-700 border-blue-500'}`} role="alert">
              {alert[1]}
              <button type="button" className="absolute top-0 right-0 p-1" onClick={() => setAlert(null)}>×</button>
            </div>
          )}
          {/* Guide Text */}
          <div className='w-full py-4 text-center'>
            <p>
              <Trans t={t} i18nKey='guide.verify.sent' values={{ email: user.email }}>
                <span className='text-blue-500'>?</span>
              </Trans>
              &nbsp;
              <Trans t={t} i18nKey='guide.verify.checkSpam'>
                <span className='underline italic'>?</span>
              </Trans>
            </p>
            <br />
            <p className={`${emailSent ? 'hidden' : 'block'}`}>
              <Trans t={t} i18nKey='guide.verify.resend'>
                <span className='underline italic'>?</span>
              </Trans>
            </p>
          </div>
          {/* Resend verify button */}
          <button className={`${emailSent ? 'hidden' : 'inline-block'} mt-4 px-4 py-2 bg-blue-500 text-white font-bold rounded hover:bg-blue-600 disabled:bg-blue-300`} onClick={onResend} disabled={emailSent || disable}>
            {t('action.reverify')}
          </button>
        </div>
      </TopNavLayout>
    </Protected>
  )
}