import { createFormContext } from '@mantine/form'

export interface CreateEventFormData {
  eventName: string
  eventStartTime: Date | null
  eventEndTime: Date | null
  eventLocation: string
  eventPrice: number
  eventPlaceId: string
  eventCategory: string
}

export const [CreateEventFormProvider, useCreateEventFormContext, useCreateEventForm] = createFormContext<CreateEventFormData>()