import ModelBase from '@shared/models/base'

export interface AttendeeInfoJSONModel {
  id: string
  thumbnailUrl: string
  displayName: string
}

export default class AttendeeInfoModel extends ModelBase {

  /// Public ///

  public constructor() {
    super()

    this.id = ''
    this.thumbnailUrl = ''
    this.displayName = ''
  }

  public getId(): string {
    return this.id
  }

  public getThumbnailUrl(): string {
    return this.thumbnailUrl
  }

  public getDisplayName(): string {
    return this.displayName
  }

  public setId(id: string): this {
    this.id = id
    return this
  }

  public setThumbnailUrl(thumbnailUrl: string): this {
    this.thumbnailUrl = thumbnailUrl
    return this
  }

  public setDisplayName(displayName: string): this {
    this.displayName = displayName
    return this
  }

  public toJSONObject(): AttendeeInfoJSONModel {
    const { id, thumbnailUrl, displayName } = this

    return {
      id,
      thumbnailUrl,
      displayName
    }
  }

  public fromJSONObject(json: AttendeeInfoJSONModel): this {
    this.validate(json, 'id', 'string')
    this.validate(json, 'thumbnailUrl', 'string')
    this.validate(json, 'displayName', 'string')

    const { id, thumbnailUrl, displayName } = json

    this.id = id
    this.thumbnailUrl = thumbnailUrl
    this.displayName = displayName

    return this
  }

  /// Private ///

  private id: string
  private thumbnailUrl: string
  private displayName: string
}