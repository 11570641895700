import { BinDecoder, BinEncoder, EndianBinaryReader, EndianBinaryWriter } from '../utils/binary'
import ModelBase from './base'

export enum BinaryValueType {
  BYTE = 0,
  BOOL,
  UINT16,
  UINT32,
  UINT64,
  INT16,
  INT32,
  INT64,
  FLOAT,
  STRING,
  BASE64,
  JWT,
  BIN,
  ARRAY,
  DICT
}

const DefaultBinaryValueMap = {
  [BinaryValueType.BYTE]: 0,
  [BinaryValueType.BOOL]: false,
  [BinaryValueType.INT16]: 0,
  [BinaryValueType.INT32]: 0,
  [BinaryValueType.INT64]: 0n,
  [BinaryValueType.UINT16]: 0,
  [BinaryValueType.UINT32]: 0,
  [BinaryValueType.UINT64]: 0n,
  [BinaryValueType.FLOAT]: 0,
  [BinaryValueType.STRING]: '',
  [BinaryValueType.BASE64]: '',
  [BinaryValueType.JWT]: ''
}

type BinaryValueDefinition = {
  type: (
    BinaryValueType.BYTE | BinaryValueType.BOOL | BinaryValueType.INT16 |
    BinaryValueType.INT32 | BinaryValueType.INT64 | BinaryValueType.UINT16 |
    BinaryValueType.UINT32 | BinaryValueType.UINT64 | BinaryValueType.FLOAT |
    BinaryValueType.STRING | BinaryValueType.BASE64 | BinaryValueType.JWT
  )
  optional?: boolean
} | {
  type: BinaryValueType.BIN
  optional?: boolean
  ctor?: new () => BinaryModelBase
} | {
  type: BinaryValueType.ARRAY
  optional?: boolean
  value: BinaryValueDefinition
} | {
  type: BinaryValueType.DICT
  optional?: boolean
  key: BinaryValueDefinition
  value: BinaryValueDefinition
}

export default abstract class BinaryModelBase<TBModel extends BinaryModelBase = any, TJModel extends BinaryModelBase = any> extends ModelBase<TJModel> {
  /// Public ///

  public constructor() {
    super()

    this.fieldList = []
  }

  public serialize(writer: EndianBinaryWriter = new EndianBinaryWriter()): Buffer {
    const valueWriter = new EndianBinaryWriter()

    const maskBits: boolean[] = []
    for (const [name, field] of this.fieldList) {
      try {
        const value = (<any>this)[name]

        // Check non optional null field
        if (!field.optional && value == null) throw new Error(`field '${name}' is null`)

        maskBits.unshift(this.serializeValue(field, valueWriter, value))
      } catch (err) {
        throw new Error(`failed to serialize field '${name}': ${String(err)}`)
      }
    }

    let mask = 0n
    for (const bit of maskBits) {
      mask = (mask << 1n) | (bit ? 1n : 0n)
    }

    this.writeBitmask(writer, mask)
    writer.WriteBytes(valueWriter.buffer.subarray(0, valueWriter.position))

    return writer.buffer.subarray(0, writer.position)
  }

  public deserialize(reader: EndianBinaryReader | Buffer): this {
    if (Buffer.isBuffer(reader)) reader = new EndianBinaryReader(reader)

    let mask = this.readBitmask(reader)
    for (const [name, field] of this.fieldList) {
      try {
        if ((mask & 1n) === 1n) {
          (<any>this)[name] = this.deserializeValue(field, reader)
        }
        mask >>= 1n
      } catch (err) {
        throw new Error(`failed to deserialize field '${name}': ${String(err)}`)
      }
    }

    return this
  }

  /// Protected ///

  protected addField(name: keyof TBModel, field: BinaryValueDefinition): void {
    this.fieldList.push([String(name), field])
  }

  /// Private ///

  private fieldList: [string, BinaryValueDefinition][]

  private readBitmask(reader: EndianBinaryReader): bigint {
    const bits = this.fieldList.length

    if (bits <= 0) return 0n
    if (bits <= 8) return BigInt(BinDecoder.ReadByte(reader))
    if (bits <= 16) return BigInt(BinDecoder.ReadUInt16(reader))
    if (bits <= 32) return BigInt(BinDecoder.ReadUInt32(reader))
    if (bits <= 64) return BinDecoder.ReadUInt64(reader)
    return 0n
  }

  private writeBitmask(writer: EndianBinaryWriter, mask: bigint): void {
    const bits = this.fieldList.length

    if (bits <= 0) return
    else if (bits <= 8) BinEncoder.WriteByte(writer, Number(mask))
    else if (bits <= 16) BinEncoder.WriteUInt16(writer, Number(mask))
    else if (bits <= 32) BinEncoder.WriteUInt32(writer, Number(mask))
    else if (bits <= 64) BinEncoder.WriteUInt64(writer, mask)
  }

  private serializeValue(definition: BinaryValueDefinition, writer: EndianBinaryWriter, value: any): boolean {
    // Skip serialize for null or default value
    if (value == null || value === DefaultBinaryValueMap[<keyof typeof DefaultBinaryValueMap>definition.type]) return false

    // Write value
    switch (definition.type) {
      case BinaryValueType.BYTE:
        if (typeof value !== 'number') throw new Error(`mismatch type, expected 'number' got '${typeof value}'`)
        BinEncoder.WriteByte(writer, value)
        break
      case BinaryValueType.BOOL:
        if (typeof value !== 'boolean') throw new Error(`mismatch type, expected 'boolean' got '${typeof value}'`)
        BinEncoder.WriteBool(writer, value)
        break
      case BinaryValueType.UINT16:
        if (typeof value !== 'number') throw new Error(`mismatch type, expected 'number' got '${typeof value}'`)
        BinEncoder.WriteUInt16(writer, value)
        break
      case BinaryValueType.UINT32:
        if (typeof value !== 'number') throw new Error(`mismatch type, expected 'number' got '${typeof value}'`)
        BinEncoder.WriteUInt32(writer, value)
        break
      case BinaryValueType.UINT64:
        if (typeof value !== 'bigint') throw new Error(`mismatch type, expected 'bigint' got '${typeof value}'`)
        BinEncoder.WriteUInt64(writer, value)
        break
      case BinaryValueType.INT16:
        if (typeof value !== 'number') throw new Error(`mismatch type, expected 'number' got '${typeof value}'`)
        BinEncoder.WriteInt16(writer, value)
        break
      case BinaryValueType.INT32:
        if (typeof value !== 'number') throw new Error(`mismatch type, expected 'number' got '${typeof value}'`)
        BinEncoder.WriteInt32(writer, value)
        break
      case BinaryValueType.INT64:
        if (typeof value !== 'bigint') throw new Error(`mismatch type, expected 'bigint' got '${typeof value}'`)
        BinEncoder.WriteFixedInt64(writer, value)
        break
      case BinaryValueType.FLOAT:
        if (typeof value !== 'number') throw new Error(`mismatch type, expected 'number' got '${typeof value}'`)
        BinEncoder.WriteFloat(writer, value)
        break
      case BinaryValueType.STRING:
        if (typeof value !== 'string') throw new Error(`mismatch type, expected 'string' got '${typeof value}'`)
        BinEncoder.WriteString(writer, value)
        break
      case BinaryValueType.BASE64:
        if (typeof value !== 'string') throw new Error(`mismatch type, expected 'string' got '${typeof value}'`)
        BinEncoder.WriteBytes(writer, Buffer.from(value.replace(/-/g, '+').replace(/_/g, '/'), 'base64'))
        break
      case BinaryValueType.JWT: {
        if (typeof value !== 'string') throw new Error(`mismatch type, expected 'string' got '${typeof value}'`)
        const parts = value.split('.')
        if (parts.length !== 3) throw new Error('invalid jwt')
        for (const part of parts) {
          this.serializeValue({ type: BinaryValueType.BASE64 }, writer, part)
        }
        break
      }
      case BinaryValueType.BIN:
        if (!(value instanceof BinaryModelBase)) throw new Error(`mismatch type, expected 'binary' got '${typeof value}'`)
        value.serialize(writer)
        break
      case BinaryValueType.ARRAY:
        if (!Array.isArray(value)) throw new Error(`mismatch type, expected 'array' got '${typeof value}'`)
        if (value.length === 0) return false
        BinEncoder.WriteArray(writer, this.serializeValue.bind(this, definition.value), value)
        break
      case BinaryValueType.DICT:
        if (typeof value !== 'object') throw new Error(`mismatch type, expected 'object' got '${typeof value}'`)
        if (Object.keys(value).length === 0) return false
        BinEncoder.WriteDict(writer, this.serializeValue.bind(this, definition.key), this.serializeValue.bind(this, definition.value), new Map(Object.entries(value)))
        break
      default:
        throw new Error('invalid value type')
    }

    return true
  }

  private deserializeValue(definition: BinaryValueDefinition, reader: EndianBinaryReader): any {
    // Write value
    switch (definition.type) {
      case BinaryValueType.BYTE:
        return BinDecoder.ReadByte(reader)
      case BinaryValueType.BOOL:
        return BinDecoder.ReadBool(reader)
      case BinaryValueType.UINT16:
        return BinDecoder.ReadUInt16(reader)
      case BinaryValueType.UINT32:
        return BinDecoder.ReadUInt32(reader)
      case BinaryValueType.UINT64:
        return BinDecoder.ReadUInt64(reader)
      case BinaryValueType.INT16:
        return BinDecoder.ReadInt16(reader)
      case BinaryValueType.INT32:
        return BinDecoder.ReadInt32(reader)
      case BinaryValueType.INT64:
        return BinDecoder.ReadFixedInt64(reader)
      case BinaryValueType.FLOAT:
        return BinDecoder.ReadFloat(reader)
      case BinaryValueType.STRING:
        return BinDecoder.ReadString(reader)
      case BinaryValueType.BASE64:
        return BinDecoder.ReadBytes(reader).toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
      case BinaryValueType.JWT:
        return `${this.deserializeValue({ type: BinaryValueType.BASE64 }, reader)}.${this.deserializeValue({ type: BinaryValueType.BASE64 }, reader)}.${this.deserializeValue({ type: BinaryValueType.BASE64 }, reader)}`
      case BinaryValueType.BIN:
        if (definition.ctor == null) {
          if (!definition.optional) throw new Error('missing bin ctor')
          return null
        }
        return new definition.ctor().deserialize(reader)
      case BinaryValueType.ARRAY: {
        const value: any[] = []
        BinDecoder.ReadArray(reader, this.deserializeValue.bind(this, definition.value), value)
        return value
      }
      case BinaryValueType.DICT: {
        const value = new Map()
        BinDecoder.ReadDict(reader, this.deserializeValue.bind(this, definition.key), this.deserializeValue.bind(this, definition.value), value)
        return Object.fromEntries(Array.from(value.entries()))
      }
      default:
        throw new Error('invalid value type')
    }
  }
}