import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from '@mantine/carousel'
import { Button, Paper, Text, Title, rem, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { link } from 'fs'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

interface CardProps {
  image: string
  title: string
  category: string
  eventId: string
}

function Card({ image, title, category, eventId }: CardProps) {
  const { t } = useTranslation('event')
  const navigate = useNavigate()

  return (
    <Paper
      shadow="md"
      p="xl"
      radius="md"
      withBorder
      style={{ backgroundImage: `url(${image})` }}
      className='h-96 flex flex-col justify-between items-start bg-cover bg-center'
      h={400}
    >
      <div>
        <Text className=' text-white/70 font-bold uppercase text-base'>
          {category}
        </Text>
        <Title order={3} className='font-sans font-black text-white text-xl mt-2'>
          {title}
        </Title>
      </div>
      <Button
        variant='light'
        // leftSection={<IconPhoto size={14} />}
        rightSection={<FontAwesomeIcon icon={faArrowRight} size="sm" />}
        className='bg-blue-500/60 hover:bg-blue-600/60 text-white font-sans mt-4'
        onClick={() => navigate(`/event/${eventId}/`)}
      >
        {t('title.review')}
      </Button>
    </Paper>
  )
}

const data = [
  {
    image:
      'https://images.unsplash.com/photo-1508193638397-1c4234db14d8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    title: 'Best forests to visit in North America',
    category: 'nature',
    eventId: '1',
  },
  {
    image:
      'https://images.unsplash.com/photo-1559494007-9f5847c49d94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    title: 'Hawaii beaches review: better than you think',
    category: 'beach',
    eventId: '511d8503-9ae2-4702-a07c-bc9b3e920664',
  },
  {
    image:
      'https://images.unsplash.com/photo-1608481337062-4093bf3ed404?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    title: 'Mountains at night: 12 best locations to enjoy the view',
    category: 'nature',
    eventId: '3',
  },
  {
    image:
      'https://images.unsplash.com/photo-1507272931001-fc06c17e4f43?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    title: 'Aurora in Norway: when to visit for best experience',
    category: 'nature',
    eventId: '4',
  },
  {
    image:
      'https://images.unsplash.com/photo-1510798831971-661eb04b3739?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    title: 'Best places to visit this winter',
    category: 'tourism',
    eventId: '5',
  },
  {
    image:
      'https://images.unsplash.com/photo-1582721478779-0ae163c05a60?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80',
    title: 'Active volcanos reviews: travel at your own risk',
    category: 'nature',
    eventId: '6',
  },
]

export default function HomeCarousel(): ReactElement {
  const theme = useMantineTheme()
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const slides = data.map((item) => (
    <Carousel.Slide key={item.title}>
      <Card {...item} />
    </Carousel.Slide>
  ))

  return (
    <Carousel
      slideSize={{ base: '100%', sm: '50%' }}
      slideGap={{ base: rem(2), sm: 'xl' }}
      align="start"
      slidesToScroll={mobile ? 1 : 2}
      height={400}
      classNames={{ control: 'bg-gray-200' }}
    >
      {slides}
    </Carousel>
  )
}
