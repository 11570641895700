import TopNav from '@/components/navigation/top-nav'
import { ReactElement } from 'react'
import VerticalScrollLayout from './vertical-scroll'

export type TopNavLayoutProps = {
  className?: string
  children?: ReactElement[] | ReactElement
}

export default function TopNavLayout({ className, children }: Readonly<TopNavLayoutProps>): ReactElement {
  return (
    <div className='flex flex-col h-full overflow-hidden bg-white dark:bg-dark text-black dark:text-white'>
      {/* Top Navbar */}
      <TopNav />

      {/* Main Content */}
      <VerticalScrollLayout className={className}>{children}</VerticalScrollLayout>
    </div>
  )
}