import Alert, { AlertType } from '@/components/alert'
import DualNavLayout from '@/components/layout/dual-nav'
import Protected from '@/components/protected'
import { AuthState } from '@/constants/auth'
import { Retcode } from '@/models/api'
import Api from '@/utils/api'
import Auth from '@/utils/auth'
import DateTime from '@/utils/datetime'
import { getErrorMessage } from '@/utils/error'
import { fetchEventImageUrl } from '@/utils/firebase'
import {
  faCalendarPlus,
  faChevronLeft,
  faLocationDot,
  faTicket,
  faClock as fasClock,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from '@mantine/carousel'
import { AspectRatio, Button, Group, Image, Pill, Stack, Text, TypographyStylesProvider } from '@mantine/core'
import { modals } from '@mantine/modals'
import GetEventReqModel from '@shared/models/api/request/get-event'
import PublishEventReqModel from '@shared/models/api/request/publish-event'
import GetEventRspModel from '@shared/models/api/response/get-event'
import EventModel from '@shared/models/event'
import { MapCameraChangedEvent, MapCameraProps, Map, AdvancedMarker } from '@vis.gl/react-google-maps'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const INITIAL_CAMERA = {
  center: { lat: 22.302383, lng: 114.2037 },
  zoom: 12,
}

export default function EventPublishPage(): ReactElement {
  const { id } = useParams()
  const { t } = useTranslation('event')
  const navigate = useNavigate()
  const [alert, setAlert] = useState<[AlertType, string] | null>(null)
  const [eventData, setEventData] = useState<EventModel | null>(null)
  const [tags, setTags] = useState<ReactElement[]>([])
  const beginTime = new DateTime(eventData?.getBeginTime())
  const endTime = new DateTime(eventData?.getEndTime())
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [locationPos, setLocationPos] = useState<google.maps.LatLngLiteral | null>(null)

  useEffect(() => {
    const eventId = id ?? '-1'
    if (!eventId) return navigate('/')

    Auth.getAuthState().then(() => {
      Api.sendRequest('/event/getEvent', GetEventRspModel, new GetEventReqModel().setId(eventId)).then(async (rsp) => {
        if (rsp.getRetcode() !== Retcode.RET_SUCC) return navigate('/')

        const data = rsp.getData()
        if (data == null) return navigate('/')

        setTags([])
        const eventData = data.getEvent()
        const tags = JSON.parse(eventData.getTags())
        tags.map((tag: string) => {
          setTags((prev) => [
            ...prev,
            <Pill
              key={tag}
              color="blue"
              size="md"
              className="mr-2 rounded-md font-sans text-base bg-primary-700 text-white/80"
            >
              {tag}
            </Pill>,
          ])
        })
        // Load organizer after setting event data
        setEventData(eventData)
        setCameraProps({ center: { lat: eventData.getLocationLat(), lng: eventData.getLocationLng() }, zoom: 12 })
        setLocationPos({ lat: eventData.getLocationLat(), lng: eventData.getLocationLng() })
      })
    })
  }, [id])

  // Fetch image urls
  useEffect(() => {
    const loadImages = async () => {
      if (!eventData) return console.log('No event data')
      const images = JSON.parse(eventData.getImages())
      const urls = await Promise.all(images.map(fetchEventImageUrl))
      setImageUrls(urls.filter((url) => url !== null) as string[])
    }

    loadImages()
  }, [eventData])

  const INITIAL_CAROUSEL_SETTINGS = {
    slideSize: { base: '100%', xs: '70%', lg: '50%' },
    height: 'auto',
    slideGap: 'xl',
    withIndicators: true,
    initialSlide: 1,
    dragFree: true,
    classNames: {
      root: 'mb-4',
      control: 'dark:bg-gray-200/20 dark:text-white bg-gray-800/20 dark:text-white',
      controls: 'sm:-mx-12 max-sm:hidden',
      indicator: 'bg-gray-200/80',
    },
  }

  const slides = imageUrls.map((url, index) => (
    <Carousel.Slide key={index}>
      <Image
        src={url}
        h={{ base: '300', sm: '400' }}
        className="w-full rounded-md object-fill"
        onClick={() => {
          modals.open({
            title: 'Gallery',
            radius: 'md',
            size: 'xl',
            centered: true,
            children: (
              <AspectRatio ratio={1}>
                <Image src={url} alt="Gallery" className="w-full rounded-md object-fill" />
              </AspectRatio>
            ),
          })
        }}
      />
    </Carousel.Slide>
  ))

  async function onPublish() {
    try {
      const id = eventData?.getId()
      if (id == null) throw new Error('id == null')

      const rsp = await Api.sendCsrfRequest(
        '/event/publishEvent',
        undefined,
        new PublishEventReqModel().fromJSONObject({ eventId: id }),
      )
      if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

      setAlert(['success', 'Event published successfully'])
      navigate(`/event/${id}`)
    } catch (err) {
      // Show error message
      setAlert(['danger', getErrorMessage(err)])
    }
  }

  function onPrevious() {
    navigate(`/event/${id}/edit`)
  }

  const [cameraProps, setCameraProps] = useState<MapCameraProps>(INITIAL_CAMERA)
  const handleCameraChange = useCallback((event: MapCameraChangedEvent) => {
    setCameraProps(event.detail)
  }, [])

  return (
    <Protected state={AuthState.LOGIN} redirect className="h-full">
      <DualNavLayout className="container mx-auto px-2 md:px-24 lg:px-32 xl:px-44">
        <h1 className="text-3xl font-bold mb-4">{t('title.review')}</h1>

        <Carousel {...INITIAL_CAROUSEL_SETTINGS} className="w-full h-auto">
          {slides}
        </Carousel>

        <h1 className="text-3xl font-bold mb-4">{eventData?.getName()}</h1>

        <>
          {eventData && (
            <TypographyStylesProvider className="mb-8">
              <Text dangerouslySetInnerHTML={{ __html: eventData?.getDescription() }} className="font-sans"></Text>
            </TypographyStylesProvider>
          )}
        </>

        <Text className="font-sans text-xl sm:text-2xl md:text-3xl font-bold mb-4">{t('tags.label')}</Text>
        <div className="mb-6">{tags}</div>

        <div className="mb-4">
          {locationPos && (
            <AspectRatio ratio={16 / 9} className="z-0 mt-10">
              <Map
                id="publish-map"
                {...cameraProps}
                onCameraChanged={handleCameraChange}
                defaultCenter={locationPos ?? cameraProps.center}
                defaultZoom={12}
                gestureHandling={'greedy'}
                mapId={'63208d5c9c3fd3ba'}
              >
                {locationPos && <AdvancedMarker position={locationPos} />}
              </Map>
            </AspectRatio>
          )}
        </div>

        <Group gap="xs" className="mb-2">
          <div className="flex justify-center items-center h-8 w-8 rounded-md dark:bg-white/10">
            <FontAwesomeIcon icon={fasClock} size="lg" />
          </div>
          <Stack gap="0" align="flex-start">
            <Text className="text-sm md:text-base font-sans mb-0">{beginTime.toFormatString('D MMMM YYYY')}</Text>
            <Text className="text-sm md:text-base font-sans">
              {beginTime.toFormatString('dddd, HH:mm A')} to {endTime.toFormatString('HH:mm A')} GMT+8
            </Text>
          </Stack>
        </Group>
        <Group gap="xs" className="mb-2">
          <div className="flex justify-center items-center h-8 w-8 rounded-md dark:bg-white/10">
            <FontAwesomeIcon icon={faLocationDot} size="lg" />
          </div>
          <Stack gap="0" align="flex-start">
            <Text className="text-sm md:text-base font-sans">{eventData?.getLocationName() ?? 'Unknown'}</Text>
          </Stack>
        </Group>
        <Group gap="xs" className="mb-6">
          <div className="flex justify-center items-center h-8 w-8 rounded-md dark:bg-white/10">
            <FontAwesomeIcon icon={faTicket} size="lg" />
          </div>
          <Stack gap="0" align="flex-start">
            <Text className="text-sm md:text-base font-sans">${eventData?.getPrice()}</Text>
            <Text className="text-sm md:text-base font-sans">{t('preHead')}</Text>
          </Stack>
        </Group>

        <div>{alert != null && <Alert type={alert[0]} message={alert[1]} />}</div>

        <Group gap="xl" className="mb-1" justify="center">
          <Button
            variant="light"
            color="orange"
            leftSection={<FontAwesomeIcon icon={faChevronLeft} size="sm" />}
            className={'bg-orange-400/30'}
            onClick={onPrevious}
          >
            <Text className="font-sans text-sm">{t('submit.previous')}</Text>
          </Button>
          <Button
            variant="light"
            color="red"
            leftSection={<FontAwesomeIcon icon={faCalendarPlus} size="sm" />}
            className={'bg-red-400/30'}
            onClick={onPublish}
          >
            <Text className="font-sans text-sm">{t('submit.publish')}</Text>
          </Button>
        </Group>
      </DualNavLayout>
    </Protected>
  )
}
