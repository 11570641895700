import React from 'react';
import { Avatar, Text, Badge } from '@mantine/core';

interface UserCardProps {
  name: string;
  role: string;
}

const UserCardSmall: React.FC<UserCardProps> = ({ name, role }) => {
  return (
    <div className="flex flex-col items-center mr-4 min-h-16 min-w-16">
      <Avatar size="lg" />
      <Text size="sm" className="mt-1 font-medium">{name}</Text>
      <Badge color="blue" variant="light">{role}</Badge>
    </div>
  );
};

export default UserCardSmall;