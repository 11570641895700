import Auth from '@/utils/auth'
import { Alert, Avatar, Collapse, Group, Stack, Text } from '@mantine/core'
import ChatMessageModel from '@shared/models/chat/chat-message'
import { ReactElement, useEffect, useState } from 'react'
import DateTime from '../../utils/datetime'
import { fetchEventImageUrl } from '../../utils/firebase'
import ChatMessageAction, { ChatMessageActionType } from './chat-message-action'

export type ChatMessageProps = {
  bgColor?: string
  fgColor?: string
  highlight?: boolean
  message: ChatMessageModel
  onAction?: (actionType: ChatMessageActionType, message: ChatMessageModel) => void
}

export default function ChatMessage({ bgColor, fgColor, highlight, message, onAction }: Readonly<ChatMessageProps>): ReactElement {
  const [isTimeOpen, setIsTimeOpen] = useState<boolean>(false)
  const [hovered, setHovered] = useState<boolean>(false)
  const [iconUrl, setIconUrl] = useState<string>('')

  const senderThumbnailUrl = message.getSender()?.getThumbnailUrl() ?? ''
  const senderDisplayName = message.getSender()?.getDisplayName() ?? 'Guest'
  const isMyMsg = Auth.currentUser?.getId() === message.getSender()?.getId()
  const sentTime = new DateTime(message.getSentTime() ?? 0).removeTimezoneOffset()
  const replyData = message.getReplyData()
  const isReplyMyMsg = Auth.currentUser?.getId() === replyData?.getSender()?.getId()
  const isReplyToSelf = message.getSender()?.getId() === replyData?.getSender()?.getId()
  const isDeleted = message.getTextData() == null
  const isReplyDeleted = message.getReplyData()?.getTextData() == null
  const justify = isMyMsg ? 'right' : 'left'

  // Fetch icon url
  useEffect(() => {
    if (senderThumbnailUrl != null) fetchEventImageUrl(senderThumbnailUrl).then(iconUrl => iconUrl && setIconUrl(iconUrl))
  }, [])

  return (
    <>
      <Group
        className={`py-2 flex-nowrap ${highlight ? 'bg-primary-700/20' : ''}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        justify={justify}
        align='flex-end'
      >
        {/* User icon */}
        <Avatar
          src={iconUrl}
          radius='xl'
        />

        {!isDeleted && hovered ? <ChatMessageAction message={message} onAction={onAction} /> : null}

        {/* Content stack */}
        <Stack className='min-w-0' p={0} m={0} gap={2}>
          {/* User display name */}
          <Text size='sm' c='dimmed' className='font-sans'>{senderDisplayName}</Text>

          {/* Reply content stack */}
          <Stack p={0} m={0} gap={0} hidden={isDeleted || replyData == null}>
            <Group align='center' p={0} m={0}>
              <Text size='xs' p={0} className='font-sans'>
                {isMyMsg ? 'You' : senderDisplayName}{' replied to '}
                {isReplyToSelf && (isMyMsg ? 'yourself' : 'themself') || ((isReplyMyMsg ? 'you' : replyData?.getSender()?.getDisplayName()) ?? 'Guest')}
              </Text>
            </Group>
            <Group>
              <Alert
                color='gray'
                variant={isReplyDeleted ? 'outline' : 'light'}
                radius='lg'
                py={8}
              >
                {isReplyDeleted ? <Text c='gray' size='xs' className='font-sans'>Message deleted</Text> : (replyData?.getTextData())}
              </Alert>
            </Group>
          </Stack>

          {/* Message content group */}
          <Group align='center'>
            <Alert
              color={bgColor ?? 'blue'}
              radius='lg'
              py={8}
              variant={isDeleted ? 'outline' : 'light'}
              onClick={() => setIsTimeOpen(open => !open)}
            >
              {isDeleted ? <Text c='red' size='xs' className='font-sans'>Message deleted</Text> : (<Text c={fgColor} className='font-sans'>{message.getTextData()}</Text>)}
            </Alert>
          </Group>
        </Stack>
      </Group>
      <Group justify={justify}>
        <Collapse in={isTimeOpen} px='xs'>
          <Text size='xs' c='dimmed' className='font-sans'>{sentTime.toFormatString('YYYY-MM-DD hh:mm:ss A')}</Text>
        </Collapse>
      </Group>
    </>
  )
}