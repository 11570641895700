import BinaryModelBase, { BinaryValueType } from '@shared/models/binary'
import UserSimpleModel, { UserSimpleJSONModel } from '@shared/models/user/simple'

export interface ChatMessageJSONModel {
  id: string | null
  sender: UserSimpleJSONModel | null
  sentTime: number | null
  replyData: ChatMessageJSONModel | null
  textData: string | null
}

export default class ChatMessageModel extends BinaryModelBase<ChatMessageModel> {
  /// Public ///

  public constructor() {
    super()

    this.id = null
    this.sender = null
    this.sentTime = null
    this.replyData = null
    this.textData = null

    this.addField('Id', { type: BinaryValueType.UINT64, optional: true })
    this.addField('Sender', { type: BinaryValueType.BIN, optional: true, ctor: UserSimpleModel })
    this.addField('SentTime', { type: BinaryValueType.UINT64, optional: true })
    this.addField('ReplyData', { type: BinaryValueType.BIN, optional: true, ctor: ChatMessageModel })
    this.addField('TextData', { type: BinaryValueType.STRING, optional: true })
  }

  public getId(): bigint | null {
    return this.id
  }

  public getSender(): UserSimpleModel | null {
    return this.sender
  }

  public getSentTime(): Date | null {
    return this.sentTime
  }

  public getReplyData(): ChatMessageModel | null {
    return this.replyData
  }

  public getTextData(): string | null {
    return this.textData
  }

  public setId(id: bigint | null): this {
    this.id = id
    return this
  }

  public setSender(sender: UserSimpleModel | null): this {
    this.sender = sender
    return this
  }

  public setSentTime(sentTime: Date | null): this {
    this.sentTime = sentTime
    return this
  }

  public setReplyData(replyData: ChatMessageModel | null): this {
    this.replyData = replyData
    return this
  }

  public setTextData(textData: string | null): this {
    this.textData = textData
    return this
  }

  public toJSONObject(): ChatMessageJSONModel {
    const { id, sender, sentTime, replyData, textData } = this

    return {
      id: id?.toString() ?? null,
      sender: sender?.toJSONObject() ?? null,
      sentTime: sentTime?.getTime() ?? null,
      replyData: replyData?.toJSONObject() ?? null,
      textData
    }
  }

  public fromJSONObject(json: ChatMessageJSONModel): this {
    this.validate(json, 'id', 'string', true)
    this.validate(json, 'sender', 'object', true)
    this.validate(json, 'sentTime', 'number', true)
    this.validate(json, 'replyData', 'object', true)
    this.validate(json, 'textData', 'string', true)

    const { id, sender, sentTime, replyData, textData } = json

    this.id = BigInt(id || '0')
    this.sender = sender == null ? null : new UserSimpleModel().fromJSONObject(sender)
    this.sentTime = sentTime == null ? null : new Date(sentTime)
    this.replyData = replyData == null ? null : new ChatMessageModel().fromJSONObject(replyData)
    this.textData = textData

    return this
  }

  /// Private ///

  private id: bigint | null
  private sender: UserSimpleModel | null
  private sentTime: Date | null
  private replyData: ChatMessageModel | null
  private textData: string | null

  /// Binary getter/setter ///

  public get Id(): bigint | null {
    return this.id
  }

  public get Sender(): UserSimpleModel | null {
    return this.sender
  }

  public get SentTime(): bigint | null {
    return this.sentTime == null ? null : BigInt(this.sentTime.getTime())
  }

  public get ReplyData(): ChatMessageModel | null {
    return this.replyData
  }

  public get TextData(): string | null {
    return this.textData
  }

  public set Id(value: bigint | null) {
    this.id = value
  }

  public set Sender(value: UserSimpleModel | null) {
    this.sender = value
  }

  public set SentTime(value: bigint | null) {
    this.sentTime = value == null ? null : new Date(Number(value))
  }

  public set ReplyData(value: ChatMessageModel | null) {
    this.replyData = value
  }

  public set TextData(value: string | null) {
    this.textData = value
  }
}