import OpenChatButton from '@/components/chat/open-chat-button'
import DualNavLayout from '@/components/layout/dual-nav'
import Protected from '@/components/protected'
import UserCardSmall from '@/components/user-card-sm'
import { AuthState } from '@/constants/auth'
import { defaultIconUrl } from '@/constants/common'
import { AttendEventFormData, useAttendEventForm } from '@/context/attend-event-form'
import { Retcode } from '@/models/api'
import Api from '@/utils/api'
import Auth from '@/utils/auth'
import DateTime from '@/utils/datetime'
import { getErrorMessage } from '@/utils/error'
import { fetchEventImageUrl } from '@/utils/firebase'
import { faCalendar, faClock } from '@fortawesome/free-regular-svg-icons'
import { faLocationDot, faTicket, faClock as fasClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from '@mantine/carousel'
import {
  Accordion,
  Anchor,
  AspectRatio,
  Avatar,
  Badge,
  Button,
  Grid,
  Group,
  Image,
  Modal,
  NumberInput,
  Paper,
  Pill,
  Select,
  SimpleGrid,
  Space,
  Stack,
  Text,
  TextInput,
  TypographyStylesProvider,
  UnstyledButton,
  rem,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import AttendEventReqModel from '@shared/models/api/request/attend-event'
import UpdateEventStatusReqModel from '@shared/models/api/request/update-event-status'
import GetAttendEventReqModel from '@shared/models/api/request/get-attend'
import GetEventReqModel from '@shared/models/api/request/get-event'
import UpdateAttendStatusReqModel from '@shared/models/api/request/update-attend-status'
import GetAttendStatusRspModel from '@shared/models/api/response/get-attend-status'
import GetAttendeesRspModel from '@shared/models/api/response/get-attendees'
import GetEventRspModel from '@shared/models/api/response/get-event'
import EventModel, { EventStatus } from '@shared/models/event'
import EventAttendAnswerModel from '@shared/models/event/attend-answer'
import EventAttendeeModel, { EventAttendeeStatus } from '@shared/models/event/attendee'
import { AdvancedMarker, Map } from '@vis.gl/react-google-maps'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ParsedNumber, getCountries, getCountryCallingCode, isValidPhoneNumber, parseNumber } from 'libphonenumber-js'

/*
const commentData = [
  {
    comment_id: 'L2F1dGhvcml0waeS9ob3Jpem9uL2NsdXN230ZXJlZF9ldmVudC8yMDI0LTA0LTEzfDUwNzAwMTY1NDIwMzUwMzk2MTc=',
    user: {
      name: 'ET',
      icon: 'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-2.png',
    }, // firebase auth user object
    comment:
      'I use Heroku to host my Node.js application, but MongoDB add-on appears to be too expensive. I consider switching to Digital Ocean VPS to save some cash. I use Heroku to host my Node.js application, but MongoDB add-on appears to be too expensive. I consider switching to Digital Ocean VPS to save some cash. I use Heroku to host my Node.js application, but MongoDB add-on appears to be too expensive. I consider switching to Digital Ocean VPS to save some cash. I use Heroku to host my Node.js application, but MongoDB add-on appears to be too expensive. I consider switching to Digital Ocean VPS to save some cash.',
    time: '2024-04-13 00:00:00',
    rating: Math.floor(5 * Math.random() * 10) / 10,
  },
  {
    comment_id: 'L2F1dGhvcml0eS9ob3J2pem9uL2NsdXN230ZXJlZF9ldmVudC8yMDI0LTA0LTEzfDUwNzAwMTY1NDIwMzUwMzk2MTc=',
    user: {
      name: 'ET',
      icon: 'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-2.png',
    }, // firebase auth user object
    comment:
      'I use Heroku to host my Node.js application, but MongoDB add-on appears to be too expensive. I consider switching to Digital Ocean VPS to save some cash.',
    time: '2024-04-13 00:00:00',
    rating: Math.floor(5 * Math.random() * 10) / 10,
  },
  {
    comment_id: 'L2F1dGhv321cml0eS9ob3Jpem9uL2NsdXN230ZXJlZF9ldmVudC8yMDI0LTA0LTEzfDUwNzAwMTY1NDIwMzUwMzk2MTc=',
    user: {
      name: 'ET',
      icon: 'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-2.png',
    }, // firebase auth user object
    comment:
      'I use Heroku to host my Node.js application, but MongoDB add-on appears to be too expensive. I consider switching to Digital Ocean VPS to save some cash.',
    time: '2024-04-13 00:00:00',
    rating: Math.floor(5 * Math.random() * 10) / 10,
  },
]
*/

const faqData = [
  {
    faq_id: 'L2F1dGhvcml0eS9ob3Jpem9u123qewdsL2NsdXN230ZXJlZF9ldmVudC8yMDI0LTA0LTEzfDUwNzAwMTY1NDIwMzUwMzk2MTc=',
    question: 'How can I reset my password?',
    answer:
      'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.',
  },
  {
    faq_id: 'L2F1dGhvcml0eS9ob3Jpem9uL2NsdXN213qewads5230ZXJlZF9ldmVudC8yMDI0LTA0LTEzfDUwNzAwMTY1NDIwMzUwMzk2MTc=',
    question: 'Can I create more that one account?',
    answer:
      'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.',
  },
  {
    faq_id: 'L2F1dGhvcml0eS9ob3Jpem9uL2NsdXN230ZXJlZF9ldmVudC8yMDI0LTA435ertdLTEzfDUwNzAwMTY1NDIwMzUwMzk2MTc=',
    question: 'How can I subscribe to monthly newsletter?',
    answer:
      'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.',
  },
  {
    faq_id: 'L2F1dGhvcml0eS9ob3Jpem9uL2NsdXN230ZXJlZF9ldmVudC8yMDI0213qweLTA0LTEzfDUwNzAwMTY1NDIwMzUwMzk2MTc=',
    question: 'Do you store credit card information securely?',
    answer:
      'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.',
  },
  {
    faq_id: 'L2F1dGhvcml0eS9ob3Jpem9uL2NsdX213qewN230ZXJlZF9ldmVudC8yMDI0LTA0LTEzfDUwNzAwMTY1NDIwMzUwMzk2MTc=',
    question: 'What payment systems to you work with?',
    answer:
      'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.',
  },
]

export default function EventDetailsPage(): ReactElement {
  const { id } = useParams()
  const { t } = useTranslation('event')
  const navigate = useNavigate()
  const [eventData, setEventData] = useState<EventModel | null>(null)
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [organizerIcon, setOrganizerIcon] = useState<string>()
  const [tags, setTags] = useState<ReactElement[]>([])
  const [opened, { open, close }] = useDisclosure(false)
  const [joinStatus, setJoinStatus] = useState<EventAttendeeStatus>(EventAttendeeStatus.STATUS_NONE)
  const [eventStatus, setEventStatus] = useState<EventStatus>(EventStatus.STATUS_NONE)
  const [eventLocationPos, setEventLocationPos] = useState<google.maps.LatLngLiteral | null>(null)
  const [attendeeList, setAttendeeList] = useState<EventAttendeeModel[]>([])
  const [isOrg, setIsOrg] = useState<boolean>(false)
  const [country, setCountry] = useState('HK') // Default to US
  const [placeId, setPlaceId] = useState<string>('')
  const [locationName, setLocationName] = useState<string>('')

  const countryCodes = getCountries().map((countryCode) => ({
    value: countryCode,
    label: `${countryCode} +${getCountryCallingCode(countryCode)}`,
  }))

  let answerData: EventAttendAnswerModel[] = []

  const INITIAL_CAMERA = {
    center: { lat: 22.302383, lng: 114.2037 },
    zoom: 12,
  }
  const form = useAttendEventForm({
    initialValues: {
      attendeeName: '',
      attendeePhone: '',
      countryCode: 'HK',
    },
    validate: {
      attendeeName: (value) => (value.length <= 0 ? 'Attendee name is required' : null),
      attendeePhone: (value, values) => {
        const fullNumber = `+${getCountryCallingCode(values.countryCode)}${value}`
        return isValidPhoneNumber(fullNumber) ? null : 'Invalid phone number'
      },
    },
  })

  // Fetch event data
  useEffect(() => {
    Auth.getAuthState().then(() => {
      if (!id) return navigate('/')
      Api.sendRequest('/event/getEvent', GetEventRspModel, new GetEventReqModel().setId(id ?? '')).then(async (rsp) => {
        if (rsp.getRetcode() !== Retcode.RET_SUCC) {
          console.warn('retcode not succ')
          return navigate('/')
        }

        const data = rsp.getData()
        if (data == null) {
          console.warn('data == null')
          return navigate('/')
        }

        setTags([])

        const event = data.getEvent()
        setEventData(data.getEvent())
        setEventLocationPos({ lat: event.getLocationLat(), lng: event.getLocationLng() })
        setIsOrg(event.getOrganizer().getId() === Auth.currentUser?.getId())
        setEventStatus(event.getStatus())
        setPlaceId(event.getPlaceId())
        setLocationName(event.getLocationName())
        console.log('event.getPlaceId()', event.getPlaceId());
        
        const _isTimeBetween = new DateTime().isInEventTime(
          new DateTime(event.getBeginTime()),
          new DateTime(event.getEndTime()),
        )

        if (_isTimeBetween && eventStatus !== EventStatus.STATUS_ONGOING) {
          Api.sendRequest(
            '/event/updateEventStatus',
            undefined,
            new UpdateEventStatusReqModel().setEventId(id ?? '').setStatus(EventStatus.STATUS_ONGOING),
          )
            .then((rsp) => {
              if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

              setEventStatus(EventStatus.STATUS_ONGOING)
            })
            .catch((err) => {
              console.error(getErrorMessage(err))
            })
        }

        const isTimeAfter = new DateTime().isAfter(new DateTime(event.getEndTime()))

        if (isTimeAfter && eventStatus !== EventStatus.STATUS_ENDED) {
          Api.sendRequest(
            '/event/updateEventStatus',
            undefined,
            new UpdateEventStatusReqModel().setEventId(id ?? '').setStatus(EventStatus.STATUS_ENDED),
          )
            .then((rsp) => {
              if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

              setEventStatus(EventStatus.STATUS_ENDED)
            })
            .catch((err) => {
              console.error(getErrorMessage(err))
            })
        }

        const tags = JSON.parse(event.getTags())
        tags.forEach((tag: string): void => {
          setTags((prev) => [
            ...prev,
            <Pill
              key={tag}
              color="blue"
              size="md"
              className="mr-2 rounded-md font-sans text-base bg-primary-700 text-white/80"
            >
              {tag}
            </Pill>,
          ])
        })
        if (!Auth.currentUser) return navigate('/login')
        const phoneNumber = parseNumber(Auth.currentUser?.getPhoneNumber())

        // Set the form data by default
        form.setValues({
          attendeeName: Auth.currentUser?.getDisplayName() ?? '',
          attendeePhone: (phoneNumber as ParsedNumber)?.phone ?? '',
        })
      })

      Api.sendRequest('/event/getAttendStatus', GetAttendStatusRspModel, new GetAttendEventReqModel().setId(id)).then(
        (rsp) => {
          if (rsp.getRetcode() !== Retcode.RET_SUCC) {
            console.warn('retcode not succ')
            return navigate('/')
          }

          const data = rsp.getData()
          if (data == null) return console.log('No data')
          setJoinStatus(data.getStatus())
        },
      )

      Api.sendRequest('/event/getAttendList', GetAttendeesRspModel, new GetAttendEventReqModel().setId(id))
        .then((rsp) => {
          if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

          const data = rsp.getData()
          if (data == null) throw new Error('data == null')

          setAttendeeList(data.getAttendeeList())
        })
        .catch((err) => {
          console.error(getErrorMessage(err))
          notifications.show({ title: 'Error', message: getErrorMessage(err), color: 'red', className: 'z-50' })
        })
    })
  }, [id, eventStatus])

  // Fetch details on event data update
  useEffect(() => {
    async function loadImage() {
      if (eventData == null) return console.warn('no event data')

      setOrganizerIcon((await fetchEventImageUrl(eventData.getOrganizer().getThumbnailUrl())) ?? defaultIconUrl)

      const images = JSON.parse(eventData.getImages())
      setImageUrls(await Promise.all(images.map(fetchEventImageUrl)))
    }

    loadImage()
  }, [eventData])

  const INITIAL_CAROUSEL_SETTINGS = {
    slideSize: { base: '100%', xs: '70%', lg: '50%' },
    height: 'auto',
    slideGap: 'xl',
    withIndicators: true,
    initialSlide: 1,
    dragFree: true,
    classNames: {
      root: 'mb-4',
      control: 'dark:bg-gray-200/20 dark:text-white bg-gray-800/20 dark:text-white',
      controls: 'sm:-mx-12 max-sm:hidden',
      indicator: 'bg-gray-200/80',
    },
  }

  const slides = imageUrls.map((url, index) => (
    <Carousel.Slide key={index}>
      <Image
        src={url}
        h={{ base: '300', sm: '400' }}
        className="w-full rounded-md object-fill"
        onClick={() => {
          modals.open({
            title: <Text className='font-sans'>{t('title.gallery')}</Text>,
            radius: 'md',
            size: 'xl',
            centered: true,
            children: (
              <AspectRatio ratio={1}>
                <Image src={url} alt="Gallery" className="w-full rounded-md object-fill" />
              </AspectRatio>
            ),
          })
        }}
      />
    </Carousel.Slide>
  ))

  /*
  const comments = commentData.map((comment) => (
    <Carousel.Slide key={comment.comment_id}>
      <Paper withBorder radius="md" className="p-6 mb-6 h-48">
        <Group>
          <Avatar src={comment.user.icon} alt={comment.user.name} radius="xl" />
          <div>
            <Text fz="sm">{comment.user.name}</Text>
            <Text fz="xs" c="dimmed">
              {comment.time}
            </Text>
          </div>
        </Group>
        <TypographyStylesProvider className="pl-14 pt-2 text-sm font-sans">
          <Text lineClamp={3}>{comment.comment}</Text>
        </TypographyStylesProvider>
        <Button
          onClick={() => {
            modals.open({
              title: comment.user.name,
              radius: 'md',
              centered: true,
              children: <p>{comment.comment}</p>,
            })
          }}
          variant="transparent"
          color="blue"
          className=" absolute bottom-0 right-0 m-8"
        // style={{ float: 'initial' }}
        >
          Read more
        </Button>
      </Paper>
      <Modal opened={opened} onClose={close} title={comment.user.name}>
        {comment.comment}
      </Modal>
    </Carousel.Slide>
  ))
  */

  const faqs = faqData.map((faq) => (
    <Accordion.Item key={faq.faq_id} className="rounded-md mb-4 border-2" value={faq.faq_id}>
      <Accordion.Control>{faq.question}</Accordion.Control>
      <Accordion.Panel>{faq.answer}</Accordion.Panel>
    </Accordion.Item>
  ))

  const beginTime = new DateTime(eventData?.getBeginTime())
  const endTime = new DateTime(eventData?.getEndTime())
  // const iconMarkup = renderToStaticMarkup(<FontAwesomeIcon icon={faLocationDot} size="2xl" color="blue" />)
  // const ratings: [number, number, number, number, number] = [5, 10, 1230, 6330, 3280]

  async function onSubmit(value: AttendEventFormData) {
    try {
      // setDisable(true)
      const { attendeeName, attendeePhone } = value
      const q1 = new EventAttendAnswerModel().fromJSONObject({
        question: 'What is your name?',
        answer: Auth.currentUser?.getDisplayName() ?? '',
      })

      const q2 = new EventAttendAnswerModel().fromJSONObject({
        question: 'What is your phone number?',
        answer: Auth.currentUser?.getPhoneNumber() ?? '',
      })

      answerData.push(q1)
      answerData.push(q2)

      const rsp = await Api.sendCsrfRequest(
        '/event/attendEvent',
        undefined,
        new AttendEventReqModel().fromJSONObject({
          eventId: id ?? '',
          attendeeName,
          phoneNumber: attendeePhone,
          answerData: answerData.map((answer) => answer.toJSONObject()),
        }),
      )
      if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

      setJoinStatus(EventAttendeeStatus.STATUS_PENDING)
      // Show success message
      close()
      notifications.show({
        title: 'Successful',
        message: 'You have successfully submitted, your registration is pending approval',
        color: 'green',
        className: 'z-50',
      })
    } catch (err) {
      // Show error message
      notifications.show({
        title: 'Submit failed',
        message: getErrorMessage(err),
        color: 'red',
        className: 'z-50',
      })
      // setDisable(false)
    }
  }

  // cancelJoin
  async function cancelJoin() {
    try {
      // setDisable(true)
      const rsp = await Api.sendCsrfRequest(
        '/event/revokeAttendEvent',
        undefined,
        new UpdateAttendStatusReqModel().fromJSONObject({
          eventId: id ?? '',
          userId: Auth.currentUser?.getId() ?? '',
        }),
      )
      if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

      setJoinStatus(EventAttendeeStatus.STATUS_NONE)

      // Show success message
      notifications.show({
        title: 'Successful',
        message: 'You have successfully cancelled your registration',
        color: 'green',
        className: 'z-50',
      })
    } catch (err) {
      // Show error message
      notifications.show({
        title: 'Cancel failed',
        message: getErrorMessage(err),
        color: 'red',
        className: 'z-50',
      })
      // setDisable(false)
    }
  }

  function openAttendeeList() {
    // Open attendee list modal
    modals.open({
      title: <Text className="font-medium text-base">Attendee list for</Text>,
      radius: 'md',
      size: 'xl',
      centered: true,
      children: (
        <>
          <Text className="font-sans text-xl sm:text-2xl md:text-3xl font-semibold mb-4">
            {eventData?.getName() ?? 'Event'}
          </Text>
          <Group gap="sm" className="mb-2">
            <div className="flex justify-center items-center">
              <FontAwesomeIcon icon={faCalendar} size="sm" />
            </div>
            <Stack gap="0" align="flex-start">
              <Text className="text-sm font-sans font-medium">{beginTime.toFormatString('D MMMM YYYY')}</Text>
            </Stack>
            <div className="flex justify-center items-center">
              <FontAwesomeIcon icon={faLocationDot} size="sm" />
            </div>
            <Stack gap="0" align="flex-start">
              <Text className="text-sm font-sans font-medium">{eventData?.getLocationName() ?? 'Unknown'}</Text>
            </Stack>
          </Group>
          <SimpleGrid cols={3} spacing="md" className="mt-4">
            {attendeeList?.map((user) => (
              <UserCardSmall
                key={user.getId()}
                name={user.getUser().getDisplayName()}
                role={user.getId() === eventData?.getOrganizer().getId() ? 'Event organizer' : 'Attendee'}
              />
            ))}
          </SimpleGrid>
          {/* <AttendeesTable attendees={users}></AttendeesTable> */}
        </>
      ),
    })
  }

  return (
    <Protected state={AuthState.LOGIN} redirect className="h-full">
      <DualNavLayout className="container max-w-none py-6 mx-auto px-6 sm:px-14 md:px-20 lg:px-36 xl:px-80">
        <Grid gutter="xs" className="mb-6">
          <Grid.Col>
            <Group>
              <Text className="font-sans text-lg sm:text-2xl md:text-3xl font-bold">{eventData?.getName()}</Text>
              <Badge
                className="pointer-events-none flex rounded-md h-10 sm:h-14 md:h-16"
                variant="gradient"
                gradient={{ from: 'yellow', to: 'red' }}
                size="sm"
              >
                <Stack gap={0} align="center">
                  <p className="font-sans text-sm sm:text-lg md:text-xl">{beginTime.toFormatString('D')}</p>
                  <p className="font-sans text-xs sm:text-base md:text-lg">{beginTime.toFormatString('MMM')}</p>
                </Stack>
              </Badge>
            </Group>
          </Grid.Col>
          <Grid.Col>
            {/* <Group gap="4">
              <Text className="font-sans">
                Start at {beginTime.toFormatString('HH')}:{beginTime.toFormatString('mm')}
              </Text>
            </Group> */}
          </Grid.Col>
        </Grid>

        <Carousel {...INITIAL_CAROUSEL_SETTINGS} className="w-full h-auto">
          {slides}
        </Carousel>

        <Grid>
          <Grid.Col span={{ base: 12, xs: 8 }}>
            <Text className="font-sans text-xl sm:text-2xl md:text-3xl font-bold">{t('description.label')}</Text>
            <>
              {eventData && (
                <TypographyStylesProvider className="mb-8">
                  <div dangerouslySetInnerHTML={{ __html: eventData?.getDescription() }}></div>
                </TypographyStylesProvider>
              )}
            </>

            <Text className="font-sans text-xl sm:text-2xl md:text-3xl font-bold mb-4">{t('tags.label')}</Text>
            <div className="mb-6">{tags}</div>

            {/* Attendees List*/}
            {attendeeList.length > 0 && (
              <Paper shadow="md" radius="lg" p="lg" className="mb-6 flex overflow-auto">
                <Group align="center" className="gap-16">
                  {attendeeList.slice(0, 3).map((user) => (
                    <UserCardSmall
                      key={user.getId()}
                      name={user.getUser().getDisplayName()}
                      role={user.getId() === eventData?.getOrganizer().getId() ? t('ogranizer') : t('attendee')}
                    />
                  ))}
                  {attendeeList.length > 3 && (
                    <div className="flex flex-col items-center cursor-pointer" onClick={openAttendeeList}>
                      <Avatar className="text-xl">+{attendeeList.length - 3}</Avatar>
                      <Text size="sm" className="font-medium text-base">
                        More
                      </Text>
                    </div>
                  )}
                </Group>
              </Paper>
            )}

            <Group gap="xs" className="mb-2">
              <div className="flex justify-center items-center h-8 w-8 rounded-md dark:bg-white/10">
                <FontAwesomeIcon icon={fasClock} size="lg" />
              </div>
              <Stack gap="0" align="flex-start">
                <Text className="text-sm md:text-base font-sans mb-0">{beginTime.toFormatString('D MMMM YYYY')}</Text>
                <Text className="text-sm md:text-base font-sans">
                  {beginTime.toFormatString('dddd, HH:mm A')} to {endTime.toFormatString('HH:mm A')} GMT+8
                </Text>
              </Stack>
            </Group>
            <Group gap="xs" className="mb-2">
              <div className="flex justify-center items-center h-8 w-8 rounded-md dark:bg-white/10">
                <FontAwesomeIcon icon={faLocationDot} size="lg" />
              </div>
              <Stack gap="0" align="flex-start">
                <Text className="text-sm md:text-base font-sans">{eventData?.getLocationName() ?? 'Unknown'}</Text>
              </Stack>
            </Group>
            <Group gap="xs" className="mb-6">
              <div className="flex justify-center items-center h-8 w-8 rounded-md dark:bg-white/10">
                <FontAwesomeIcon icon={faTicket} size="lg" />
              </div>
              <Stack gap="0" align="flex-start">
                <Text className="text-sm md:text-base font-sans">${eventData?.getPrice()}</Text>
                <Text className="text-sm md:text-base font-sans">{t('preHead')}</Text>
              </Stack>
            </Group>

            {/* About Host */}
            <Text className="font-sans text-xl sm:text-2xl md:text-3xl font-bold mb-4">{t('host.label')}</Text>
            <Group gap="xs" className="mb-6">
              <UnstyledButton className="p-2">
                <Group gap="xs">
                  <Avatar key={organizerIcon} src={organizerIcon} radius="md" className="h-14 w-14" />
                  <Text className="text-base font-semibold font-sans">
                    {eventData?.getOrganizer().getDisplayName()}
                  </Text>
                </Group>
              </UnstyledButton>
              <OpenChatButton
                targetType="private"
                targetUserId={eventData?.getOrganizer().getId() ?? null}
                text={t('host.message')}
                className="font-sans bg-blue-400/30 hover:bg-blue-500/30"
              />
            </Group>

            {/* <EventRating ratings={ratings} /> */}

            {/* {comments.length > 0 && (
              <Carousel
                slideSize="100%"
                slideGap="md"
                align="start"
                dragFree
                classNames={{
                  root: 'mb-4',
                  control: 'dark:bg-gray-200/20 dark:text-white bg-gray-800/20 dark:text-white',
                  controls: 'sm:-mx-12 max-sm:hidden',
                  indicator: 'bg-gray-200/80',
                }}
              >
                {comments}
              </Carousel>
            )} */}

            {/* {faqs.length > 0 ?? (
              <div>
                <Text className="font-sans text-xl sm:text-2xl md:text-3xl font-bold mb-4">FAQ</Text>
                <Accordion variant="separated">{faqs}</Accordion>
              </div>
            )} */}
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 4 }} className="max-sm:hidden">
            <div className="sticky top-10">
              <Paper shadow="sm" radius="lg" p="lg" className="min-w-80">
                <UnstyledButton>
                  <Group gap="md">
                    <Avatar key={organizerIcon} src={organizerIcon} radius="md" className="h-14 w-14" />
                    <Text className="text-sm md:text-base font-semibold font-sans">
                      {eventData?.getOrganizer().getDisplayName()}
                    </Text>
                  </Group>
                </UnstyledButton>
              </Paper>
              <Paper shadow="sm" radius="lg" p="lg" className="mt-4 min-w-80">
                <TypographyStylesProvider>
                  <Group gap="xs" className="mb-2 flex-nowrap">
                    <div className="flex justify-center items-center h-8 w-8">
                      <FontAwesomeIcon icon={faClock} size="lg" />
                    </div>
                    <Stack gap="0" align="flex-start">
                      <Text className="text-sm md:text-base font-sans mb-0">
                        {beginTime.toFormatString('D MMMM, YYYY')}
                      </Text>
                      <Text className="text-sm md:text-base font-sans">
                        {beginTime.toFormatString('dddd, HH:mm A')} to {endTime.toFormatString('HH:mm A')} GMT+8
                      </Text>
                    </Stack>
                  </Group>
                  <Group gap="xs" className="mb-2 flex-nowrap">
                    <div className="flex justify-center items-center h-8 w-8">
                      <FontAwesomeIcon icon={faLocationDot} size="lg" />
                    </div>
                    <Stack gap="0" align="flex-start">
                      <Text className="text-sm md:text-base font-sans">
                        {eventData?.getLocationName() ?? 'Unknown'}
                      </Text>
                    </Stack>
                  </Group>

                  <AddToCalendarButton
                    name={eventData?.getName() ?? 'Unknown'}
                    options={['Apple', 'Google', 'Outlook.com', 'Yahoo']}
                    location={eventData?.getLocationName() ?? 'Unknown'}
                    startDate={beginTime.toDateString()}
                    endDate={endTime.toDateString()}
                    startTime={beginTime.toTimeString()}
                    endTime={endTime.toTimeString()}
                    timeZone="Asia/Hong_Kong"
                    buttonStyle="date"
                    lightMode="dark"
                  ></AddToCalendarButton>
                  <Anchor href={`https://www.google.com/maps/search/?api=1&query=${locationName}&query_place_id=${placeId}/`} target="_blank">
                    <AspectRatio ratio={16 / 9} className="mt-4">
                      <Map
                        id="create"
                        center={eventLocationPos ?? INITIAL_CAMERA.center}
                        zoom={15}
                        gestureHandling={'greedy'}
                        mapId={'63208d5c9c3fd3ba'}
                        controlled={false}
                        fullscreenControl={false}
                        mapTypeControl={false}
                        streetViewControl={false}
                        scaleControl={false}
                        zoomControl={false}
                      >
                        {eventLocationPos && <AdvancedMarker position={eventLocationPos} />}
                      </Map>
                    </AspectRatio>
                  </Anchor>
                </TypographyStylesProvider>
              </Paper>
            </div>
          </Grid.Col>
        </Grid>

        <Space h="60px" />
        <div className="z-10 sticky bottom-1 border-t-[1px] mx-auto px-3 md:px-10 py-2 border-solid border-gray-400 bg-blue-400/90 rounded-md">
          <Grid grow justify="center" align="center">
            <Grid.Col span={4}>
              <Stack gap="0" align="flex-start">
                <Text className="text-sm font-sans">{beginTime.toFormatString('D MMMM YYYY')}</Text>
                <Text className="text-sm font-sans">
                  {beginTime.toFormatString('dddd, HH:mm')} - {endTime.toFormatString('HH:mm')}
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={8}>
              {!isOrg && (
                <Group gap="xs" align="center" className="items-center justify-end">
                  {eventStatus === EventStatus.STATUS_ONGOING ? (
                    <>
                      <Text className="font-sans text-sm md:text-base">The event is ongoing</Text>
                      <OpenChatButton
                        targetType="private"
                        targetUserId={eventData?.getOrganizer().getId() ?? null}
                        variant="filled"
                        className="bg-green-500 hover:bg-green-600"
                        text="Contact host"
                      />
                    </>
                  ) : eventStatus === EventStatus.STATUS_ENDED ? (
                    <>
                      <Text className="font-sans text-sm md:text-base">The event has ended</Text>
                      <OpenChatButton
                        targetType="private"
                        targetUserId={eventData?.getOrganizer().getId() ?? null}
                        variant="filled"
                        className="bg-green-500 hover:bg-green-600"
                        text="Contact host"
                      />
                    </>
                  ) : joinStatus === EventAttendeeStatus.STATUS_PENDING ? (
                    <>
                      <Text className="font-sans text-sm md:text-base">Waiting for the host to approve</Text>
                      <Button
                        variant="filled"
                        className={'bg-red-600 hover:bg-red-600/70'}
                        onClick={() =>
                          modals.openConfirmModal({
                            title: <Text className="font-sans font-semibold text-xl">Cancel registration</Text>,
                            children: 'Are you sure you want to cancel your registration?',
                            confirmProps: { className: 'bg-red-600 hover:bg-red-600/70' },
                            cancelProps: { color: 'gray', bg: 'gray-500' },
                            labels: { confirm: 'Yes', cancel: 'No' },
                            centered: true,
                            onConfirm: cancelJoin,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </>
                  ) : joinStatus === EventAttendeeStatus.STATUS_JOINED ? (
                    <>
                      <Text className="font-sans text-sm md:text-base">You have joined</Text>
                      <Button variant="filled" color="red" className={'bg-red-500/70'} onClick={cancelJoin}>
                        Cancel
                      </Button>
                    </>
                  ) : joinStatus === EventAttendeeStatus.STATUS_NONE && eventStatus === EventStatus.STATUS_JOIN_OPEN ? (
                    <>
                      <Text className="font-sans text-sm md:text-base">
                        {(eventData?.getPrice() ?? 0) <= 0 ? 'Free' : `$${(eventData?.getPrice() ?? 0).toFixed(1)}`}
                      </Text>
                      <Button
                        variant="filled"
                        color="green"
                        className={'bg-green-500/70 hover:bg-green-600'}
                        onClick={open}
                      >
                        Attend
                      </Button>
                    </>
                  ) : null}
                </Group>
              )}
            </Grid.Col>
          </Grid>
          {/* </Group> */}
          <Modal
            opened={opened}
            onClose={close}
            title={<Text className="font-sans text-xl font-semibold">Complete your registration</Text>}
            centered
            radius="md"
          >
            <form onSubmit={form.onSubmit((v) => onSubmit(v) as unknown)}>
              <TextInput
                data-autofocus
                label="What is your name?"
                placeholder="Your Name..."
                classNames={{ label: 'font-sans mb-2', input: 'font-sans', wrapper: 'mb-3' }}
                {...form.getInputProps('attendeeName')}
              />
              <>
                <NumberInput
                  className="mb-3"
                  classNames={{ label: 'font-sans mb-2', input: 'font-sans' }}
                  label={
                    <>
                      {/* {t('price.label')} */}
                      Phone Number...
                    </>
                  }
                  required
                  placeholder="Phone Number..."
                  hideControls
                  rightSection={
                    <Select
                      data={countryCodes}
                      rightSectionWidth={28}
                      value={country}
                      onChange={(value) => setCountry(value!)}
                      searchable={true}
                      nothingFoundMessage="No country found"
                      styles={{
                        input: {
                          fontWeight: 500,
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          width: rem(110),
                          marginRight: rem(-2),
                        },
                      }}
                    />
                  }
                  rightSectionWidth={110}
                  {...form.getInputProps('attendeePhone')}
                />
              </>
              <Button
                fullWidth
                color="blue"
                className={'bg-green-600 hover:bg-green-700 font-sans text-base font-normal'}
                type="submit"
                mt="md"
              >
                Attend
              </Button>
            </form>
          </Modal>
        </div>
      </DualNavLayout>
    </Protected>
  )
}
