import EventModel, { EventJSONModel } from '@shared/models/event'
import ModelBase from '@shared/models/base'

export interface GetEventRspJSONModel {
  event: EventJSONModel
}

export default class GetEventRspModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.event = new EventModel()
  }

  public getEvent(): EventModel {
    return this.event
  }

  public setEvent(event: EventModel): this {
    this.event = event
    return this
  }

  public toJSONObject(): GetEventRspJSONModel {
    const { event } = this

    return {
      event: event.toJSONObject()
    }
  }

  public fromJSONObject(json: GetEventRspJSONModel): this {
    this.validate(json, 'event', 'object')

    const { event } = json

    this.event.fromJSONObject(event)

    return this
  }

  /// Private ///

  private event: EventModel
}