import DateTime from '@/utils/datetime'
import { fetchEventImageUrl } from '@/utils/firebase'
import { faClock, faEye } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Card, Grid, Group, Image, SimpleGrid, Skeleton, Stack, StyleProp, Text, rem } from '@mantine/core'
import { modals } from '@mantine/modals'
import EventModel from '@shared/models/event'
import { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AttendeesTable from '../attendees-table'
import Api from '@/utils/api'
import { Retcode } from '@shared/models/api'
import GetAttendEventReqModel from '@shared/models/api/request/get-attend'
import GetAttendeesRspModel from '@shared/models/api/response/get-attendees'
import { getErrorMessage } from '@/utils/error'
import { notifications } from '@mantine/notifications'
import { faCheck, faCheckDouble, faTrash, faX } from '@fortawesome/free-solid-svg-icons'

export type EventCardGridType = 'self' | 'joined'

export type EventCardGridProps = {
  cols?: StyleProp<number>
  type: EventCardGridType
  eventList: EventModel[]
}

function GridItem({ type, data }: Readonly<{ type: EventCardGridType; data: EventModel }>) {
  const beginTime = new DateTime(data.getBeginTime())
  const endTime = new DateTime(data.getEndTime())
  const [thumbUrl, setThumbUrl] = useState<string>('')

  useEffect(() => {
    const fetchThumbUrl = async () => {
      const path = JSON.parse(data.getImages())[0]
      const url = await fetchEventImageUrl(path)
      if (!url) return console.warn('No image found for event', data.getId())

      setThumbUrl(url)
    }

    const fetchAttendees = async () => {
      // Fetch attendees
      Api.sendRequest('/event/getAttendList', GetAttendeesRspModel, new GetAttendEventReqModel().setId(data.getId()))
        .then((rsp) => {
          if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

          const data = rsp.getData()
          if (data == null) throw new Error('data == null')
        })
        .catch((err) => {
          console.error(getErrorMessage(err))
          notifications.show({ title: 'Error', message: getErrorMessage(err), color: 'red' })
        })
    }

    fetchAttendees()
    fetchThumbUrl()
  }, [data])

  function openAttendeeList() {
    // Open attendee list modal
    modals.open({
      title: <Text className="font-medium text-base">Attendee list for</Text>,
      radius: 'md',
      size: 'xl',
      centered: true,
      children: (
        <>
          <Text className="font-sans text-lg font-semibold mb-2">{data.getName()}</Text>
          <Group gap={4} wrap="nowrap" className="mb-6">
            <ActionIcon size="lg" variant="subtle" color="gray">
              <FontAwesomeIcon icon={faEye} size="lg" />
            </ActionIcon>
            <Text c="gray" className="font-sans">
              View
            </Text>
            <ActionIcon size="lg" variant="subtle" color="blue">
              <FontAwesomeIcon icon={faCheck} size="lg" />
            </ActionIcon>
            <Text c="blue">Accept</Text>
            <ActionIcon size="lg" variant="subtle" color="rgba(255, 105, 105, 1)">
              <FontAwesomeIcon icon={faX} size="lg" />
            </ActionIcon>
            <Text c="rgba(255, 105, 105, 1)">Reject</Text>
            <ActionIcon size="lg" variant="subtle" color="rgba(255, 25, 25, 1)">
              <FontAwesomeIcon icon={faTrash} size="lg" />
            </ActionIcon>
            <Text c="rgba(255, 25, 25, 1)">Revoke approval</Text>
            <ActionIcon size="lg" variant="subtle" color="green">
              <FontAwesomeIcon icon={faCheckDouble} size="lg" />
            </ActionIcon>
            <Text c="green">Mark as present</Text>
          </Group>
          <AttendeesTable eventId={data.getId()}></AttendeesTable>
        </>
      ),
    })
  }

  return (
    <Card shadow="md" radius="md" className="block" padding="lg">
      <Grid align="center" justify="center">
        <Grid.Col span={3}>
          <Link to={`/event/${data.getId()}`}>
            <Image src={thumbUrl} className="max-h-16 max-w-16 rounded-md object-contain" />
          </Link>
        </Grid.Col>
        <Grid.Col span={9}>
          <Stack gap="0">
            <Link to={`/event/${data.getId()}`}>
              <Text className="font-sans text-lg sm:text-xl font-semibold mb-1">{data.getName()}</Text>
            </Link>
            <Group gap="xs" className="mb-2">
              <div className="flex justify-center items-center">
                <FontAwesomeIcon icon={faClock} size="sm" />
              </div>
              <Stack gap="0" align="flex-start">
                <Text className="text-sm font-sans font-medium">
                  {beginTime.toFormatString('MMM DD hh:mm A')} - {endTime.toFormatString('MMM DD hh:mm A')}
                </Text>
              </Stack>
            </Group>
            <Group>
              {type === 'self' && (
                <>
                  <Link to={`/event/${data.getId()}/edit`} className="text-sm md:text-base font-sans underline">
                    Edit
                  </Link>
                  <Text className="text-sm md:text-base font-sans underline cursor-pointer" onClick={openAttendeeList}>
                    Manage
                  </Text>
                </>
              )}
              <Link to={`/event/${data.getId()}`} className="max-w-fit">
                <Text className="text-sm md:text-base font-sans underline">View</Text>
              </Link>
            </Group>
          </Stack>
        </Grid.Col>
      </Grid>
    </Card>
  )
}

export default function EventCardGrid({ cols, type, eventList }: Readonly<EventCardGridProps>): ReactElement {
  if (eventList.length === 0) {
    return <Skeleton animate height={rem(150)} radius={'md'} />
  }

  return (
    <SimpleGrid cols={cols}>
      {eventList.map((e) => (
        <GridItem key={e.getId()} type={type} data={e} />
      ))}
    </SimpleGrid>
  )
}
