import BinaryModelBase, { BinaryValueType } from '@shared/models/binary'

export interface DelChatReqJSONModel {
  roomId: string
  msgId: string
}

export default class DelChatReqModel extends BinaryModelBase<DelChatReqModel> {
  /// Public ///

  public constructor() {
    super()

    this.roomId = 0n
    this.msgId = 0n

    this.addField('RoomId', { type: BinaryValueType.UINT64 })
    this.addField('MsgId', { type: BinaryValueType.UINT64 })
  }

  public getRoomId(): bigint {
    return this.roomId
  }

  public getMsgId(): bigint {
    return this.msgId
  }

  public setRoomId(roomId: bigint): this {
    this.roomId = roomId
    return this
  }

  public setMsgId(msgId: bigint): this {
    this.msgId = msgId
    return this
  }

  public toJSONObject(): DelChatReqJSONModel {
    const { roomId, msgId } = this

    return {
      roomId: roomId.toString(),
      msgId: msgId.toString()
    }
  }

  public fromJSONObject(json: DelChatReqJSONModel): this {
    this.validate(json, 'roomId', 'string')
    this.validate(json, 'msgId', 'string')

    const { roomId, msgId } = json

    this.roomId = BigInt(roomId || '0')
    this.msgId = BigInt(msgId || '0')

    return this
  }

  /// Private ///

  private roomId: bigint
  private msgId: bigint

  /// Binary getter/setter ///

  public get RoomId(): bigint {
    return this.roomId
  }

  public get MsgId(): bigint {
    return this.msgId
  }

  public set RoomId(value: bigint) {
    this.roomId = value
  }

  public set MsgId(value: bigint) {
    this.msgId = value
  }
}