import ErrorPage from '@/app/error'
import HKMLPage from '@/app/profile/hkml'
import EventCreatePage from '@/app/event/create'
import EventDetails from '@/app/event/details'
import EventListPage from '@/app/event/list'
import EventPublishPage from '@/app/event/publish'
import HomePage from '@/app/home'
import LoginPage from '@/app/login'
import ProfilePage from '@/app/profile/index'
import RegisterPage from '@/app/register'
import ResetPage from '@/app/reset'
import SettingsPage from '@/app/settings'
import SplashPage from '@/app/splash'
import VerifyPage from '@/app/verify'
import { firebaseAuth } from '@/configs/firebase'
import { AuthState } from '@/constants/auth'
import { AuthContext, IAuthContext } from '@/context/auth'
import Auth from '@/utils/auth'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import CalendarPage from './calendar'
import ChatRoomPage from './chat/room'
import { DebugPage } from './debug'
import CategorySearchPage from './event/category'
import EventEditPage from './event/edit'
import ProfileSetupPage from './profile/setup'

let curState = AuthState.CHECKING // Current auth state of context
let updatingState = false // Firebase calling onAuthStateChanged too fast, need it to slow down
let updateLoop: NodeJS.Timeout | null = null // Update auth state timer

function updateAuthState(setAuthState: (newState: AuthState) => void, isUseCache: boolean): void {
  isUseCache = isUseCache || updatingState
  if (!isUseCache) updatingState = true

  Auth.activate()
  Auth.getAuthState(isUseCache)
    .then(setAuthState)
    .catch(console.error)
    .finally(() => {
      if (!isUseCache) updatingState = false

      if (updateLoop == null) updateLoop = setInterval(updateAuthState.bind(null, setAuthState, true), 1e3)
    })
}

export default function App(): ReactElement {
  const [authState, setAuthState] = useState(curState)

  useEffect(() => {
    function changeAuthState(newState: AuthState): void {
      if (curState === newState) return

      setAuthState(newState)
      curState = newState

      console.debug('[app]', 'auth state change:', AuthState[newState] ?? newState)
    }

    firebaseAuth.onAuthStateChanged(updateAuthState.bind(null, changeAuthState, false))
  }, [])

  return (
    <BrowserRouter>
      <div className="app h-full overflow-hidden">
        <AuthContext.Provider
          value={useMemo<IAuthContext>(() => ({
            authState,
            setAuthState
          }), [authState])}
        >
          <Routes>
            {/* Splash & error page */}
            <Route path='/splash' element={<SplashPage />} />
            <Route path='/error' element={<ErrorPage />} />

            {/* Auth page */}
            <Route path='/login' element={<LoginPage />} />
            <Route path='/login/:lang' element={<LoginPage />} />
            <Route path='/register' element={<RegisterPage />} />
            <Route path='/reset' element={<ResetPage />} />
            <Route path='/verify' element={<VerifyPage />} />

            {/* Home page */}
            <Route path='/' element={<HomePage />} />
            <Route path='/calendar' element={<CalendarPage />} />

            {/* User management page */}
            <Route path='/profile' element={<ProfilePage />} />
            <Route path='/profile/hkml' element={<HKMLPage />} />
            <Route path='/profile/setup' element={<ProfileSetupPage />} />
            <Route path='/settings' element={<SettingsPage />} />

            {/* Event management page */}
            <Route path='/event/create' element={<EventCreatePage />} />
            <Route path='/event/publish' element={<EventPublishPage />} />
            <Route path='/event/list' element={<EventListPage />} />
            <Route path='/event/:id/edit' element={<EventEditPage />} />
            <Route path='/event/:id/publish' element={<EventPublishPage />} />
            <Route path='/event/:id' element={<EventDetails />} />
            <Route path='/category/:category' element={<CategorySearchPage />} />

            {/* Chat */}
            <Route path='/chat/:id' element={<ChatRoomPage />} />
            <Route path='/chat' element={<ChatRoomPage />} />

            {/* Debug */}
            <Route path='/debug' element={<DebugPage />} />

            {/* Redirect 404 */}
            <Route path='*' element={<Navigate replace to='/' />} />
          </Routes>
        </AuthContext.Provider>
      </div>
    </BrowserRouter >
  )
}