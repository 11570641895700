import BinaryModelBase from '../models/binary'

enum EndianType {
  LittleEndian,
  BigEndian
}

function readStringToNull(buf: Buffer, maxLen: number = Infinity): string {
  return buf.subarray(0, Math.min(maxLen, buf.indexOf(0))).toString('utf8')
}

export class EndianBinaryReader {
  public readonly buffer: Buffer

  public endian: EndianType
  public position: number

  public constructor(buf: Buffer, endian: EndianType = EndianType.BigEndian) {
    this.endian = endian
    this.buffer = buf
    this.position = 0
  }

  public ReadInt16(): number {
    const { buffer, endian, position } = this

    if (position + 2 > buffer.length) throw new Error('Out of range')
    this.position += 2

    if (endian == EndianType.BigEndian) return buffer.readInt16BE(position)
    return buffer.readInt16LE(position)
  }

  public ReadInt32(): number {
    const { buffer, endian, position } = this

    if (position + 4 > buffer.length) throw new Error('Out of range')
    this.position += 4

    if (endian == EndianType.BigEndian) return buffer.readInt32BE(position)
    return buffer.readInt32LE(position)
  }

  public ReadInt64(): bigint {
    const { buffer, endian, position } = this

    if (position + 8 > buffer.length) throw new Error('Out of range')
    this.position += 8

    if (endian == EndianType.BigEndian) return buffer.readBigInt64BE(position)
    return buffer.readBigInt64LE(position)
  }

  public ReadUInt16(): number {
    const { buffer, endian, position } = this

    if (position + 2 > buffer.length) throw new Error('Out of range')
    this.position += 2

    if (endian == EndianType.BigEndian) return buffer.readUInt16BE(position)
    return buffer.readUInt16LE(position)
  }

  public ReadUInt32(): number {
    const { buffer, endian, position } = this

    if (position + 4 > buffer.length) throw new Error('Out of range')
    this.position += 4

    if (endian == EndianType.BigEndian) return buffer.readUInt32BE(position)
    return buffer.readUInt32LE(position)
  }

  public ReadUInt64(): bigint {
    const { buffer, endian, position } = this

    if (position + 8 > buffer.length) throw new Error('Out of range')
    this.position += 8

    if (endian == EndianType.BigEndian) return buffer.readBigUInt64BE(position)
    return buffer.readBigUInt64LE(position)
  }

  public ReadSingle(): number {
    const { buffer, endian, position } = this

    if (position + 4 > buffer.length) throw new Error('Out of range')
    this.position += 4

    if (endian == EndianType.BigEndian) return buffer.readFloatBE(position)
    return buffer.readFloatLE(position)
  }

  public ReadDouble(): number {
    const { buffer, endian, position } = this

    if (position + 8 > buffer.length) throw new Error('Out of range')
    this.position += 8

    if (endian == EndianType.BigEndian) return buffer.readDoubleBE(position)
    return buffer.readDoubleLE(position)
  }

  public ReadByte(): number {
    const { buffer, position } = this

    if (position + 1 > buffer.length) throw new Error('Out of range')
    this.position += 1

    return buffer.readUInt8(position)
  }

  public ReadBoolean(): boolean {
    return this.ReadByte() !== 0
  }

  public ReadBytes(size: number): Buffer {
    const { buffer, position } = this

    if (position + size > buffer.length) throw new Error('Out of range')
    this.position += size

    return buffer.subarray(position, position + size)
  }

  public ReadAlignedString(): string {
    const { buffer, position } = this

    const len = this.ReadInt32()
    if (len > 0 && len <= buffer.length - position) {
      const strBuf = this.ReadBytes(len)
      this.AlignStream()
      return strBuf.toString('utf8')
    }

    return ''
  }

  public ReadStringToNull(maxLen: number = Infinity): string {
    const { buffer, position } = this

    const str = readStringToNull(buffer.subarray(position), maxLen)
    const size = Math.min(buffer.length - position, maxLen, str.length + 1)

    this.position += size

    return str
  }

  public AlignStream(alignment: number = 4): void {
    const { position } = this

    const mod = position % alignment
    if (mod != 0) this.position += alignment - mod
  }
}

export class EndianBinaryWriter {
  public buffer: Buffer

  public endian: EndianType
  public position: number

  public constructor(endian: EndianType = EndianType.BigEndian) {
    this.endian = endian
    this.buffer = Buffer.alloc(0)
    this.position = 0
  }

  public WriteInt16(value: number): this {
    const data = Buffer.alloc(2)

    if (this.endian == EndianType.BigEndian) {
      data.writeInt16BE(value)
    } else {
      data.writeInt16LE(value)
    }

    return this.WriteBytes(data)
  }

  public WriteInt32(value: number): this {
    const data = Buffer.alloc(4)

    if (this.endian == EndianType.BigEndian) {
      data.writeInt32BE(value)
    } else {
      data.writeInt32LE(value)
    }

    return this.WriteBytes(data)
  }

  public WriteInt64(value: bigint): this {
    const data = Buffer.alloc(8)

    if (this.endian == EndianType.BigEndian) {
      data.writeBigInt64BE(value)
    } else {
      data.writeBigInt64LE(value)
    }

    return this.WriteBytes(data)
  }

  public WriteUInt16(value: number): this {
    const data = Buffer.alloc(2)

    if (this.endian == EndianType.BigEndian) {
      data.writeUInt16BE(value)
    } else {
      data.writeUInt16LE(value)
    }

    return this.WriteBytes(data)
  }

  public WriteUInt32(value: number): this {
    const data = Buffer.alloc(4)

    if (this.endian == EndianType.BigEndian) {
      data.writeUInt32BE(value)
    } else {
      data.writeUInt32LE(value)
    }

    return this.WriteBytes(data)
  }

  public WriteUInt64(value: bigint): this {
    const data = Buffer.alloc(8)

    if (this.endian == EndianType.BigEndian) {
      data.writeBigUInt64BE(value)
    } else {
      data.writeBigUInt64LE(value)
    }

    return this.WriteBytes(data)
  }

  public WriteSingle(value: number): this {
    const data = Buffer.alloc(4)

    if (this.endian == EndianType.BigEndian) {
      data.writeFloatBE(value)
    } else {
      data.writeFloatLE(value)
    }

    return this.WriteBytes(data)
  }

  public WriteDouble(value: number): this {
    const data = Buffer.alloc(8)

    if (this.endian == EndianType.BigEndian) {
      data.writeDoubleBE(value)
    } else {
      data.writeDoubleLE(value)
    }

    return this.WriteBytes(data)
  }

  public WriteByte(value: number): this {
    const data = Buffer.alloc(1)

    data.writeUInt8(value, 0)

    return this.WriteBytes(data)
  }

  public WriteBoolean(value: boolean): this {
    return this.WriteByte(value ? 1 : 0)
  }

  public WriteBytes(value: Buffer): this {
    this.ensureCapacity(value.length)
    value.copy(this.buffer, this.position)
    this.position += value.length

    return this
  }

  public WriteAlignedString(value: string, alignment: number = 4): this {
    const strBuf = Buffer.from(value, 'utf8')

    this.WriteInt32(strBuf.length)
    this.WriteBytes(strBuf)
    this.AlignStream(alignment)

    return this
  }

  public AlignStream(alignment: number = 4): this {
    const { position } = this

    const mod = position % alignment
    if (mod != 0) this.WriteBytes(Buffer.alloc(alignment - mod))

    return this
  }

  private ensureCapacity(additionalBytes: number): void {
    const { buffer, position } = this

    const requiredCapacity = position + additionalBytes
    if (requiredCapacity <= buffer.length) return

    const newBuffer = Buffer.alloc(Math.max(buffer.length * 2, requiredCapacity))
    buffer.copy(newBuffer)

    this.buffer = newBuffer
  }
}

export type KVDecoder<T> = (reader: EndianBinaryReader) => T

export class BinDecoder {
  public static ReadByteArrayEncode(reader: EndianBinaryReader): number[] {
    const bytes: number[] = []

    while (reader.position < reader.buffer.length) {
      const byte = reader.ReadByte()
      bytes.push(byte)
      if ((byte & 0x80) === 0) break
    }

    return bytes
  }

  public static ReadByte(reader: EndianBinaryReader): number {
    return reader.ReadByte()
  }

  public static ReadBool(reader: EndianBinaryReader): boolean {
    return reader.ReadByte() !== 0
  }

  public static ReadUInt16(reader: EndianBinaryReader): number {
    return (BinDecoder.ReadUInt32(reader) & 0xFFFF) >>> 0
  }

  public static ReadUInt32(reader: EndianBinaryReader): number {
    return Number(BinDecoder.ReadUInt64(reader) & 0xFFFFFFFFn) >>> 0
  }

  public static ReadUInt64(reader: EndianBinaryReader): bigint {
    const bytes = BinDecoder.ReadByteArrayEncode(reader)
    if (bytes.length <= 0) return 0n

    let val = 0n
    for (let i = 0; i < bytes.length; i++) {
      val |= BigInt(bytes[i] & 0x7F) << BigInt((i * 7) & 0x3F)
    }

    return val
  }

  public static ReadInt16(reader: EndianBinaryReader): number {
    const value = BinDecoder.ReadUInt16(reader)
    return value >> 1 ^ -(value & 1)
  }

  public static ReadInt32(reader: EndianBinaryReader): number {
    const value = BinDecoder.ReadUInt32(reader)
    return value >> 1 ^ -(value & 1)
  }

  public static ReadFixedUInt16(reader: EndianBinaryReader): number {
    return reader.ReadUInt16()
  }

  public static ReadFixedUInt32(reader: EndianBinaryReader): number {
    return reader.ReadUInt32()
  }

  public static ReadFixedUInt64(reader: EndianBinaryReader): bigint {
    return reader.ReadUInt64()
  }

  public static ReadFixedInt16(reader: EndianBinaryReader): number {
    return reader.ReadInt16()
  }

  public static ReadFixedInt32(reader: EndianBinaryReader): number {
    return reader.ReadInt32()
  }

  public static ReadFixedInt64(reader: EndianBinaryReader): bigint {
    return reader.ReadInt64()
  }

  public static ReadFloat(reader: EndianBinaryReader): number {
    return reader.ReadSingle()
  }

  public static ReadBytes(reader: EndianBinaryReader): Buffer {
    return reader.ReadBytes(BinDecoder.ReadUInt32(reader))
  }

  public static ReadString(reader: EndianBinaryReader): string {
    return this.ReadBytes(reader).toString('utf8')
  }

  public static ReadArrayLength(reader: EndianBinaryReader): number {
    return Math.min(BinDecoder.ReadUInt32(reader), 16384) // I don't think there's any array gonna be that big?
  }

  public static ReadDictCount(reader: EndianBinaryReader): number {
    return Math.min(BinDecoder.ReadUInt32(reader), 16384) // I don't think there's any dict gonna be that big?
  }

  public static ReadArray<T>(reader: EndianBinaryReader, valDecoder: KVDecoder<T>, out: T[]): void {
    const len = BinDecoder.ReadArrayLength(reader)
    if (len <= 0) return

    for (let i = 0; i < len; i++) {
      out.push(valDecoder(reader))
    }
  }

  public static ReadBinArray<T extends BinaryModelBase>(reader: EndianBinaryReader, ctor: new () => T, out: T[]): void {
    return BinDecoder.ReadArray<T>(reader, reader => new ctor().deserialize(reader), out)
  }

  public static ReadDict<K, V>(reader: EndianBinaryReader, keyDecoder: KVDecoder<K>, valDecoder: KVDecoder<V>, out: Map<K, V>): void {
    const count = BinDecoder.ReadDictCount(reader)
    if (count <= 0) return

    for (let i = 0; i < count; i++) {
      out.set(keyDecoder(reader), valDecoder(reader))
    }
  }

  public static ReadBinDict<K, V extends BinaryModelBase>(reader: EndianBinaryReader, keyDecoder: KVDecoder<K>, ctor: new () => V, out: Map<K, V>): void {
    return BinDecoder.ReadDict<K, V>(reader, keyDecoder, reader => new ctor().deserialize(reader), out)
  }
}

export type KVEncoder<T> = (writer: EndianBinaryWriter, kv: T) => void

export class BinEncoder {
  public static WriteByteArrayEncode(writer: EndianBinaryWriter, value: number[]): void {
    for (const byte of value) {
      writer.WriteByte(byte)
      if ((byte & 0x80) === 0) break
    }
  }

  public static WriteByte(writer: EndianBinaryWriter, value: number): void {
    writer.WriteByte(value)
  }

  public static WriteBool(writer: EndianBinaryWriter, value: boolean): void {
    writer.WriteBoolean(value)
  }

  public static WriteUInt16(writer: EndianBinaryWriter, value: number): void {
    BinEncoder.WriteUInt32(writer, (value & 0xFFFF) >>> 0)
  }

  public static WriteUInt32(writer: EndianBinaryWriter, value: number): void {
    BinEncoder.WriteUInt64(writer, BigInt(value >>> 0) & 0xFFFFFFFFn)
  }

  public static WriteUInt64(writer: EndianBinaryWriter, value: bigint): void {
    const bytes: number[] = []
    while (value > 0x7F) {
      bytes.push(Number((value & 0x7Fn) | 0x80n))
      value >>= 7n
    }
    bytes.push(Number(value & 0x7Fn))

    BinEncoder.WriteByteArrayEncode(writer, bytes)
  }

  public static WriteInt16(writer: EndianBinaryWriter, value: number): void {
    BinEncoder.WriteUInt16(writer, value >> 0xf ^ value * 2)
  }

  public static WriteInt32(writer: EndianBinaryWriter, value: number): void {
    BinEncoder.WriteUInt32(writer, value >> 0x1f ^ value * 2)
  }

  public static WriteFixedUInt16(writer: EndianBinaryWriter, value: number): void {
    writer.WriteUInt16(value)
  }

  public static WriteFixedUInt32(writer: EndianBinaryWriter, value: number): void {
    writer.WriteUInt32(value)
  }

  public static WriteFixedUInt64(writer: EndianBinaryWriter, value: bigint): void {
    writer.WriteUInt64(value)
  }

  public static WriteFixedInt16(writer: EndianBinaryWriter, value: number): void {
    writer.WriteInt16(value)
  }

  public static WriteFixedInt32(writer: EndianBinaryWriter, value: number): void {
    writer.WriteInt32(value)
  }

  public static WriteFixedInt64(writer: EndianBinaryWriter, value: bigint): void {
    writer.WriteInt64(value)
  }

  public static WriteFloat(writer: EndianBinaryWriter, value: number): void {
    writer.WriteSingle(value)
  }

  public static WriteBytes(writer: EndianBinaryWriter, value: Buffer): void {
    BinEncoder.WriteUInt32(writer, value.length)
    writer.WriteBytes(value)
  }

  public static WriteString(writer: EndianBinaryWriter, value: string): void {
    this.WriteBytes(writer, Buffer.from(value, 'utf8'))
  }

  public static WriteArrayLength(writer: EndianBinaryWriter, value: number): void {
    BinEncoder.WriteUInt32(writer, value)
  }

  public static WriteDictCount(writer: EndianBinaryWriter, value: number): void {
    BinEncoder.WriteUInt32(writer, value)
  }

  public static WriteTypeIndex(writer: EndianBinaryWriter, value: number): void {
    BinEncoder.WriteUInt32(writer, value)
  }

  public static WriteArray<T>(writer: EndianBinaryWriter, valEncoder: KVEncoder<T>, inp: T[]): void {
    const len = inp.length
    BinEncoder.WriteDictCount(writer, len)

    if (len <= 0) return

    for (let i = 0; i < len; i++) {
      valEncoder(writer, inp[i])
    }
  }

  public static WriteDict<K, V>(writer: EndianBinaryWriter, keyEncoder: KVEncoder<K>, valEncoder: KVEncoder<V>, inp: Map<K, V>): void {
    const count = inp.size
    BinEncoder.WriteDictCount(writer, count)

    if (count <= 0) return

    const keys = Array.from(inp.keys())
    for (let i = 0; i < count; i++) {
      const key = keys[i]
      const value = inp.get(key)

      keyEncoder(writer, key)
      valEncoder(writer, <V>value)
    }
  }
}