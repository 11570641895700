import { Retcode } from '@/models/api'
import Api from '@/utils/api'
import { getErrorMessage } from '@/utils/error'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonVariant, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import GetPrivateChatRoomReqModel from '@shared/models/api/request/get-private-chat-room'
import GetPrivateChatRoomRspModel from '@shared/models/api/response/get-private-chat-room'
import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

export type OpenChatButtonProps = {
  targetType: 'room'
  targetRoomId: bigint | null
  variant?: ButtonVariant
  className?: string
  text?: string
} | {
  targetType: 'private'
  targetUserId: string | null
  variant?: ButtonVariant
  className?: string
  text?: string
}

export default function OpenChatButton(props: Readonly<OpenChatButtonProps>): ReactElement {
  const navigate = useNavigate()

  function openChatRoom(roomId: bigint | null): void {
    if (roomId == null) return

    navigate(`/chat/${roomId.toString()}`)
  }

  function openPrivateChatRoom(userId: string | null): void {
    if (userId == null) return

    Api.sendRequest(
      '/chat/getPrivateChatRoom',
      GetPrivateChatRoomRspModel,
      new GetPrivateChatRoomReqModel().setUserId(userId)
    ).then(rsp => {
      if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

      const data = rsp.getData()
      if (data == null) throw new Error('data == null')

      openChatRoom(data.getRoomId())
    }).catch(err => {
      modals.open({
        title: 'Error',
        children: (<Text size='sm' c='red'>{getErrorMessage(err)}</Text>),
        withCloseButton: true
      })
    })
  }

  function openChatRoomByType(): void {
    switch (props.targetType) {
      case 'room':
        openChatRoom(props.targetRoomId)
        break
      case 'private':
        openPrivateChatRoom(props.targetUserId)
        break
      default:
        throw new Error('Invalid target type')
    }
  }

  return (
    <Button
      variant={props.variant ?? 'light'}
      leftSection={<FontAwesomeIcon icon={faMessage} size="sm" />}
      className={props.className ?? 'bg-blue-400/30 hover:bg-blue-500/30'}
      onClick={openChatRoomByType}
    >
      {props.text ?? 'Send Message'}
    </Button>
  )
}