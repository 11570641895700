import { faComment, faHome, faPlus, faSearch, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

export default function BottomNav(): ReactElement {
  return (
    <div className='w-full py-4 z-10 bg-zinc-100 dark:bg-dark border-t dark:border-zinc-800 border-zinc-200 shadow-lg sm:hidden'>
      <div className='flex flex-row justify-around items-center bg-transparent w-full'>
        <Link
          to='/home'
          className='flex items-center text-black dark:text-white'
        >
          <FontAwesomeIcon icon={faHome} size='xl' />
        </Link>
        {/* <Link
          to='/search'
          className='flex items-center text-black dark:text-white'
        >
          <FontAwesomeIcon icon={faSearch} size='xl' />
        </Link> */}
        <Link
          to='/event/create'
          className='flex items-center text-black dark:text-white'
        >
          <FontAwesomeIcon icon={faPlus} size='xl' />
        </Link>
        <Link
          to='/chat'
          className='flex items-center text-black dark:text-white'
        >
          <FontAwesomeIcon icon={faComment} size='xl' />
        </Link>
        <Link
          to='/profile'
          className='flex items-center text-black dark:text-white'
        >
          <FontAwesomeIcon icon={faUser} size='xl' />
        </Link>
      </div>
    </div>
  )
}