import TopNavLayout from '@/components/layout/top-nav'
import Protected from '@/components/protected'
import { AuthState } from '@/constants/auth'
import Auth from '@/utils/auth'
import { Button } from '@mantine/core'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export default function ErrorPage(): ReactElement {
  const { t } = useTranslation('error')

  function forceRetry() {
    Auth.getAuthState(false)
  }

  return (
    <Protected state={AuthState.ERROR} redirect className='h-full'>
      <TopNavLayout className='flex flex-col justify-center items-center '>
        <h1 className='text-3xl'>Oops...</h1>
        <h2>{t('generic.network')}</h2>
        <br />
        <Button variant='outline' onClick={forceRetry}>{t('generic.retry')}</Button>
      </TopNavLayout>
    </Protected>
  )
}
