// src/utils/firebaseUtils.ts
import { getDownloadURL, getStorage, ref } from 'firebase/storage'

const urlCache: { [path: string]: [number, string | null] } = {}

let fetchTask: Promise<string | null> | null = null

// Fetch single image
export async function fetchEventImageUrl(imagePath: string): Promise<string | null> {
  if (fetchTask != null) await fetchTask.catch(() => { /* NOOP */ })

  const cache = urlCache[imagePath]
  if (cache !== undefined) {
    if (Date.now() - cache[0] < 60e3) return cache[1]
    delete urlCache[imagePath]
  }

  const storage = getStorage()
  const imageRef = ref(storage, imagePath)

  try {
    fetchTask = getDownloadURL(imageRef)
    const url = await fetchTask
    fetchTask = null

    urlCache[imagePath] = [Date.now(), url]
    return url
  } catch (error) {
    console.error('Failed to fetch image URL:', error)
    urlCache[imagePath] = [Date.now(), null]
    return null
  }
}

// Fetch multiple images
export async function fetchEventImageUrlList(imagePathList: string[]): Promise<(string | null)[]> {
  return Promise.all(imagePathList.map(fetchEventImageUrl))
}