import { useAttendeListAsync } from '@/hooks/attende-list'
import Api from '@/utils/api'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faCheckDouble, faTrash, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Badge, Group, Stack, Text, Tooltip } from '@mantine/core'
import { modals } from '@mantine/modals'
import { showNotification } from '@mantine/notifications'
import { Retcode } from '@shared/models/api'
import UpdateAttendStatusReqModel from '@shared/models/api/request/update-attend-status'
import { EventAttendAnswerJSONModel } from '@shared/models/event/attend-answer'
import EventAttendeeModel, { EventAttendeeStatus } from '@shared/models/event/attendee'
import { DataTable, DataTableSortStatus } from 'mantine-datatable'
import React, { useEffect, useState } from 'react'

// Define the type for props
interface AttendeesTableProps {
  eventId: string
  sortStatus?: DataTableSortStatus
}

const getStatusColor = (status: number) => {
  switch (EventAttendeeStatus[status]) {
    case 'STATUS_NONE':
      return 'red'
    case 'STATUS_PENDING':
      return 'orange'
    case 'STATUS_JOINED':
      return 'blue'
    case 'STATUS_REJECTED':
      return 'red'
    case 'STATUS_CHECKED_IN':
      return 'green'
    default:
      return 'yellow'
  }
}

const AttendeesTable: React.FC<AttendeesTableProps> = ({ eventId }) => {
  async function onAccept(attendee: EventAttendeeModel) {
    if (!attendee) {
      console.error('Attendee is undefined')
      return
    }
    // console.log('Accept', attendee)
    try {
      const rsp = await Api.sendCsrfRequest(
        '/event/acceptAttend',
        undefined,
        new UpdateAttendStatusReqModel().fromJSONObject({
          eventId: attendee.getEvent().getId() ?? '',
          userId: attendee.getUser().getId(),
        }),
      )
      if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

      showNotification({
        title: 'Success',
        message: 'User has been accepted',
        withBorder: true,
      })
    } catch (error) {
      console.error(error)
      showNotification({
        title: 'Error',
        message: 'Failed to accept user',
        withBorder: true,
      })
    }
  }

  async function onReject(attendee: EventAttendeeModel) {
    console.log('Reject', attendee)
    try {
      const rsp = await Api.sendCsrfRequest(
        '/event/rejectAttend',
        undefined,
        new UpdateAttendStatusReqModel().fromJSONObject({
          eventId: attendee.getEvent().getId() ?? '',
          userId: attendee.getUser().getId(),
        }),
      )
      if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

      showNotification({
        title: 'Success',
        message: 'User has been rejected',
        withBorder: true,
      })
    } catch (error) {
      console.error(error)
      showNotification({
        title: 'Error',
        message: 'Failed to reject user',
        withBorder: true,
      })
    }
  }

  async function onRevoke(attendee: EventAttendeeModel) {
    console.log('Cancel', attendee)
    try {
      const rsp = await Api.sendCsrfRequest(
        '/event/revokeAttendEvent',
        undefined,
        new UpdateAttendStatusReqModel().fromJSONObject({
          eventId: attendee.getEvent().getId() ?? '',
          userId: attendee.getUser().getId(),
        }),
      )
      if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

      showNotification({
        title: 'Success',
        message: 'User has been canceled',
        withBorder: true,
      })
    } catch (error) {
      console.error(error)
      showNotification({
        title: 'Error',
        message: 'Failed to cancel user',
        withBorder: true,
      })
    }
  }

  async function onCheckIn(attendee: EventAttendeeModel) {
    console.log('Check In', attendee)
    try {
      const rsp = await Api.sendCsrfRequest(
        '/event/checkInAttendEvent',
        undefined,
        new UpdateAttendStatusReqModel().fromJSONObject({
          eventId: attendee.getEvent().getId() ?? '',
          userId: attendee.getUser().getId(),
        }),
      )
      if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

      showNotification({
        title: 'Success',
        message: 'User has been checked in',
        withBorder: true,
      })
    } catch (error) {
      console.error(error)
      showNotification({
        title: 'Error',
        message: 'Failed to check in user',
        withBorder: true,
      })
    }
  }

  function showModal(arg0: { attendee: EventAttendeeModel; action: string }): void {
    const { attendee, action } = arg0

    switch (action) {
      case 'view':
        modals.open({
          size: 'lg',
          centered: true,
          title: attendee.getUser().getDisplayName(),
          children: (
            <Stack gap={6}>
              {JSON.parse(attendee.getAnswerData()).map((answer: EventAttendAnswerJSONModel) => (
                <Group key={answer.question} gap={0}>
                  <div className=" w-60">{answer.question}</div>
                  <div>{answer.answer}</div>
                </Group>
              ))}
            </Stack>
          ),
        })
        break
      case 'accept':
        modals.openConfirmModal({
          size: 'lg',
          centered: true,
          title: (
            <Text>
              Are you sure you want to <span className="text-green-500">accept</span> this user?
            </Text>
          ),
          children: (
            <Stack gap={6}>
              {JSON.parse(attendee.getAnswerData()).map((answer: EventAttendAnswerJSONModel) => (
                <Group key={answer.question} gap={0}>
                  <div className=" w-60">{answer.question}</div>
                  <div>{answer.answer}</div>
                </Group>
              ))}
            </Stack>
          ),
          labels: { confirm: 'Confirm', cancel: 'Cancel' },
          confirmProps: { className: 'bg-green-600 hover:bg-green-600/70' },
          cancelProps: { color: 'gray', bg: 'gray-500' },
          onCancel: () => console.log('Cancel'),
          onConfirm: () => onAccept(attendee),
        })
        break
      case 'reject':
        modals.openConfirmModal({
          size: 'lg',
          centered: true,
          title: (
            <Text>
              Are you sure you want to <span className="text-red-500">reject</span> this user?
            </Text>
          ),
          children: (
            <Stack gap={6}>
              {JSON.parse(attendee.getAnswerData()).map((answer: EventAttendAnswerJSONModel) => (
                <Group key={answer.question} gap={0}>
                  <div className=" w-60">{answer.question}</div>
                  <div>{answer.answer}</div>
                </Group>
              ))}
            </Stack>
          ),
          labels: { confirm: 'Confirm', cancel: 'Cancel' },
          confirmProps: { className: 'bg-red-600 hover:bg-red-600/70' },
          cancelProps: { color: 'gray', bg: 'gray-500' },
          onCancel: () => console.log('Cancel'),
          onConfirm: () => onReject(attendee),
        })
        break
      case 'revoke':
        modals.openConfirmModal({
          size: 'lg',
          centered: true,
          title: (
            <Text>
              Are you sure you want to <span className="text-red-500">revoke</span> approval for this user?
            </Text>
          ),
          children: (
            <Stack gap={6}>
              {JSON.parse(attendee.getAnswerData()).map((answer: EventAttendAnswerJSONModel) => (
                <Group key={answer.question} gap={0}>
                  <div className=" w-60">{answer.question}</div>
                  <div>{answer.answer}</div>
                </Group>
              ))}
            </Stack>
          ),
          labels: { confirm: 'Confirm', cancel: 'Cancel' },
          confirmProps: { className: 'bg-red-600 hover:bg-red-600/70' },
          cancelProps: { color: 'gray', bg: 'gray-500' },
          onCancel: () => console.log('Cancel'),
          onConfirm: () => onRevoke(attendee),
        })
        break
      case 'checkIn':
        modals.openConfirmModal({
          size: 'lg',
          centered: true,
          title: (
            <Text>
              Are you confirm this attendee <span className="text-green-500">arrvied</span>?
            </Text>
          ),
          children: (
            <Stack gap={6}>
              {JSON.parse(attendee.getAnswerData()).map((answer: EventAttendAnswerJSONModel) => (
                <Group key={answer.question} gap={0}>
                  <div className=" w-60">{answer.question}</div>
                  <div>{answer.answer}</div>
                </Group>
              ))}
            </Stack>
          ),
          labels: { confirm: 'Confirm', cancel: 'Cancel' },
          confirmProps: { className: 'bg-green-600 hover:bg-green-600/70' },
          cancelProps: { color: 'gray', bg: 'gray-500' },
          onCancel: () => console.log('Cancel'),
          onConfirm: () => onCheckIn(attendee),
        })
        break
      default:
        console.error('Invalid action', action)
    }
  }

  function renderActions(attendee: EventAttendeeModel, status: EventAttendeeStatus): React.ReactNode {
    switch (status) {
      case EventAttendeeStatus.STATUS_JOINED:
        return (
          <Group gap={4} wrap="nowrap">
            <Tooltip label="Revoke">
              <ActionIcon
                size="lg"
                variant="subtle"
                color="rgba(255, 25, 25, 1)"
                onClick={() => showModal({ attendee, action: 'revoke' })}
              >
                <FontAwesomeIcon icon={faTrash} size="lg" />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Check-in">
              <ActionIcon
                size="lg"
                variant="subtle"
                color="green"
                onClick={() => showModal({ attendee, action: 'checkIn' })}
              >
                <FontAwesomeIcon icon={faCheckDouble} size="lg" />
              </ActionIcon>
            </Tooltip>
          </Group>
        )
      case EventAttendeeStatus.STATUS_CHECKED_IN:
        return (
          <Group gap={4} wrap="nowrap">
            <Tooltip label="View">
              <ActionIcon
                size="lg"
                variant="subtle"
                color="gray"
                onClick={() => showModal({ attendee, action: 'view' })}
              >
                <FontAwesomeIcon icon={faEye} size="lg" />
              </ActionIcon>
            </Tooltip>
          </Group>
        )
      default:
        return (
          <Group gap={4} wrap="nowrap">
            <Tooltip label="View">
              <ActionIcon
                size="lg"
                variant="subtle"
                color="gray"
                onClick={() => showModal({ attendee, action: 'view' })}
              >
                <FontAwesomeIcon icon={faEye} size="lg" />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Accept">
              <ActionIcon
                size="lg"
                variant="subtle"
                color="blue"
                onClick={() => showModal({ attendee, action: 'accept' })}
              >
                <FontAwesomeIcon icon={faCheck} size="lg" />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Reject">
              <ActionIcon
                size="lg"
                variant="subtle"
                color="rgba(255, 105, 105, 1)"
                onClick={() => showModal({ attendee, action: 'reject' })}
              >
                <FontAwesomeIcon icon={faX} size="lg" />
              </ActionIcon>
            </Tooltip>
          </Group>
        )
    }
  }

  function renderContent(attendee: EventAttendeeModel): React.ReactNode {
    console.log(attendee)

    return (
      <Stack p="xs" gap={6}>
        {JSON.parse(attendee.getAnswerData()).map((answer: EventAttendAnswerJSONModel) => (
          <Group key={answer.question} gap={0}>
            <div className=" w-48">{answer.question}</div>
            <div>{answer.answer}</div>
          </Group>
        ))}
      </Stack>
    )
  }
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<EventAttendeeModel>>({
    columnAccessor: '#',
    direction: 'asc',
  })

  const { records, loading } = useAttendeListAsync({ eventId, sortStatus })

  useEffect(() => {
    console.log('sortStatus', sortStatus)
  }, [sortStatus])

  return (
    <DataTable
      className="min-h-40"
      withTableBorder
      borderRadius="md"
      withColumnBorders
      striped
      highlightOnHover
      pinLastColumn
      records={records} // provide data
      fetching={loading}
      sortStatus={sortStatus}
      onSortStatusChange={setSortStatus}
      columns={[
        { accessor: '#', title: <Text inherit>#</Text>, render: (attendee, index) => attendee.getId(), sortable: true },
        {
          accessor: 'name',
          title: <Text inherit>Name</Text>,
          render: (attendee) => attendee.getUser().getDisplayName(),
          sortable: true,
        },
        {
          accessor: 'phone',
          title: <Text inherit>Phone Number</Text>,
          render: (attendee) => (
            <Text size="sm" c="gray" className="truncate">
              {attendee.getPhoneNumber()}
            </Text>
          ),
          sortable: true,
        },
        {
          accessor: 'status',
          title: <Text inherit>Status</Text>,
          render: (attendee) => {
            const color = getStatusColor(attendee.getStatus())

            return (
              <Badge color={color} variant="light" className="font-sans text-sm">
                {attendee.getStatusString()}
              </Badge>
            )
          },
          sortable: true,
        },
        {
          accessor: 'actions',
          title: <Text inherit>Actions</Text>,

          render: (attendee) => renderActions(attendee, attendee.getStatus()),
        },
      ]}
      rowExpansion={{
        allowMultiple: true,
        content: ({ record }) => renderContent(record),
      }}
    />
  )
}

export default AttendeesTable
