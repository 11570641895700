import { createFormContext } from '@mantine/form';
import { CountryCode } from 'libphonenumber-js';

export interface ProfileSetupFormData {
  displayName: string
  phoneNumber: string
  countryCode: CountryCode
}

// You can give context variables any name
export const [ProfileSetupFormProvider, useProfileSetupFormContext, useProfileSetupForm] =
  createFormContext<ProfileSetupFormData>();