import BinaryModelBase, { BinaryValueType } from '@shared/models/binary'
import ChatMessageModel, { ChatMessageJSONModel } from '@shared/models/chat/chat-message'
import ChatRoomModel, { ChatRoomJSONModel } from '@shared/models/chat/chat-room'

export interface PullChatRspJSONModel {
  roomList: ChatRoomJSONModel[] | null
  messageList: ChatMessageJSONModel[] | null
}

export default class PullChatRspModel extends BinaryModelBase<PullChatRspModel> {
  /// Public ///

  public constructor() {
    super()

    this.roomList = []
    this.messageList = []

    this.addField('RoomList', { type: BinaryValueType.ARRAY, value: { type: BinaryValueType.BIN, ctor: ChatRoomModel } })
    this.addField('MessageList', { type: BinaryValueType.ARRAY, value: { type: BinaryValueType.BIN, ctor: ChatMessageModel } })
  }

  public getRoomList(): ChatRoomModel[] {
    return [...this.roomList]
  }

  public getMessageList(): ChatMessageModel[] {
    return [...this.messageList]
  }

  public setRoomList(roomList: ChatRoomModel[]): this {
    this.roomList = roomList
    return this
  }

  public setMessageList(messageList: ChatMessageModel[]): this {
    this.messageList = messageList
    return this
  }

  public toJSONObject(): PullChatRspJSONModel {
    const { roomList, messageList } = this

    return {
      roomList: roomList.length === 0 ? null : roomList.map(m => m.toJSONObject()),
      messageList: messageList.length === 0 ? null : messageList.map(m => m.toJSONObject())
    }
  }

  public fromJSONObject(json: PullChatRspJSONModel): this {
    this.validate(json, 'roomList', 'array', true)
    this.validate(json, 'messageList', 'array', true)

    const { roomList, messageList } = json

    if (roomList?.find(v => typeof v !== 'object') != null) throw new Error(`invalid array value type 'roomList'`)
    if (messageList?.find(v => typeof v !== 'object') != null) throw new Error(`invalid array value type 'messageList'`)

    this.roomList = roomList == null ? [] : roomList.map(m => new ChatRoomModel().fromJSONObject(m))
    this.messageList = messageList == null ? [] : messageList.map(m => new ChatMessageModel().fromJSONObject(m))

    return this
  }

  /// Private ///

  private roomList: ChatRoomModel[]
  private messageList: ChatMessageModel[]

  /// Binary getter/setter ///

  public get RoomList(): ChatRoomModel[] {
    return this.roomList
  }

  public get MessageList(): ChatMessageModel[] {
    return this.messageList
  }

  public set RoomList(value: ChatRoomModel[]) {
    this.roomList = value
  }

  public set MessageList(value: ChatMessageModel[]) {
    this.messageList = value
  }
}