import { ReactElement } from 'react'
import ThirdPartyButton from './button'
import ThirdPartySeparator from './separator'
import Auth from '@/utils/auth'

export type ThirdPartyProps = {
  separator?: 'top' | 'bottom'
}

export default function ThirdParty({ separator }: Readonly<ThirdPartyProps>): ReactElement {
  const firebaseProvider: Record<string, { name: string; onClick: () => void }> = {
    google: {
      name: 'Google',
      onClick: async () => {
        console.log('Sign in with Google')
        await Auth.loginByGoogle();
      },
    },
  }

  return (
    <div className="flex flex-col px-1 justify-around items-stretch gap-4">
      {separator === 'top' ? <ThirdPartySeparator /> : null}
      {firebaseProvider &&
        Object.entries(firebaseProvider).map(([provider, { name, onClick }]) => (
          <ThirdPartyButton key={`tp-${provider}`} provider={provider} onClick={onClick} />
        ))}
      {separator === 'bottom' ? <ThirdPartySeparator /> : null}
    </div>
  )
}
