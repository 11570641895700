import ModelBase from '@shared/models/base'

export interface PublishEventReqJSONModel {
  eventId: string
}

export default class PublishEventReqModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.eventId = ''
  }

  public getEventId(): string {
    return this.eventId
  }

  public toJSONObject(): PublishEventReqJSONModel {
    const { eventId: id } = this

    return {
      eventId: id
    }
  }

  public fromJSONObject(json: PublishEventReqJSONModel): this {
    this.validate(json, 'eventId', 'string')

    const { eventId: id } = json

    this.eventId = id

    return this
  }

  /// Private ///

  private eventId: string
}