import ModelBase from '@shared/models/base'
import EventModel, { EventJSONModel } from './'
import { EventAttendAnswerJSONModel } from './attend-answer'
import AttendeeInfoModel, { AttendeeInfoJSONModel } from './attendee-info'

export enum EventAttendeeStatus {
  STATUS_NONE = 0,
  STATUS_PENDING,
  STATUS_JOINED,
  STATUS_REJECTED,
  STATUS_CHECKED_IN,
}

export interface EventAttendeeJSONModel {
  id: string
  event: EventJSONModel
  user: AttendeeInfoJSONModel
  attendTime: number
  answerData: string
  status: string
}

export default class EventAttendeeModel extends ModelBase {
  /// Public ///
  public constructor() {
    super()

    this.id = ''
    this.event = new EventModel()
    this.user = new AttendeeInfoModel()
    this.attendTime = new Date(0)
    this.answerData = ''
    this.status = EventAttendeeStatus.STATUS_NONE
  }

  public getId(): string {
    return this.id
  }

  public getEvent(): EventModel {
    return this.event
  }

  public getUser(): AttendeeInfoModel {
    return this.user
  }

  public getAttendTime(): Date {
    return this.attendTime
  }

  public getAnswerData(): string {
    return this.answerData
  }

  public getStatus(): EventAttendeeStatus {
    return this.status
  }

  public getStatusString(): string {
    return EventAttendeeStatus[this.status].substring(7).toLowerCase().replace(/_/g, ' ')
  }

  public getPhoneNumber(): string {
    if (!this.answerData) {
      return 'N/A'
    }

    try {
      const answers: EventAttendAnswerJSONModel[] = JSON.parse(this.answerData)
      const phoneAnswer = answers.find((answer) => answer.question.toLowerCase().includes('phone number'))
      if (phoneAnswer) {
        return phoneAnswer.answer
      }
    } catch (error) {
      console.error('Failed to parse answerData:', error)
    }

    return 'N/A'
  }

  public setId(id: string): this {
    this.id = id
    return this
  }

  public setEvent(event: EventModel): this {
    this.event = event
    return this
  }

  public setUser(user: AttendeeInfoModel): this {
    this.user = user
    return this
  }

  public setAttendTime(attendTime: Date): this {
    this.attendTime = attendTime
    return this
  }

  public setAnswerData(answerData: string): this {
    this.answerData = answerData
    return this
  }

  public setStatus(status: EventAttendeeStatus): this {
    this.status = status
    return this
  }

  public toJSONObject(): EventAttendeeJSONModel {
    const { id, event, user, attendTime, answerData, status } = this

    return {
      id,
      event: event.toJSONObject(),
      user: user.toJSONObject(),
      attendTime: attendTime.getTime(),
      answerData,
      status: EventAttendeeStatus[status],
    }
  }

  public fromJSONObject(json: EventAttendeeJSONModel): this {
    this.validate(json, 'id', 'string')
    this.validate(json, 'event', 'object')
    this.validate(json, 'user', 'object')
    this.validate(json, 'attendTime', 'number')
    this.validate(json, 'answerData', 'string')
    this.validate(json, 'status', 'string')

    const { id, event, user, attendTime, answerData, status } = json

    this.id = id
    this.event = new EventModel().fromJSONObject(event)
    this.user = new AttendeeInfoModel().fromJSONObject(user)
    this.attendTime = new Date(attendTime)
    this.answerData = answerData
    this.status = EventAttendeeStatus[<keyof typeof EventAttendeeStatus>status] ?? EventAttendeeStatus.STATUS_NONE

    return this
  }

  /// Private ///
  private id: string
  private event: EventModel
  private user: AttendeeInfoModel
  private attendTime: Date
  private answerData: string
  private status: EventAttendeeStatus
}
