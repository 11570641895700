// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth ,ActionCodeSettings, GoogleAuthProvider, browserLocalPersistence, initializeAuth, browserPopupRedirectResolver } from 'firebase/auth'
import { actionCodeContinueUrl } from './url'
import { getStorage } from "firebase/storage";

// Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyAu0ABzyghxnqHfrfR0BgJCLC_J0-WaJLw',
  authDomain: 'event-management-app-29ce6.firebaseapp.com',
  projectId: 'event-management-app-29ce6',
  storageBucket: 'event-management-app-29ce6.appspot.com',
  messagingSenderId: '1053932548148',
  appId: '1:1053932548148:web:37f82345de71197bf31a61',
  measurementId: 'G-51DVM7P89H',
}

export const firebaseActionCodeConfig: ActionCodeSettings = {
  url: actionCodeContinueUrl
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
export const storage = getStorage(firebaseApp);
export const firebaseAuth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver
})

export const googleProvider = new GoogleAuthProvider();