import { Card, Image, Text, Grid, Stack } from '@mantine/core'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

interface MemberCardProps {
  organizerId: string
  organizerName: string
  role: string
  icon: string
  url: string
}

export default function MemberCard(props: Readonly<MemberCardProps>): ReactElement {
  return (
    <Card key={props.organizerId} shadow="md" radius="md" withBorder className="block" padding="lg">
      <Grid align="center" justify="center">
        <Grid.Col span={3}>
          <Image src={require('@/assets/images/logo.png')} />
        </Grid.Col>
        <Grid.Col span={9}>
          <Stack gap="0">
            <Text className="font-sans text-base sm:text-lg font-semibold text-black dark:text-white">
              {props.organizerName}
            </Text>
            <Text className="text-xs md:text-sm font-sans mb-2">{props.role}</Text>
            <Link to={props.url} className="text-sm md:text-base font-sans underline w-fit">
              View Profile
            </Link>
          </Stack>
        </Grid.Col>
      </Grid>
    </Card>
  )
}
