import ModelBase from '@shared/models/base'

export interface SearchCatagoryReqJSONModel {
  input: string
}

export default class SearchCatagoryReqModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.input = ''
  }

  public getInput(): string {
    return this.input
  }

  public setInput(catagory: string): this {
    this.input = catagory
    return this
  }

  public toJSONObject(): SearchCatagoryReqJSONModel {
    const { input: catagory } = this

    return {
      input: catagory
    }
  }

  public fromJSONObject(json: SearchCatagoryReqJSONModel): this {
    this.validate(json, 'input', 'string')

    const { input: catagory } = json

    this.input = catagory

    return this
  }

  /// Private ///

  private input: string
}