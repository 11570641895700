import Protected from '@/components/protected'
import { AuthState } from '@/constants/auth'
import { ReactElement } from 'react'
import { FullScreenModalLayout } from '../components/layout/full-screen-modal'

export default function SplashPage(): ReactElement {
  return (
    <Protected state={AuthState.CHECKING} redirect className='h-full'>
      <FullScreenModalLayout className='flex flex-col justify-center items-center '>
        <h1 className='text-3xl'>Loading...</h1>
      </FullScreenModalLayout>
    </Protected>
  )
}