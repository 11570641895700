import { IconDefinition, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'

export type AlertType = 'info' | 'danger' | 'success' | 'warning' | 'dark'
export type AlertProps = {
  type: AlertType
  message: string
  icon?: IconDefinition
}

const alertStyles = {
  info: 'text-blue-800 bg-blue-50 border-blue-300 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800',
  danger: 'text-red-800 bg-red-50 border-red-300 dark:bg-gray-800 dark:text-red-400 dark:border-red-800',
  success: 'text-green-800 bg-green-50 border-green-300 dark:bg-gray-800 dark:text-green-400 dark:border-green-800',
  warning:
    'text-yellow-800 bg-yellow-50 border-yellow-300 dark:bg-gray-800 dark:text-yellow-400 dark:border-yellow-800',
  dark: 'text-gray-800 bg-gray-50 border-gray-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-800',
}

export default function Alert({ type, message, icon }: AlertProps): ReactElement {
  return (
    <div className={`flex items-center p-4 mb-4 text-sm whitespace-pre border rounded-lg ${alertStyles[type]} `} role="alert">
      <FontAwesomeIcon icon={icon ?? faInfoCircle} size="lg" className="mr-2" />
      {message}
    </div>
  )
}