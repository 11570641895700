import { firebaseActionCodeConfig, firebaseAuth } from '@/configs/firebase'
import { AuthState } from '@/constants/auth'
import { Retcode } from '@/models/api'
import Api from '@/utils/api'
import GetUserRspModel from '@shared/models/api/response/get-user'
import UserModel from '@shared/models/user'
import { redirect } from 'react-router-dom'
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
} from 'firebase/auth'

const NORM_VERIFY_INTERVAL = 60e3 // 60s
const FAST_VERIFY_INTERVAL = 10e3 // 10s

export default class Auth {
  /// Public ///

  public static currentUser: UserModel | null = null // NOSONAR

  public static requirements = [
    { re: /[0-9]/, label: 'label.requirementNum' },
    { re: /[a-z]/, label: 'label.requirementLower' },
  ]

  public static activate(): void {
    if (this.authState === AuthState.INACTIVE) this.authState = AuthState.CHECKING
  }

  public static async getAuthState(isUseCache = true): Promise<AuthState> {
    await this.waitActive()
    await this.lockMu()
    try {
      // Use cached verify result for frequent checks
      if (isUseCache && Date.now() - this.lastVerifyTime < this.getVerifyInterval()) return this.authState

      // Default to current auth state
      let state = this.authState

      // Check for preconditions before verify
      if (await this.isNeedVerify()) {
        // Get auth state of auth token
        state = await this.verifyAuthToken()
      }

      // Update last verify time
      this.lastVerifyTime = Date.now()

      // Check if auth state is changed
      if (this.authState === state) return this.authState

      // Update auth state
      this.authState = state

      return this.authState
    } finally {
      this.unlockMu()
    }
  }

  public static async logout(): Promise<boolean> {
    await this.lockMu()
    try {
      await firebaseAuth.signOut()
      return true
    } catch (err) {
      console.error(err)
      return false
    } finally {
      this.unlockMu()
    }
  }

  public static async loginByAccount(email: string, pass: string, target?: string): Promise<void> {
    await this.lockMu()
    try {
      // Sign in
      await signInWithEmailAndPassword(firebaseAuth, email, pass)

      // Redirect to target if set
      if (target != null) redirect(target)
    } catch (err) {
      // Attempt to signout on error
      firebaseAuth.signOut().catch(() => {
        /* NOOP */
      })

      // Rethrow error
      throw err
    } finally {
      this.unlockMu()
    }
  }

  // Sign in with Google
  public static async loginByGoogle(): Promise<void> {
    await this.lockMu()
    try {
      // Sign in with Google
      const provider = new GoogleAuthProvider()
      await signInWithPopup(firebaseAuth, provider)

      // Redirect to home page
      redirect('/')
    } catch (err) {
      // Attempt to signout on error
      firebaseAuth.signOut().catch(() => {
        /* NOOP */
      })

      // Rethrow error
      console.error(err)
      redirect('/')
    } finally {
      this.unlockMu()
    }
  }

  public static async createAccountAndSendVerification(email: string, pass: string): Promise<void> {
    await this.lockMu()
    try {
      // Create user
      const { user } = await createUserWithEmailAndPassword(firebaseAuth, email, pass)

      // Send verification email
      await sendEmailVerification(user, firebaseActionCodeConfig)

      // Redirect to verify page
      redirect('/verify')
    } catch (err) {
      // Attempt to signout on error
      firebaseAuth.signOut().catch(() => {
        /* NOOP */
      })

      // Rethrow error
      throw err
    } finally {
      this.unlockMu()
    }
  }

  // Change password
  public static async changePassword(currentPassword: string, newPassword: string): Promise<void> {
    await this.lockMu()
    try {
      const user = firebaseAuth.currentUser
      if (!user) throw new Error('Not logged in')

      // Reauthenticate if necessary
      const credential = EmailAuthProvider.credential(user.email!, currentPassword)
      await reauthenticateWithCredential(user, credential)

      // Update password
      await updatePassword(user, newPassword)
    } catch (err) {
      console.error(err)
      throw err // Or handle error appropriately
    } finally {
      this.unlockMu()
    }
  }

  // Reset password
  public static async resetPassword(email: string): Promise<boolean> {
    await this.lockMu()
    try {
      await sendPasswordResetEmail(firebaseAuth, email)
      // Inform the user to check their email for password reset instructions

      return true // Return true if the email was sent successfully
    } catch (err) {
      console.error(err)
      return false // Return false if there was an error
    } finally {
      this.unlockMu()
    }
  }

  public static async resendVerification(): Promise<void> {
    await this.lockMu()
    try {
      // Get current user
      const user = firebaseAuth.currentUser
      if (user == null) throw new Error('Current user is null')

      // Send verification email
      await sendEmailVerification(user, firebaseActionCodeConfig)
    } finally {
      this.unlockMu()
    }
  }

  public static getStrength(password: string) {
    let multiplier = password.length >= 8 ? 0 : 1

    this.requirements.forEach((requirement) => {
      if (!requirement.re.test(password)) {
        multiplier += 1
      }
    })

    return Math.max(100 - (100 / (this.requirements.length + 1)) * multiplier, 0)
  }

  /// Private ///

  private static mutex: Promise<void> | null = null
  private static mutexResolve: (() => void) | null = null

  private static lastVerifyTime: number = 0
  private static authState: AuthState = AuthState.INACTIVE

  private static async waitActive(): Promise<void> {
    if (this.authState !== AuthState.INACTIVE) return

    return new Promise((resolve) => {
      const loop = setInterval(() => {
        if (this.authState === AuthState.INACTIVE) return

        clearInterval(loop)
        resolve()
      })
    })
  }

  private static async lockMu(): Promise<void> {
    // Wait for current mutex to unlock
    while (this.mutex != null) await this.mutex

    // Create new mutex
    this.mutex = new Promise((resolve) => (this.mutexResolve = resolve))
  }

  private static unlockMu(): void {
    if (this.mutex == null && this.mutexResolve == null) return

    // Resolve mutex promise
    this.mutexResolve?.()

    // Clear mutex
    this.mutex = null
    this.mutexResolve = null
  }

  private static getVerifyInterval(): number {
    switch (this.authState) {
      case AuthState.ERROR:
      case AuthState.VERIFY:
        return FAST_VERIFY_INTERVAL
      default:
        return NORM_VERIFY_INTERVAL
    }
  }

  private static async isNeedVerify(): Promise<boolean> {
    // Use normal verify if auth state is not verify
    if (this.authState !== AuthState.VERIFY) return true

    // Get current user or fallback to normal verify if user not found
    const user = firebaseAuth.currentUser
    if (user == null) return true

    // Reload user data
    await user.reload()

    // No need to refresh id token if email is not verified
    if (!user.emailVerified) return false

    // Force refresh id token for server verify
    await user.getIdToken(true)

    return true
  }

  private static async verifyAuthToken(): Promise<AuthState> {
    // Verify auth token with server
    const verifyRsp = await Api.sendRequest('/auth/verify')

    // Get state by retcode
    switch (verifyRsp.getRetcode()) {
      case Retcode.RET_SUCC: {
        if (this.currentUser != null) return AuthState.LOGIN

        const getUserRsp = await Api.sendRequest('/user/getUser', GetUserRspModel)
        switch (getUserRsp.getRetcode()) {
          case Retcode.RET_SUCC:
            this.currentUser = getUserRsp.getData()?.getUser() ?? null
            return AuthState.LOGIN
          case Retcode.RET_NOT_FOUND:
            return AuthState.SETUP
          default:
            console.warn(getUserRsp.getMsg())
            return AuthState.ERROR
        }
      }
      case Retcode.RET_AUTH_FAIL:
        if (this.currentUser != null) this.currentUser = null
        return AuthState.LOGOUT
      case Retcode.RET_VERIFY_EMAIL:
        return AuthState.VERIFY
      default:
        console.warn(verifyRsp.getMsg())
        return AuthState.ERROR
    }
  }
}
