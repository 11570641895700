import ModelBase from '@shared/models/base'

export interface CreateEventDraftRspJSONModel {
  eventId: string
}

export default class CreateEventDraftRspModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.eventId = ''
  }

  public getEventId(): string {
    return this.eventId
  }

  public setEventId(eventId: string): this {
    this.eventId = eventId
    return this
  }

  public toJSONObject(): CreateEventDraftRspJSONModel {
    const { eventId } = this

    return {
      eventId
    }
  }

  public fromJSONObject(json: CreateEventDraftRspJSONModel): this {
    this.validate(json, 'eventId', 'string')

    const { eventId } = json

    this.eventId = eventId

    return this
  }

  /// Private ///

  private eventId: string
}