import { devSocketUrl, socketUrl } from '@/configs/url'
import { ApiSocketCmdId, ApiSocketRspModel } from '@shared/models/api/socket'
import BinaryModelBase from '@shared/models/binary'
import { firebaseAuth } from '../configs/firebase'
import { ApiSocketReqModel, Retcode } from '../models/api'
import { isDevServerOnline } from './api'
import { getErrorMessage } from './error'

class SocketTransaction {
  /// Public ///

  public constructor(cmdId: ApiSocketCmdId) {
    this.resolve = null
    this.reject = null

    this.cmdId = cmdId & 0xF0FF
    this.data = null

    this.promise = new Promise((resolve, reject) => {
      this.resolve = (data) => {
        this.resolve = null
        this.reject = null

        this.data = data
        resolve(data)
      }
      this.reject = (err) => {
        this.resolve = null
        this.reject = null
        reject(err)
      }
    })
  }

  public complete(data: Buffer): void {
    this.resolve?.(data)
  }

  public abort(err: Error): void {
    this.reject?.(err)
  }

  public isRspCmd(cmdId: ApiSocketCmdId): boolean {
    return ((cmdId >> 8) & 0xF) === 2 && this.cmdId === (cmdId & 0xF0FF)
  }

  public getData(): Buffer | null {
    return this.data
  }

  public async wait(): Promise<Buffer> {
    return this.promise
  }

  /// Private ///

  private promise: Promise<Buffer>
  private resolve: ((data: Buffer) => void) | null
  private reject: ((err: Error) => void) | null

  private cmdId: ApiSocketCmdId
  private data: Buffer | null
}

export type SocketNotifyCallback = (cmdId: ApiSocketCmdId, notfiyBuf: Buffer) => void

export class Socket {
  /// Public ///

  public constructor(url: string) {
    this.url = url
    this.socket = null
    this.checkOpenLoop = -1

    this.transactionQueue = []
    this.notifyCallback = null

    this.tokenExpireTime = null

    this.onOpen = this.onOpen.bind(this)
    this.onMessage = this.onMessage.bind(this)
    this.onError = this.onError.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  public open(): void {
    if (this.socket != null) {
      if (this.socket.readyState <= 1) return

      this.onClose()
    }

    this.socket = new WebSocket(`${isDevServerOnline ? devSocketUrl : socketUrl}${this.url}`)

    console.debug('[socket] open:', this.socket.url)

    this.socket.binaryType = 'arraybuffer'

    this.socket.addEventListener('open', this.onOpen)
    this.socket.addEventListener('message', this.onMessage)
    this.socket.addEventListener('error', this.onError)
    this.socket.addEventListener('close', this.onClose)

    if (this.checkOpenLoop < 0) {
      this.checkOpenLoop = window.setInterval(this.checkOpen.bind(this), 5e3)
    }
  }

  public close(): void {
    if (this.checkOpenLoop >= 0) {
      window.clearInterval(this.checkOpenLoop)
      this.checkOpenLoop = -1
    }

    if (this.socket == null) return

    console.debug('[socket] close:', this.socket.url)

    this.socket.close()
  }

  public async sendReq<
    TReq extends BinaryModelBase = BinaryModelBase,
    TRsp extends BinaryModelBase = BinaryModelBase
  >(cmdId: ApiSocketCmdId, data: TReq, rspDataCtor?: new () => TRsp): Promise<ApiSocketRspModel<TRsp>> {
    // Create request
    const apiReq = new ApiSocketReqModel().setCmdId((cmdId & 0xF0FF) | 0x0100)

    apiReq.setData(data)
    await this.injectAuthToken(apiReq)

    // Send packet & wait for response
    const apiRsp = new ApiSocketRspModel(rspDataCtor).setCmdId((cmdId & 0xF0FF) | 0x0200)
    try {
      apiRsp.deserialize(await this.send(apiReq).wait())
    } catch (err) {
      apiRsp.setRetcode(Retcode.RET_FAIL).setMsg(getErrorMessage(err))
    }

    return apiRsp
  }

  public async sendNotify<
    TNotify extends BinaryModelBase = BinaryModelBase
  >(cmdId: ApiSocketCmdId, data: TNotify): Promise<void> {
    // Create notify
    const apiReq = new ApiSocketReqModel().setCmdId((cmdId & 0xF0FF) | 0x0300)

    apiReq.setData(data)
    await this.injectAuthToken(apiReq)

    // Send notify
    this.send(apiReq)
  }

  public onNotify(callback: SocketNotifyCallback | null): void {
    this.notifyCallback = callback
  }

  /// Private ///

  private url: string
  private socket: WebSocket | null
  private checkOpenLoop: number

  private transactionQueue: SocketTransaction[]
  private notifyCallback: SocketNotifyCallback | null

  private tokenExpireTime: number | null

  private checkOpen(): void {
    if (this.socket == null) this.open()
  }

  private async injectAuthToken<T extends BinaryModelBase = BinaryModelBase>(apiReq: ApiSocketReqModel<T>): Promise<void> {
    const currentUser = firebaseAuth.currentUser
    if (currentUser == null) {
      this.tokenExpireTime = null
      return
    }

    const { tokenExpireTime } = this
    if (tokenExpireTime != null && Date.now().valueOf() < tokenExpireTime) return

    apiReq.setAuthToken(await currentUser.getIdToken())
    this.tokenExpireTime = new Date((await currentUser.getIdTokenResult()).expirationTime).getTime().valueOf()
  }

  private send(apiReq: ApiSocketReqModel): SocketTransaction {
    const { socket, transactionQueue } = this

    const isNotify = ((apiReq.getCmdId() >> 8) & 0xF) === 3

    // Create transaction
    const transaction = new SocketTransaction(apiReq.getCmdId())

    // Send packet
    if (socket == null || socket.readyState !== 1) {
      setTimeout(() => transaction.abort(new Error('socket closed')), 1)
    } else {
      if (isNotify) transaction.complete(Buffer.alloc(0))
      else transactionQueue.push(transaction)

      socket.send(apiReq.serialize())
    }

    return transaction
  }

  private popTransaction(cmdId: ApiSocketCmdId): SocketTransaction | null {
    const { transactionQueue } = this

    const transaction = transactionQueue.find(t => t.isRspCmd(cmdId))
    if (transaction == null) return null

    transactionQueue.splice(transactionQueue.indexOf(transaction), 1)
    return transaction
  }

  private onOpen(): void {
    if (this.socket == null) return

    console.debug('[socket] on open:', this.socket.url)

    // Clear token state
    this.tokenExpireTime = null
  }

  private onMessage(e: MessageEvent): void {
    if (this.socket == null) return

    const data = Buffer.from(<ArrayBuffer>e.data)

    // Get cmd id
    let cmdId = ApiSocketCmdId.CMD_NONE
    try {
      cmdId = new ApiSocketRspModel().deserialize(data).getCmdId()
    } catch (err) {
      console.warn('socket parse error:', err)
      return
    }

    // Handle message by cmd type
    switch ((cmdId >> 8) & 0xF) {
      case 1: // Req
        console.warn('recv client req on client, what are you doing?')
        break
      case 2: // Rsp
        this.popTransaction(cmdId)?.complete(data)
        break
      case 3: // CS Notify
        console.warn('recv client notify on client, what are you doing?')
        break
      case 4: // SC Notify
        this.notifyCallback?.(cmdId & 0xF0FF, data)
        break
      default:
        console.warn('invalid cmd type, cmdId:', cmdId.toString(16).padStart(4, '0'))
        break
    }
  }

  private onError(e: Event): void {
    console.warn('[socket] on error:', e)
  }

  private onClose(): void {
    const { socket, transactionQueue } = this

    // Close current socket if state is closing/closed
    if (socket != null && socket.readyState > 1) {
      console.debug('[socket] on close:', socket.url)

      socket.removeEventListener('open', this.onOpen)
      socket.removeEventListener('message', this.onMessage)
      socket.removeEventListener('error', this.onError)
      socket.removeEventListener('close', this.onClose)

      this.socket = null
    }

    // Clear transaction queue
    while (transactionQueue.length > 0) transactionQueue.pop()?.abort(new Error('socket closed'))
  }
}