import ModelBase from '@shared/models/base'

export interface GetPrivateChatRoomRspJSONModel {
  roomId: string
}

export default class GetPrivateChatRoomRspModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.roomId = 0n
  }

  public getRoomId(): bigint {
    return this.roomId
  }

  public setRoomId(roomId: bigint): this {
    this.roomId = roomId
    return this
  }

  public toJSONObject(): GetPrivateChatRoomRspJSONModel {
    const { roomId } = this

    return {
      roomId: roomId.toString()
    }
  }

  public fromJSONObject(json: GetPrivateChatRoomRspJSONModel): this {
    this.validate(json, 'roomId', 'string')

    const { roomId } = json

    this.roomId = BigInt(roomId || '0')

    return this
  }

  /// Private ///

  private roomId: bigint
}