import ModelBase from '@shared/models/base'

export interface GetAttendEventReqJSONModel {
  id: string
}

export default class GetAttendEventReqModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.id = ''
  }

  public getId(): string {
    return this.id
  }

  public setId(id: string): this {
    this.id = id
    return this
  }

  public toJSONObject(): GetAttendEventReqJSONModel {
    const { id } = this

    return {
      id
    }
  }

  public fromJSONObject(json: GetAttendEventReqJSONModel): this {
    this.validate(json, 'id', 'string')

    const { id } = json

    this.id = id

    return this
  }

  /// Private ///

  private id: string
}