export default class DateTime extends Date {
  /// Public ///

  public constructor(value?: number | string | Date) {
    if (value == null) super()
    else super(value)
  }

  // is Between
  public isInEventTime(start: Date, end: Date): boolean {
    return this.getTime() >= start.getTime() && this.getTime() <= end.getTime()
  }

  public isBefore(date: Date): boolean {
    return this.getTime() < date.getTime()
  }

  public isAfter(date: Date): boolean {
    return this.getTime() > date.getTime()
  }

  public addTimezoneOffset(): this {
    this.setTime(this.getTime() + (this.getTimezoneOffset() * 60e3))
    return this
  }

  public removeTimezoneOffset(): this {
    this.setTime(this.getTime() - (this.getTimezoneOffset() * 60e3))
    return this
  }

  public toFormatString(format: string): string {
    const { LongMonth, ShortMonth, ShortDay, LongDay } = DateTime

    return format.replace(/\w+/g, (fs) => {
      let value = 0
      switch (fs.slice(0, 1)) {
        case 'Y':
          value = this.getFullYear()
          break
        case 'M':
          if (fs.length >= 4) return LongMonth[this.getMonth()]
          if (fs.length >= 3) return ShortMonth[this.getMonth()]
          value = this.getMonth() + 1
          break
        case 'D':
          value = this.getDate()
          break
        case 'd': // New case for handling day of the week
          if (fs.length >= 4) return LongDay[this.getDay()]
          if (fs.length >= 3) return ShortDay[this.getDay()]
          throw new Error('invalid format specifier for day of the week: ' + fs)
        case 'h':
          value = this.getHours() % 12
          if (value <= 0) value = 12
          break
        case 'H':
          value = this.getHours()
          break
        case 'm':
          value = this.getMinutes()
          break
        case 's':
          value = this.getSeconds()
          break
        case 'a':
          return this.getHours() < 12 ? 'am' : 'pm'
        case 'A':
          return this.getHours() < 12 ? 'AM' : 'PM'
        default:
          throw new Error('invalid format specifier: ' + fs)
      }

      return `${value}`.padStart(fs.length, '0')
    })
  }

  public toDateString(): string {
    const year = this.getFullYear()
    const month = this.getMonth() + 1 // getMonth() returns a zero-based value (where zero indicates the first month of the year)
    const date = this.getDate()

    // Pad the month and date with leading zeros if they are less than 10
    const paddedMonth = month < 10 ? `0${month}` : month
    const paddedDate = date < 10 ? `0${date}` : date

    return `${year}-${paddedMonth}-${paddedDate}`
  }

  public toTimeString(): string {
    const hours = this.getHours()
    const minutes = this.getMinutes()
    const seconds = this.getSeconds()

    // Pad the hours, minutes, and seconds with leading zeros if they are less than 10
    const paddedHours = hours < 10 ? `0${hours}` : hours
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes
    const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`
  }

  /// Private ///

  private static LongMonth = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  private static ShortMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  private static LongDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  private static ShortDay = [
    'Sun', // Sunday
    'Mon', // Monday
    'Tue', // Tuesday
    'Wed', // Wednesday
    'Thu', // Thursday
    'Fri', // Friday
    'Sat', // Saturday
  ]
}
