import ModelBase from '@shared/models/base'
import { EventStatus } from '@shared/models/event'

export interface UpdateEventStatusReqJSONModel {
  eventId: string
  status: string
}

export default class UpdateEventStatusReqModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.eventId = ''
    this.status = EventStatus.STATUS_NONE
  }

  public getEventId(): string {
    return this.eventId
  }

  public getStatus(): EventStatus {
    return this.status
  }

  public setEventId(value: string): this {
    this.eventId = value
    return this
  }

  public setStatus(value: EventStatus): this {
    this.status = value
    return this
  }

  public toJSONObject(): UpdateEventStatusReqJSONModel {
    const {
      eventId,
      status
    } = this

    return {
      eventId,
      status: EventStatus[status]
    }
  }

  public fromJSONObject(json: UpdateEventStatusReqJSONModel): this {
    this.validate(json, 'eventId', 'string')
    this.validate(json, 'status', 'string')

    const {
      eventId,
      status
    } = json

    this.eventId = eventId
    this.status = EventStatus[<keyof typeof EventStatus>status] ?? EventStatus.STATUS_NONE

    return this
  }

  /// Private ///

  private eventId: string
  private status: EventStatus
}