import { faFaceSmile, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Group, TextInput } from '@mantine/core'
import { getHotkeyHandler } from '@mantine/hooks'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type ChatBoxProps = {
  disabled?: boolean
  onSubmit: (value: string) => void
}

export default function ChatBox({ disabled, onSubmit }: Readonly<ChatBoxProps>): ReactElement {
  const { t } = useTranslation('chat')
  const [value, setValue] = useState('')

  function isValidValue(): boolean {
    return value.length > 0 && /\S/.test(value)
  }

  function submitMessage(): void {
    onSubmit(value)
    setValue('')
  }

  return (
    <Group className='w-full' p={0}>
      <TextInput
        className='grow'
        classNames={{ label: 'font-sans', input: 'font-sans' }}
        value={value}
        disabled={disabled}
        onChange={(event) => setValue(event.currentTarget.value)}
        placeholder={t('chatPlaceholder')}
        rightSection={
          <ActionIcon onClick={() => null}>
            <FontAwesomeIcon icon={faFaceSmile} />
          </ActionIcon>
        }
        onKeyDown={isValidValue() ? getHotkeyHandler([['Enter', submitMessage]]) : undefined}
      />
      <ActionIcon
        onClick={submitMessage}
        variant='hover'
        size='lg'
        disabled={disabled || !isValidValue()}
      >
        <FontAwesomeIcon icon={faPaperPlane} />
      </ActionIcon>
    </Group>
  )
}