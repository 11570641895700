import { AlertType } from '@/components/alert'
import EventSearchGrid from '@/components/home/event-card-grid'
import DualNavLayout from '@/components/layout/dual-nav'
import Protected from '@/components/protected'
import { AuthState } from '@/constants/auth'
import Api from '@/utils/api'
import { getErrorMessage } from '@/utils/error'
import { Text } from '@mantine/core'
import { Retcode } from '@shared/models/api'
import SearchCatagoryReqModel from '@shared/models/api/request/search-catagory'
import GetEventListRspModel from '@shared/models/api/response/get-event-list'
import EventModel from '@shared/models/event'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const CategorySearchPage: React.FC = () => {
  const { category } = useParams()
  const [alert, setAlert] = useState<[AlertType, string] | null>(null)
  const [events, setEvents] = useState<EventModel[]>([])

  useEffect(() => {
    const fetchEvents = async () => {
      Api.sendRequest('/event/searchCategory', GetEventListRspModel, new SearchCatagoryReqModel().setInput(category!))
        .then((rsp) => {
          if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

          const data = rsp.getData()
          if (data == null) throw new Error('data == null')

          setEvents(data.getEventList())
        })
        .catch((err) => setAlert(['danger', getErrorMessage(err)]))
    }

    fetchEvents()
  }, [category])

  return (
    <Protected state={AuthState.LOGIN} redirect className='h-full'>
      <DualNavLayout className="my-5 px-1 sm:p-0 md:px-6 lg:px-26 xl:px-44">
        {/* <div className="container mx-0 my-5 px-1 md:px-6 lg:px-26 xl:px-44"> */}
        <Text className="text-3xl font-bold">{category?.toUpperCase()}</Text>
        <EventSearchGrid eventList={events} />
        {/* </div> */}
      </DualNavLayout>
    </Protected>
  )
}

export default CategorySearchPage
