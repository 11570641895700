import App from '@/app/'
import { firebaseConfig } from '@/configs/firebase'
import { ThemeProvider } from '@/context/theme'
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import { APIProvider } from '@vis.gl/react-google-maps'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { MetricType, ReportCallback } from 'web-vitals'
import './i18n'
import './styles/index.scss'

function onWebVitalsMetricEntry(metric: MetricType): void {
  switch (metric.name) {
    case 'CLS': // Cumulative Layout Shift
      console.debug('[metric]', 'cumulative layout shift:', `${metric.value.toFixed(2)}ms(${metric.rating})`)
      break
    case 'FCP': // First Contentful Paint
      console.debug('[metric]', 'first contentful paint:', `${metric.value.toFixed(2)}ms(${metric.rating})`)
      break
    case 'FID': // First Input Delay
      console.debug('[metric]', 'first input delay:', `${metric.value.toFixed(2)}ms(${metric.rating})`)
      break
    case 'INP': // Interaction to Next Paint
      console.debug('[metric]', 'interaction to next paint:', `${metric.value.toFixed(2)}ms(${metric.rating})`)
      break
    case 'LCP': // Largest Contentful Paint
      console.debug('[metric]', 'largest contentful paint:', `${metric.value.toFixed(2)}ms(${metric.rating})`)
      break
    case 'TTFB': // Time to First Byte
      console.debug('[metric]', 'time to first byte:', `${metric.value.toFixed(2)}ms(${metric.rating})`)
      break
    default:
      console.warn('[metric]', 'unknown metric:', metric)
      break
  }
}

async function reportWebVitals(onPerfEntry?: ReportCallback): Promise<void> {
  if (!onPerfEntry || !(onPerfEntry instanceof Function)) return

  const { onCLS, onFID, onFCP, onLCP, onTTFB } = await import('web-vitals')

  onCLS(onPerfEntry)
  onFID(onPerfEntry)
  onFCP(onPerfEntry)
  onLCP(onPerfEntry)
  onTTFB(onPerfEntry)
}

async function main(): Promise<void> {
  const domNode = document.getElementById('root')
  if (domNode == null) throw new Error('DOM node not found')

  // IOS input zoom fix
  if (navigator.userAgent.indexOf('iPhone') > -1) {
    document.querySelector('[name=viewport]')?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1')
  }

  const theme = localStorage.getItem('theme') as 'light' | 'dark'
  const root = ReactDOM.createRoot(domNode)

  root.render(
    <React.StrictMode>
      <Suspense fallback={null}>
        <MantineProvider defaultColorScheme={theme}>
          <ThemeProvider>
            <Notifications />
            <ModalsProvider>
              <APIProvider apiKey={firebaseConfig.apiKey} libraries={['places']}>
                <App />
              </APIProvider>
            </ModalsProvider>
          </ThemeProvider>
        </MantineProvider>
      </Suspense>
    </React.StrictMode>,
  )
  await reportWebVitals(onWebVitalsMetricEntry)
}

main().catch((err: Error) => (document.body.innerText = err.message))
