import ModelBase from '@shared/models/base'
import EventAttendAnswerModel, { EventAttendAnswerJSONModel } from '@shared/models/event/attend-answer'

export interface AttendEventReqJSONModel {
  eventId: string
  attendeeName: string
  phoneNumber: string
  answerData: EventAttendAnswerJSONModel[]
}

export default class AttendEventReqModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.eventId = ''
    this.attendeeName = ''
    this.phoneNumber = ''
    this.answerData = []
  }

  public getEventId(): string {
    return this.eventId
  }

  public getDisplayName(): string {
    return this.attendeeName
  }

  public getPhoneNumber(): string {
    return this.phoneNumber
  }

  public getAnswerData(): EventAttendAnswerJSONModel[] {
    return this.answerData
  }

  public setEventId(eventId: string): this {
    this.eventId = eventId
    return this
  }

  public setDisplayName(attendeeName: string): this {
    this.attendeeName = attendeeName
    return this
  }

  public setPhoneNumber(phoneNumber: string): this {
    this.phoneNumber = phoneNumber
    return this
  }

  public setAnswerData(answerData: EventAttendAnswerJSONModel[]): this {
    this.answerData = answerData
    return this
  }

  public toJSONObject(): AttendEventReqJSONModel {
    const { eventId, attendeeName, phoneNumber, answerData } = this

    return {
      eventId,
      attendeeName,
      phoneNumber,
      answerData
    }
  }

  public fromJSONObject(json: AttendEventReqJSONModel): this {
    this.validate(json, 'eventId', 'string')
    this.validate(json, 'attendeeName', 'string')
    this.validate(json, 'phoneNumber', 'string')
    this.validate(json, 'answerData', 'object')

    const { eventId, attendeeName, phoneNumber, answerData } = json

    this.eventId = eventId
    this.attendeeName = attendeeName
    this.phoneNumber = phoneNumber
    this.answerData = answerData

    return this
  }

  /// Private ///

  private eventId: string
  private attendeeName: string
  private phoneNumber: string
  private answerData: EventAttendAnswerJSONModel[]
}