import { defaultIconUrl } from '@/constants/common'
import DateTime from '@/utils/datetime'
import { fetchEventImageUrl } from '@/utils/firebase'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import { faHeart, faShare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Avatar, Badge, Card, Center, Grid, Group, Image, Stack, Text, TypographyStylesProvider, } from '@mantine/core'
import EventModel from '@shared/models/event'
import { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export type EventCardListProps = {
  eventList: EventModel[]
}

function ListItem({ data }: Readonly<{ data: EventModel }>) {
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null)
  const [organizerIcon, setOrganizerIcon] = useState<string>()

  const beginTime = new DateTime(data.getBeginTime())

  useEffect(() => {
    async function loadImages() {
      const imagePath = JSON.parse(data.getImages())[0]
      setThumbnailUrl(await fetchEventImageUrl(imagePath))
      setOrganizerIcon(await fetchEventImageUrl(data.getOrganizer().getThumbnailUrl()) ?? defaultIconUrl)
    }

    loadImages()
  }, [data])

  return (
    <div className="relative grow-0 shrink-0 w-60 shadow-sm overflow-hidden">
      <Card withBorder shadow="sm" className="relative bg-dark rounded-lg px-2" style={{ height: 365 }}>
        <Card.Section className="mb-2">
          <Link to={`/event/${data.getId()}`}>
            <Image src={thumbnailUrl} className="w-full h-[180px] rounded-md" />
          </Link>
        </Card.Section>

        <Badge
          className="absolute top-2 right-3 pointer-events-none"
          variant="gradient"
          gradient={{ from: 'yellow', to: 'red' }}
          radius="md"
          size="md"
        >
          <p className="text-xs font-sans">{beginTime.toFormatString('D MMM')}</p>
        </Badge>

        <Stack justify="flex-start" h={150}>
          <Text lineClamp={3} component="div">
            <TypographyStylesProvider>
              <Link to={`/event/${data.getId()}`}>
                <Text className="mt-3 mb-1 font-medium text-xl text-white" truncate="end" fw={500}>
                  {data.getName()}
                </Text>
              </Link>
              <Text
                className="font-sans text-gray-300/70 text-sm font-normal"
                dangerouslySetInnerHTML={{ __html: data.getDescription() }}
              ></Text>
            </TypographyStylesProvider>
          </Text>
        </Stack>

        <Group justify="space-between" className="mb-2 bottom-1 top-auto">
          <Center>
            <Avatar src={organizerIcon} size={24} radius="xl" mr="xs" />
            <Text fz="sm" inline className="font-sans text-white">
              {data.getOrganizer().getDisplayName()}
            </Text>
          </Center>
        </Group>

        <Group gap={8} mr={0}>
          <ActionIcon variant="subtle" color="gray">
            <FontAwesomeIcon icon={faHeart} size="lg" className="text-red-500" />
          </ActionIcon>
          <ActionIcon variant="subtle" color="gray">
            <FontAwesomeIcon icon={faBookmark} size="lg" className="text-yellow-500" />
          </ActionIcon>
          <ActionIcon variant="subtle" color="gray">
            <FontAwesomeIcon icon={faShare} size="lg" className="text-blue-500" />
          </ActionIcon>
        </Group>
      </Card>
    </div>
  )
}

export default function EventSearchGrid({ eventList }: Readonly<EventCardListProps>): ReactElement {
  return (
    <div className="mt-4">
      {/* <div className="font-bold text-xl mb-2">{heading}</div> */}
      <div className="max-sm:hidden">
        <Grid
          gutter="md" // Adjust spacing between items
        >
          {eventList.map((e) => (
            <Grid.Col
              span={{ xs: 6, md: 'auto' }} // Each card takes up 3 of 12 columns, adjust this value for responsiveness
              key={e.getId()}
            >
              <ListItem data={e} />
            </Grid.Col>
          ))}
        </Grid>
      </div>

      <div className="hidden max-sm:block">
        <Grid
          gutter="md" // Adjust spacing between items
        >
          {eventList.map((e) => (
            <Grid.Col
              span={{ xs: 12, md: 'auto' }} // Each card takes up 3 of 12 columns, adjust this value for responsiveness
              key={e.getId()}
            >
              <ListItem data={e} />
            </Grid.Col>
          ))}
        </Grid>
      </div>
    </div>
  )
}
