import ModelBase from '@shared/models/base'
import UserSimpleModel, { UserSimpleJSONModel } from '../user/simple'

export enum EventStatus {
  STATUS_NONE = 0,
  STATUS_DRAFT,
  STATUS_JOIN_OPEN,
  STATUS_JOIN_CLOSE,
  STATUS_ONGOING,
  STATUS_ENDED
}

export const DEFAULT_EVENT_NAME = 'Unknown Event'
export const DEFAULT_EVENT_DESC = 'No Description'
export const DEFAULT_EVENT_DATE = new Date(0)

export interface EventJSONModel {
  id: string
  organizer: UserSimpleJSONModel
  name: string
  description: string
  images: string
  placeId: string
  locationName: string
  locationLat: number
  locationLng: number
  tags: string
  category: string
  beginTime: number
  endTime: number
  price: number
  maxAttendees: number
  status: string
}

export default class EventModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.id = ''
    this.organizer = new UserSimpleModel()
    this.name = DEFAULT_EVENT_NAME
    this.description = DEFAULT_EVENT_DESC
    this.images = ''
    this.placeId = ''
    this.locationName = ''
    this.locationLat = 0
    this.locationLng = 0
    this.tags = ''
    this.category = ''
    this.beginTime = DEFAULT_EVENT_DATE
    this.endTime = DEFAULT_EVENT_DATE
    this.price = 0
    this.maxAttendees = 0
    this.status = EventStatus.STATUS_NONE
  }

  public getId(): string {
    return this.id
  }

  public getOrganizer(): UserSimpleModel {
    return this.organizer
  }

  public getName(): string {
    return this.name
  }

  public getDescription(): string {
    return this.description
  }

  public getImages(): string {
    return this.images
  }

  public getPlaceId(): string {
    return this.placeId
  }

  public getLocationName(): string {
    return this.locationName
  }

  public getLocationLat(): number {
    return this.locationLat
  }

  public getLocationLng(): number {
    return this.locationLng
  }

  public getTags(): string {
    return this.tags
  }

  public getCategory(): string {
    return this.category
  }

  public getMaxAttendees(): number {
    return this.maxAttendees
  }

  public getStatus(): EventStatus {
    return this.status
  }

  public getBeginTime(): Date {
    return this.beginTime
  }

  public getEndTime(): Date {
    return this.endTime
  }

  public getPrice(): number {
    return this.price
  }

  public setId(id: string): this {
    this.id = id
    return this
  }

  public setOrganizer(organizer: UserSimpleModel): this {
    this.organizer = organizer
    return this
  }

  public setName(name: string): this {
    this.name = name
    return this
  }

  public setDescription(description: string): this {
    this.description = description
    return this
  }

  public setImages(images: string): this {
    this.images = images
    return this
  }

  public setPlaceId(placeId: string): this {
    this.placeId = placeId
    return this
  }

  public setLocationName(locationName: string): this {
    this.locationName = locationName
    return this
  }

  public setLocationLat(locationLat: number): this {
    this.locationLat = locationLat
    return this
  }

  public setLocationLng(locationLng: number): this {
    this.locationLng = locationLng
    return this
  }

  public setTags(tags: string): this {
    this.tags = tags
    return this
  }

  public setCategory(category: string): this {
    this.category = category
    return this
  }

  public setMaxAttendees(maxAttendees: number): this {
    this.maxAttendees = maxAttendees
    return this
  }

  public setStatus(status: EventStatus): this {
    this.status = status
    return this
  }

  public setBeginTime(beginTime: Date): this {
    this.beginTime = beginTime
    return this
  }

  public setEndTime(endTime: Date): this {
    this.endTime = endTime
    return this
  }

  public setPrice(price: number): this {
    this.price = price
    return this
  }

  public toJSONObject(): EventJSONModel {
    const { id, organizer, name, description, images, placeId, locationName, locationLat, locationLng, tags, category, beginTime, endTime, price, maxAttendees, status } = this

    return {
      id,
      organizer: organizer.toJSONObject(),
      name,
      description,
      images,
      placeId,
      locationName,
      locationLat,
      locationLng,
      tags,
      category,
      beginTime: beginTime.getTime(),
      endTime: endTime.getTime(),
      price,
      maxAttendees,
      status: EventStatus[status],
    }
  }

  public fromJSONObject(json: EventJSONModel): this {
    this.validate(json, 'id', 'string')
    this.validate(json, 'organizer', 'object')
    this.validate(json, 'name', 'string')
    this.validate(json, 'images', 'string')
    this.validate(json, 'description', 'string')
    this.validate(json, 'placeId', 'string')
    this.validate(json, 'locationName', 'string')
    this.validate(json, 'locationLat', 'number')
    this.validate(json, 'locationLng', 'number')
    this.validate(json, 'tags', 'string')
    this.validate(json, 'category', 'string')
    this.validate(json, 'beginTime', 'number')
    this.validate(json, 'endTime', 'number')
    this.validate(json, 'price', 'number')
    this.validate(json, 'maxAttendees', 'number')
    this.validate(json, 'status', 'string')

    const { id, organizer, name, description, images, placeId, locationName, locationLat, locationLng, tags, category, beginTime, endTime, price, maxAttendees, status } = json

    this.id = id
    this.organizer.fromJSONObject(organizer)
    this.name = name
    this.description = description
    this.images = images
    this.placeId = placeId
    this.locationName = locationName
    this.locationLat = locationLat
    this.locationLng = locationLng
    this.tags = tags
    this.category = category
    this.beginTime = new Date(beginTime)
    this.endTime = new Date(endTime)
    this.price = price
    this.maxAttendees = maxAttendees
    this.status = EventStatus[<keyof typeof EventStatus>status] ?? EventStatus.STATUS_NONE

    return this
  }

  /// Private ///

  private id: string
  private organizer: UserSimpleModel
  private name: string
  private description: string
  private images: string
  private placeId: string
  private locationName: string
  private locationLat: number
  private locationLng: number
  private tags: string
  private category: string
  private beginTime: Date
  private endTime: Date
  private price: number
  private maxAttendees: number
  private status: EventStatus
}
