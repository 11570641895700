import Alert, { AlertType } from '@/components/alert'
import HomeCarousel from '@/components/carousel/home-carousel'
import EventCardList from '@/components/home/event-card-list'
import EventCategories from '@/components/home/event-categories'
import DualNavLayout from '@/components/layout/dual-nav'
import Protected from '@/components/protected'
import { AuthState } from '@/constants/auth'
import { Retcode } from '@/models/api'
import Api from '@/utils/api'
import Auth from '@/utils/auth'
import { getErrorMessage } from '@/utils/error'
import GetEventListRspModel from '@shared/models/api/response/get-event-list'
import EventModel from '@shared/models/event'
import UserModel from '@shared/models/user'
import { ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export default function HomePage(): ReactElement {
  const { t } = useTranslation('common')
  const [alert, setAlert] = useState<[AlertType, string] | null>(null)
  const [currUser, setCurrUser] = useState<UserModel | null>(null)
  const [popularEventList, setPopularEventList] = useState<EventModel[]>([])
  const [upcomingEventList, setUpcomingEventList] = useState<EventModel[]>([])

  useEffect(() => {
    Auth.getAuthState().then(() => {
      setCurrUser(Auth.currentUser)

      Api.sendRequest('/event/getEventList', GetEventListRspModel)
        .then((rsp) => {
          if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

          const data = rsp.getData()
          if (data == null) throw new Error('data == null')

          setPopularEventList(data.getEventList())
          setUpcomingEventList(data.getEventList())
        })
        .catch((err) => setAlert(['danger', getErrorMessage(err)]))
    })
  }, [])

  return (
    <Protected state={AuthState.LOGIN} redirect className="h-full">
      <DualNavLayout>
        {alert == null ? <div /> : <Alert type={alert[0]} message={alert[1]} />}
        <h1 className="text-3xl font-sans">
          <Trans i18nKey="home.welcomeBack" t={t}>
            ?{{name: currUser?.getDisplayName() ?? 'Guest'}}
          </Trans>
        </h1>
        <br />
        <HomeCarousel />
        <EventCategories />
        <EventCardList heading={t('home.popularEvent')} eventList={popularEventList} />
        <EventCardList heading={t('home.upcomingEvent')} eventList={upcomingEventList} />
      </DualNavLayout>
    </Protected>
  )
}
