import ModelBase from '@shared/models/base'
import { EventAttendeeStatus } from '@shared/models/event/attendee'

export interface GetAttendStatusRspJSONModel {
  status: string
}

export default class GetAttendStatusRspModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.status = EventAttendeeStatus.STATUS_NONE
  }

  public getStatus(): EventAttendeeStatus {
    return this.status
  }

  public setStatus(status: EventAttendeeStatus): this {
    this.status = status
    return this
  }

  public toJSONObject(): GetAttendStatusRspJSONModel {
    const { status } = this

    return {
      status: EventAttendeeStatus[status]
    }
  }

  public fromJSONObject(json: GetAttendStatusRspJSONModel): this {
    this.validate(json, 'status', 'string')

    const { status } = json

    this.status = EventAttendeeStatus[<keyof typeof EventAttendeeStatus>status] ?? EventAttendeeStatus.STATUS_NONE

    return this
  }

  /// Private ///

  private status: EventAttendeeStatus
}