import ModelBase from '@shared/models/base'

export interface UserJSONModel {
  id: string
  thumbnailUrl: string
  displayName: string
  phoneNumber: string
  createTime: number
}

export default class UserModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.id = ''
    this.thumbnailUrl = ''
    this.displayName = ''
    this.phoneNumber = ''
    this.createTime = new Date()
  }

  public getId(): string {
    return this.id
  }

  public getThumbnailUrl(): string {
    return this.thumbnailUrl
  }

  public getDisplayName(): string {
    return this.displayName
  }

  public getPhoneNumber(): string {
    return this.phoneNumber
  }
  
  public getCreateTime(): Date {
    return new Date(this.createTime)
  }

  public setId(id: string): this {
    this.id = id
    return this
  }

  public setThumbnailUrl(thumbnailUrl: string): this {
    this.thumbnailUrl = thumbnailUrl
    return this
  }

  public setDisplayName(displayName: string): this {
    this.displayName = displayName
    return this
  }

  public setPhoneNumber(phoneNumber: string): this {
    this.phoneNumber = phoneNumber
    return this
  }

  public setCreateTime(createTime: Date): this {
    this.createTime = new Date(createTime)
    return this
  }

  public toJSONObject(): UserJSONModel {
    const { id, thumbnailUrl, displayName, phoneNumber, createTime } = this

    return {
      id,
      thumbnailUrl,
      displayName,
      phoneNumber,
      createTime: createTime.getTime()
    }
  }

  public fromJSONObject(json: UserJSONModel) {
    this.validate(json, 'id', 'string')
    this.validate(json, 'thumbnailUrl', 'string')
    this.validate(json, 'displayName', 'string')
    this.validate(json, 'phoneNumber', 'string')
    this.validate(json, 'createTime', 'number')

    const { id, thumbnailUrl, displayName, phoneNumber, createTime } = json

    this.id = id
    this.thumbnailUrl = thumbnailUrl
    this.displayName = displayName
    this.phoneNumber = phoneNumber
    this.createTime = new Date(createTime)

    return this
  }

  /// Private ///

  private id: string
  private thumbnailUrl: string
  private displayName: string
  private phoneNumber: string
  private createTime: Date
}