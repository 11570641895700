import { createFormContext } from '@mantine/form';
import { CountryCode } from 'libphonenumber-js';

export interface AttendEventFormData {
  attendeeName: string
  attendeePhone: string
  countryCode: CountryCode
}

// You can give context variables any name
export const [AttendEventFormProvider, useAttendEventFormContext, useAttendEventForm] =
  createFormContext<AttendEventFormData>();