import ModelBase from '@shared/models/base'
import EventModel, { EventJSONModel } from '@shared/models/event'

export interface GetEventListRspJSONModel {
  eventList: EventJSONModel[]
}

export default class GetEventListRspModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.eventList = []
  }

  public getEventList(): EventModel[] {
    return [...this.eventList]
  }

  public setEventList(eventList: EventModel[]): this {
    this.eventList = eventList
    return this
  }

  public toJSONObject(): GetEventListRspJSONModel {
    const { eventList } = this

    return {
      eventList: eventList.map(e => e.toJSONObject())
    }
  }

  public fromJSONObject(json: GetEventListRspJSONModel): this {
    this.validate(json, 'eventList', 'array')

    const { eventList } = json

    if (eventList.find(v => typeof v !== 'object') != null) throw new Error(`invalid array value type 'eventList'`)

    this.eventList = eventList.map(e => new EventModel().fromJSONObject(e))

    return this
  }

  /// Private ///

  private eventList: EventModel[]
}