import logo from '@/assets/images/logo.png'
import Protected from '@/components/protected'
import { AuthState } from '@/constants/auth'
import { useTheme } from '@/context/theme'
import i18n from '@/i18n'
import Api, { isDevServerOnline } from '@/utils/api'
import Auth from '@/utils/auth'
import { fetchEventImageUrl } from '@/utils/firebase'
import {
  IconDefinition,
  faAdd,
  faCog,
  faComment,
  faHome,
  faSignOut,
  faUser,
  faWrench,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Avatar, Button, Combobox, Menu, Switch, TextInput, useCombobox } from '@mantine/core'
import SearchEventReqModel from '@shared/models/api/request/search-event'
import GetEventListRspModel from '@shared/models/api/response/get-event-list'
import EventModel from '@shared/models/event'
import { use } from 'i18next'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

interface NavItemEntry {
  icon: IconDefinition
  name: string
  href: string
  showCond?: () => boolean
  isCurrPage?: (path: string) => boolean
}

const navigationItems: NavItemEntry[] = [
  { icon: faHome, name: 'topNav.home', href: '/' },
  {
    icon: faComment,
    name: 'topNav.chat',
    href: '/chat',
    isCurrPage(path) {
      return path.startsWith('/chat/')
    },
  },
  {
    icon: faWrench,
    name: 'topNav.debug',
    href: '/debug',
    showCond() {
      return isDevServerOnline
    },
  },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function TopNav(): ReactElement {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { theme, toggleTheme } = useTheme()
  const { pathname } = useLocation()
  const [iconUrl, setIconUrl] = useState<string>('')
  const [checked, setChecked] = useState(false);

  async function onLogout(): Promise<void> {
    try {
      await Auth.logout()
    } catch (err) {
      // TODO: Show error message
    }
  }

  const [predictInput, setPredictInput] = useState('')
  const [eventList, setEventList] = useState<EventModel[]>([])
  const combobox = useCombobox()

  // Fetch icon url
  useEffect(() => {
    const thumbnailUrl = Auth.currentUser?.getThumbnailUrl()
    if (thumbnailUrl != null) fetchEventImageUrl(thumbnailUrl).then((url) => url && setIconUrl(url))
  }, [])

  useEffect(() => {
    if (predictInput && predictInput.length > 0) {
      // Fetch events
      Api.sendRequest('/event/searchEvent', GetEventListRspModel, new SearchEventReqModel().setInput(predictInput))
        .then((rsp) => {
          if (rsp.getRetcode() !== 0) throw new Error(rsp.getMsg())

          const data = rsp.getData()
          if (data == null) throw new Error('data == null')

          setEventList(data.getEventList())
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [predictInput])

  useEffect(() => {
    if (checked) {
      i18n.changeLanguage('zh-TW')
    } else {
      i18n.changeLanguage('en-US')
    }
  }, [checked])

  return (
    <div className="bg-primary-700 hidden sm:block w-full mx-auto px-2 sm:px-6 lg:px-8">
      <div className="relative flex h-16 items-center justify-between">
        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <Link to="/" className="flex-shrink-0 flex items-center">
            <div className="flex flex-shrink-0 items-center">
              <img className="h-8 w-auto" src={logo} alt="Moment" />
            </div>
          </Link>
          <div className="hidden sm:ml-6 sm:block">
            <Protected state={AuthState.LOGIN}>
              <div className="flex space-x-4">
                {navigationItems
                  .filter((item) => item.showCond == null || item.showCond())
                  .map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.isCurrPage?.(pathname) || pathname === item.href
                          ? 'bg-primary-800 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'rounded-md px-3 py-2 text-base font-medium font-sans',
                      )}
                      aria-current={item.isCurrPage?.(pathname) || pathname === item.href ? 'page' : undefined}
                    >
                      <FontAwesomeIcon className="pr-2" icon={item.icon} />
                      {t(item.name)}
                    </Link>
                  ))}
              </div>
            </Protected>
          </div>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <Protected state={AuthState.LOGIN}>
            <Combobox
              store={combobox}
              withinPortal={false}
              transitionProps={{ duration: 200, transition: 'pop' }}
              onOptionSubmit={(v) => navigate(`/event/${v}`)}
            >
              <Combobox.Target>
                <TextInput
                  className="mr-3 w-72"
                  radius="xl"
                  placeholder={t('topNav.search')}
                  onChange={(e) => setPredictInput(e.currentTarget.value)}
                  value={predictInput}
                  onClick={() => combobox.openDropdown()}
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  classNames={{ label: 'font-sans', input: 'font-sans' }}
                />
              </Combobox.Target>

              {eventList.length > 0 && (
                <Combobox.Dropdown>
                  <Combobox.Options>
                    {eventList.map((event) => (
                      <Combobox.Option value={event.getId()} key={event.getId()}>
                        {event.getName()}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Combobox.Dropdown>
              )}
            </Combobox>
          </Protected>
          <Protected state={AuthState.LOGIN}>
            <Link to={'/event/create'}>
              <ActionIcon
                className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                variant="filled"
                radius="xl"
                size="lg"
                aria-label="Settings"
              >
                <span className="absolute -inset-1.5" />
                <FontAwesomeIcon icon={faAdd} size="lg" />
              </ActionIcon>
            </Link>
          </Protected>

          {/* Profile dropdown */}
          <Menu>
            <div className="relative ml-3">
              <Menu.Target>
                <Button className="relative flex rounded-full p-0 bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">Open user menu</span>
                  {iconUrl ? (
                    <Avatar src={iconUrl} size={'md'} radius={40} />
                  ) : (
                    <Avatar size={'md'} radius={40}>
                      <FontAwesomeIcon icon={faUser} size={'xl'} />
                    </Avatar>
                  )}
                </Button>
              </Menu.Target>

              {/* Profile dropdown menu */}
              <Menu.Dropdown className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-dark py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item className="text-[16px]">
                  <div className="flex items-center justify-between">
                    <span className="font-sans text-base text-gray-900 dark:text-gray-200">{t('topNav.lang')}</span>
                    <Switch checked={checked} onChange={(event) => setChecked(event.currentTarget.checked)}>
                      <span
                        className={`${
                          theme === 'dark' ? 'translate-x-6' : 'translate-x-1'
                        } inline-block h-4 w-4 transform rounded-full bg-primary-500 transition`}
                      />
                    </Switch>
                  </div>
                </Menu.Item>
                <Menu.Item className='text-[16px]'>
                  <div className='flex items-center justify-between'>
                    <span className="font-sans text-base text-gray-900 dark:text-gray-200">{t('topNav.darkmode')}</span>
                    <Switch
                      checked={theme === 'dark'}
                      onChange={toggleTheme}
                    >
                      <span
                        className={`${theme === 'dark' ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-primary-500 transition`}
                      />
                    </Switch>
                  </div>
                </Menu.Item>
                <Protected state={AuthState.LOGIN}>
                  <Menu.Item
                    className={
                      'font-sans text-base text-gray-700 dark:text-white dark:hover:text-primary-300 text-[16px]'
                    }
                    onClick={() => navigate('/profile')}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faUser} />
                    {t('topNav.profile')}
                  </Menu.Item>
                </Protected>
                {/* <Protected state={AuthState.LOGIN}>
                  <Menu.Item
                    className={'font-sans text-base text-gray-700 dark:text-white dark:hover:text-primary-300 px-8 text-[16px]'}
                    onClick={() => navigate('/settings')}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faCog} />
                    {t('topNav.settings')}
                  </Menu.Item>
                </Protected> */}
                <Protected state={AuthState.SETUP | AuthState.LOGIN}>
                  <Menu.Item
                    className={
                      'font-sans text-base text-gray-700 dark:text-white dark:hover:text-primary-300 text-[16px]'
                    }
                    onClick={onLogout}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faSignOut} />
                    {t('topNav.logout')}
                  </Menu.Item>
                </Protected>
              </Menu.Dropdown>
            </div>
          </Menu>
        </div>
      </div>
    </div>
  )
}
