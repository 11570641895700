import ModelBase from '@shared/models/base'

export interface SearchEventReqJSONModel {
  input: string
}

export default class SearchEventReqModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.input = ''
  }

  public getInput(): string {
    return this.input
  }

  public setInput(input: string): this {
    this.input = input
    return this
  }

  public toJSONObject(): SearchEventReqJSONModel {
    const { input } = this

    return {
      input
    }
  }

  public fromJSONObject(json: SearchEventReqJSONModel): this {
    this.validate(json, 'input', 'string')

    const { input } = json

    this.input = input

    return this
  }

  /// Private ///

  private input: string
}