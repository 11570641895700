import { createFormContext } from '@mantine/form'

export interface EditEventFormData {
  eventName: string
  eventStartTime: Date | null
  eventEndTime: Date | null
  eventLocation: string
  eventPrice: number
  eventPlaceId: string
  eventTags: string[]
  eventCategory: string
}

export const [EditEventFormProvider, useEditEventFormContext, useEditEventForm] = createFormContext<EditEventFormData>()