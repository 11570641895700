import ModelBase from '@shared/models/base'
import EventAttendeeModel, { EventAttendeeJSONModel } from '@shared/models/event/attendee'

export interface GetAttendeesRspJSONModel {
  attendeeList: EventAttendeeJSONModel[]
}

export default class GetAttendeesRspModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.attendeeList = []
  }

  public getAttendeeList(): EventAttendeeModel[] {
    return [...this.attendeeList]
  }

  public setAttendeeList(attendeeList: EventAttendeeModel[]): this {
    this.attendeeList = attendeeList
    return this
  }

  public toJSONObject(): GetAttendeesRspJSONModel {
    const { attendeeList } = this

    return {
      attendeeList: attendeeList.map(e => e.toJSONObject())
    }
  }

  public fromJSONObject(json: GetAttendeesRspJSONModel): this {
    this.validate(json, 'attendeeList', 'array')

    const { attendeeList } = json
    if (attendeeList.find(v => typeof v !== 'object') != null) throw new Error(`invalid array value type 'attendeeList'`)

    this.attendeeList = attendeeList.map(e => new EventAttendeeModel().fromJSONObject(e))

    return this
  }

  /// Private ///

  private attendeeList: EventAttendeeModel[]
}