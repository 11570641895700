import { AuthState } from '@/constants/auth'
import { AuthContext } from '@/context/auth'
import { ForwardedRef, ReactElement, forwardRef, useContext } from 'react'
import { Location, Navigate, useLocation } from 'react-router-dom'

export interface RouteState {
  redirect?: string
}

export type ProtectedProps = {
  state: AuthState
  not?: boolean
  redirect?: boolean
  className?: string
  children: ReactElement | ReactElement[]
}

const Protected = forwardRef(({ state, not, redirect, className, children }: Readonly<ProtectedProps>, ref: ForwardedRef<null>): ReactElement | ReactElement[] => {
  const { pathname, state: routeState } = useLocation() as Location<RouteState>
  const authState = useContext(AuthContext).authState | (process.env.NODE_ENV === 'development' ? AuthState.DEBUG : 0)

  if (not ? !(authState & state) : (authState & state)) {
    return <div className={className} ref={ref}>{children}</div>
  }

  let target: string
  if (not) {
    target = (state & AuthState.LOGIN) ? '/login' : '/'
  } else {
    switch (authState & (~AuthState.DEBUG)) {
      case AuthState.ERROR:
        target = '/error'
        break
      case AuthState.CHECKING:
        target = '/splash'
        break
      case AuthState.VERIFY:
        target = '/verify'
        break
      case AuthState.SETUP:
        target = '/profile/setup'
        break
      case AuthState.LOGIN:
        target = '/'
        break
      case AuthState.LOGOUT:
        target = '/login'
        break
      default:
        console.warn('invalid protected state', state, not, authState)
        return <div className={className} ref={ref} />
    }

    // Return to previous page after error/loading
    if (redirect && (state === AuthState.ERROR || state === AuthState.CHECKING)) target = routeState?.redirect ?? '/'
  }

  return redirect ? <Navigate replace to={target} state={{ redirect: pathname }} /> : <div className={className} ref={ref} />
})

export default Protected