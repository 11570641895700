import Api from '@/utils/api'
import { getErrorMessage } from '@/utils/error'
import { useMounted } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { Retcode } from '@shared/models/api'
import GetAttendEventReqModel from '@shared/models/api/request/get-attend'
import GetAttendeesRspModel from '@shared/models/api/response/get-attendees'
import EventAttendeeModel, { EventAttendeeStatus } from '@shared/models/event/attendee'
import sortBy from 'lodash/sortBy'
import { DataTableSortStatus } from 'mantine-datatable'
import { useEffect, useRef, useState } from 'react'

export function useAttendeListAsync({
  eventId,
  sortStatus,
}: {
  eventId: string
  sortStatus?: DataTableSortStatus<EventAttendeeModel>
}) {
  const isMounted = useMounted()
  const [records, setRecords] = useState<EventAttendeeModel[]>([])
  const [loading, setLoading] = useState(true)
  const timeoutRef = useRef<number>(-1)

  useEffect(() => {
    window.clearTimeout(timeoutRef.current)
    if (isMounted) {
      ; (async () => {
        setLoading(true)
        timeoutRef.current = window.setTimeout(() => {
          if (isMounted) {
            Api.sendRequest('/event/getAttendList', GetAttendeesRspModel, new GetAttendEventReqModel().setId(eventId))
              .then((rsp) => {
                if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

                const data = rsp.getData()
                if (data == null) throw new Error('data == null')
                let newRecords = data
                  .getAttendeeList()
                  .filter(
                    (attendee) =>
                      attendee.getEvent().getId() === eventId && attendee.getStatus() !== EventAttendeeStatus.STATUS_NONE,
                  )
                console.log('sortStatus async', sortStatus)

                if (sortStatus) {
                  newRecords = sortBy(newRecords, sortStatus.columnAccessor)
                  if (sortStatus.direction === 'desc') newRecords.reverse()
                }
                setRecords(newRecords)
                setLoading(false)
              })
              .catch((err) => {
                console.error(getErrorMessage(err))
                notifications.show({ title: 'Error', message: getErrorMessage(err), color: 'red' })
              })
          }
        }, 500)
      })()
    }
  }, [eventId, isMounted, sortStatus])

  return { records, loading }
}
