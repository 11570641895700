import { firebaseAuth } from '@/configs/firebase'
import { apiUrl, devApiUrl } from '@/configs/url'
import { ApiReqModel, ApiRspModel, Retcode } from '@/models/api'
import { getErrorMessage } from '@/utils/error'
import GetCsrfTokenRspModel from '@shared/models/api/response/get-csrf-token'
import ModelBase from '@shared/models/base'

export let isDevServerOnline = false // NOSONAR
export let lastDevServerPing = 0 // NOSONAR

async function injectAuthToken<T extends ModelBase = ModelBase>(apiReq: ApiReqModel<T>): Promise<void> {
  const authToken = await firebaseAuth.currentUser?.getIdToken()
  if (authToken != null) apiReq.setAuthToken(authToken)
}

async function pingDevServer(): Promise<void> {
  try {
    if (process.env.NODE_ENV !== 'development') return
    if (Date.now() - lastDevServerPing < 15e3) return

    const timeoutCtrl = new AbortController()
    const timeoutTimer = setTimeout(() => timeoutCtrl.abort(), 100)
    const rsp = await fetch(`${devApiUrl}/debug/ping`, { signal: timeoutCtrl.signal }).then(rsp => rsp.json())

    isDevServerOnline = rsp?.retcode === 0
    lastDevServerPing = Date.now()

    clearTimeout(timeoutTimer)
  } catch (err) {
    isDevServerOnline = false
  }
}

async function sendRequest<
  TReq extends ModelBase = ModelBase,
  TRsp extends ModelBase = ModelBase
>(url: string, apiReq: ApiReqModel<TReq>, rspDataCtor?: new () => TRsp): Promise<ApiRspModel<TRsp>> {
  const apiRsp = new ApiRspModel(rspDataCtor)

  try {
    await pingDevServer()

    const rsp = await fetch(`${isDevServerOnline ? devApiUrl : apiUrl}${url}`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: apiReq.toJSONString()
    })

    apiRsp.fromJSONObject(await rsp.json())
  } catch (err) {
    console.error(err)
    apiRsp.setRetcode(Retcode.RET_FAIL).setMsg(`Network error: ${getErrorMessage(err)}`)
  }

  return apiRsp
}

export default class Api {
  static async sendRequest<
    TReq extends ModelBase = ModelBase,
    TRsp extends ModelBase = ModelBase
  >(url: string, rspDataCtor?: new () => TRsp, reqData?: TReq): Promise<ApiRspModel<TRsp>> {
    const apiReq = new ApiReqModel()

    apiReq.setData(reqData ?? null)
    await injectAuthToken(apiReq)

    return sendRequest(url, apiReq, rspDataCtor)
  }

  static async sendCsrfRequest<
    TReq extends ModelBase = ModelBase,
    TRsp extends ModelBase = ModelBase
  >(url: string, rspDataCtor?: new () => TRsp, reqData?: TReq): Promise<ApiRspModel<TRsp>> {
    const rsp = await this.sendRequest<TReq, GetCsrfTokenRspModel>('/csrf/getCsrfToken', GetCsrfTokenRspModel)

    if (rsp.getRetcode() !== Retcode.RET_SUCC) {
      return new ApiRspModel(rspDataCtor).setRetcode(rsp.getRetcode()).setMsg(rsp.getMsg())
    }

    const apiReq = new ApiReqModel()

    apiReq.setCsrfToken(rsp.getData()?.getCsrfToken() ?? null)
    apiReq.setData(reqData ?? null)
    await injectAuthToken(apiReq)

    return sendRequest(url, apiReq, rspDataCtor)
  }
}
