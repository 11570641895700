import Alert, { AlertType } from '@/components/alert'
import DualNavLayout from '@/components/layout/dual-nav'
import Protected from '@/components/protected'
import { storage } from '@/configs/firebase'
import { AuthState } from '@/constants/auth'
import { ProfileSetupFormData, useProfileSetupForm } from '@/context/profile-setup-form'
import { Retcode } from '@/models/api'
import Api from '@/utils/api'
import Auth from '@/utils/auth'
import { getImageDimensions } from '@/utils/common'
import { getErrorMessage } from '@/utils/error'
import { fetchEventImageUrl } from '@/utils/firebase'
import { faChevronRight, faCloudDownload, faDownload, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CloseButton, Group, Image as Mimage, NumberInput, Select, Text, TextInput, rem } from '@mantine/core'
import { Dropzone, FileWithPath, MIME_TYPES } from '@mantine/dropzone'
import CreateUserReqModel from '@shared/models/api/request/create-user'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { getCountries, getCountryCallingCode, isValidPhoneNumber } from 'libphonenumber-js'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

export default function ProfileSetupPage(): ReactElement {
  const { t } = useTranslation('auth')
  const navigate = useNavigate()
  const [alert, setAlert] = useState<[AlertType, string] | null>(null)
  const [disable, setDisable] = useState(false)
  const [file, setFile] = useState<FileWithPath | null>(null)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const openRef = useRef<() => void>(null)
  const [country, setCountry] = useState('HK') // Default to US

  const countryCodes = getCountries().map((countryCode) => ({
    value: countryCode,
    label: `${countryCode} +${getCountryCallingCode(countryCode)}`,
  }))

  const removeImage = () => {
    setFile(null)
    setImageUrl(null)
  }

  // Fetch image urls
  useEffect(() => {
    const loadImage = async () => {
      if (!file) return console.log('No image file')

      const url = await fetchEventImageUrl(file.path!)
      setImageUrl(url)
    }

    loadImage()
  }, [file])

  const preview = imageUrl ? (
    <div className="relative w-48 h-48">
      <Mimage src={imageUrl} radius="md" fit="contain" style={{ width: '100%', height: '100%' }} mt={'md'} />
      <CloseButton
        className="absolute top-0 right-0 rounded-full text-white bg-red-500 hover:bg-red-500/80"
        icon={<FontAwesomeIcon icon={faX} />}
        onClick={removeImage}
      />
    </div>
  ) : null

  const form = useProfileSetupForm({
    initialValues: {
      displayName: '',
      phoneNumber: '',
      countryCode: 'HK',
    },

    validate: {
      displayName: (value) => (value.trim().length <= 0 ? 'Name is required' : null),
      phoneNumber: (value, values) => {
        const fullNumber = `+${getCountryCallingCode(values.countryCode)}${value}`
        return isValidPhoneNumber(fullNumber) ? null : 'Invalid phone number'
      },
    },
  })

  async function addFile(selectedFile: FileWithPath) {
    const path = `users_icon/${uuidv4() + selectedFile.name}`

    try {
      // Get image dimensions using the getImageDimensions function
      const { width, height } = await getImageDimensions(selectedFile)

      // Check if image dimensions are less than 1024x1024
      if (width <= 1024 && height <= 1024) {
        // Create a storage reference
        const storageRef = ref(storage, path)

        // Upload the file
        const uploadTask = uploadBytesResumable(storageRef, selectedFile)

        // Optional: Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Get upload progress
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log(`Upload is ${progress}% done`)
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                setLoading(false)
                break
              case 'running':
                console.log('Upload is running')
                setLoading(true)
                break
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error(error)
            setLoading(false)
          },
          () => {
            // Handle successful uploads on complete
            console.log('Upload successful')
            // Adding the selected files to the component state
            setFile({ ...selectedFile, path })
            setLoading(false)
          },
        )
      } else {
        console.log('Image dimensions are less than 1024x1024')
        setAlert(['danger', 'Image dimensions are less than 1024x1024'])
      }
    } catch (error) {
      console.error('Error reading image dimensions:', error)
      setAlert(['danger', 'Error reading image dimensions'])
    }
  }

  async function onSubmit(value: ProfileSetupFormData) {
    if (file == null || file.path == null) {
      setAlert(['danger', 'Profile picture is required'])
      return
    }

    try {
      setDisable(true)

      // fullNumber is the full phone number with country code
      const fullNumber = `+${getCountryCallingCode(value.countryCode)}${value.phoneNumber}`
      const rsp = await Api.sendCsrfRequest(
        '/user/createUser',
        undefined,
        new CreateUserReqModel().fromJSONObject({
          displayName: value.displayName,
          phoneNumber: fullNumber,
          thumbnailUrl: file.path,
        })
      )
      if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

      // Update user data
      await Auth.getAuthState(false)

      // Redirect to home page
      navigate('/')
    } catch (err) {
      // Show error message
      setAlert(['danger', getErrorMessage(err)])
      setDisable(false)
    }
  }

  return (
    <Protected state={AuthState.SETUP} redirect className='h-full'>
      <DualNavLayout className="container mx-auto my-5 px-12 md:px-24 lg:px-32 xl:px-44">
        <h1 className="text-3xl font-bold mb-4">Setup your profile</h1>
        <form onSubmit={form.onSubmit((v) => onSubmit(v) as unknown)}>
          {/* User icon upload */}
          <div className="mb-6">
            <Dropzone
              openRef={openRef}
              onDrop={(files) => addFile(files[0])}
              style={{ borderWidth: 2, paddingBottom: 50 }}
              radius="md"
              accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.gif, MIME_TYPES.svg, MIME_TYPES.webp]}
              maxSize={30 * 1024 ** 2}
              maxFiles={1}
              disabled={disable}
              loading={loading}
            >
              <div style={{ pointerEvents: 'none' }}>
                <Group justify="center">
                  <Dropzone.Accept>
                    <FontAwesomeIcon icon={faDownload} size="lg" />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <FontAwesomeIcon icon={faX} size="lg" />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <FontAwesomeIcon icon={faCloudDownload} size="lg" />
                  </Dropzone.Idle>
                </Group>

                <Text ta="center" fw={700} fz="lg" mt="xl">
                  <Dropzone.Accept>{t('upload.accept')}</Dropzone.Accept>
                  <Dropzone.Reject>{t('upload.reject')}</Dropzone.Reject>
                  <Dropzone.Idle>{t('upload.idle')}</Dropzone.Idle>
                </Text>
                <Text ta="center" fz="sm" mt="xs" c="dimmed">
                  <Trans i18nKey="upload.text" t={t}>
                    ?<i>?</i>?
                  </Trans>
                </Text>
              </div>
            </Dropzone>

            {/* <SimpleGrid cols={{ base: 1, sm: 4 }} mt={preview ? 'xl' : 0}> */}
            {preview}
            {/* </SimpleGrid> */}
          </div>

          {/* Display name input */}
          <>
            <TextInput
              className="mb-6"
              label="Display Name"
              placeholder="Your Name"
              disabled={disable}
              required
              {...form.getInputProps('displayName')}
            />
          </>

          {/* Phone number input */}
          <>
            <NumberInput
              className="mb-6"
              label={
                <>
                  {/* {t('price.label')} */}
                  Phone Number
                </>
              }
              required
              placeholder="Your Phone Number"
              disabled={disable}
              hideControls
              rightSection={
                <Select
                  disabled={disable}
                  data={countryCodes}
                  rightSectionWidth={28}
                  value={country}
                  onChange={(value) => setCountry(value!)}
                  searchable={true}
                  nothingFoundMessage="No country found"
                  styles={{
                    input: {
                      fontWeight: 500,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      width: rem(110),
                      marginRight: rem(-2),
                    },
                  }}
                />
              }
              rightSectionWidth={110}
              {...form.getInputProps('phoneNumber')}
            />
          </>

          {/* Alert message */}
          {alert != null && <Alert type={alert[0]} message={alert[1]} />}

          <Button
            variant="light"
            type="submit"
            color="green"
            rightSection={<FontAwesomeIcon icon={faChevronRight} size="sm" />}
            className={'bg-green-400/30'}
            disabled={disable}
          >
            Next
          </Button>
        </form>
      </DualNavLayout>
    </Protected>
  )
}
