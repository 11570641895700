import ModelBase from '@shared/models/base'

export interface GetPrivateChatRoomReqJSONModel {
  userId: string
}

export default class GetPrivateChatRoomReqModel extends ModelBase {
  /// Public ///

  public constructor() {
    super()

    this.userId = ''
  }

  public getUserId(): string {
    return this.userId
  }

  public setUserId(userId: string): this {
    this.userId = userId
    return this
  }

  public toJSONObject(): GetPrivateChatRoomReqJSONModel {
    const { userId } = this

    return {
      userId
    }
  }

  public fromJSONObject(json: GetPrivateChatRoomReqJSONModel): this {
    this.validate(json, 'userId', 'string')

    const { userId } = json

    this.userId = userId

    return this
  }

  /// Private ///

  private userId: string
}