import Alert, { AlertType } from '@/components/alert'
import DualNavLayout from '@/components/layout/dual-nav'
import MultiSelectCreatable from '@/components/multi-select-creatable'
import Protected from '@/components/protected'
import { storage } from '@/configs/firebase'
import { AuthState } from '@/constants/auth'
import { CreateEventFormData, CreateEventFormProvider, useCreateEventForm } from '@/context/create-event-form'
import { Retcode } from '@/models/api'
import Api from '@/utils/api'
import { getErrorMessage } from '@/utils/error'
import { fetchEventImageUrl } from '@/utils/firebase'
import {
  faChevronRight,
  faCloudDownload,
  faDownload,
  faEdit,
  faGears,
  faInfo,
  faX,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ActionIcon,
  AspectRatio,
  Button,
  CloseButton,
  Combobox,
  Group,
  Loader,
  Alert as Malert,
  Image as Mimage,
  NumberInput,
  Progress,
  ScrollArea,
  Select,
  Text,
  TextInput,
  Tooltip,
  rem,
  useCombobox,
} from '@mantine/core'
import { DateTimePicker } from '@mantine/dates'
import { Dropzone, FileWithPath, MIME_TYPES } from '@mantine/dropzone'
import { modals } from '@mantine/modals'
import { RichTextEditor } from '@mantine/tiptap'
import CreateEventDraftReqModel from '@shared/models/api/request/create-event-draft'
import CreateEventDraftRspModel from '@shared/models/api/response/create-event-draft'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import {
  AdvancedMarker,
  Map,
  MapCameraChangedEvent,
  MapCameraProps,
  useMap,
  useMapsLibrary,
} from '@vis.gl/react-google-maps'
import { deleteObject, ref, uploadBytesResumable } from 'firebase/storage'
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { TAGS } from '@/constants/common'

const currency = [
  { value: 'hkd', label: '🇭🇰 HKD' },
  { value: 'eur', label: '🇪🇺 EUR' },
  { value: 'usd', label: '🇺🇸 USD' },
  { value: 'cad', label: '🇨🇦 CAD' },
  { value: 'gbp', label: '🇬🇧 GBP' },
  { value: 'aud', label: '🇦🇺 AUD' },
]

const INITIAL_CAMERA = {
  center: { lat: 22.302383, lng: 114.2037 },
  zoom: 12,
}

export default function EventCreatePage(): ReactElement {
  const { t } = useTranslation('event')
  const [files, setFiles] = useState<FileWithPath[]>([])
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [tags, setTags] = useState<string[]>([])
  const [alert, setAlert] = useState<[AlertType, string] | null>(null)
  const [disable, setDisable] = useState(false)
  const [isDraft, setIsDraft] = useState(false)
  const [progressBar, setProgressBar] = useState(0)
  const [loading, setLoading] = useState(false)
  const [eventLocationPos, setEventLocationPos] = useState<google.maps.LatLngLiteral | null>(null)
  const [predictInput, setPredictInput] = useState('')
  const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([])
  const navigate = useNavigate()
  const openRef = useRef<() => void>(null)
  const timeoutRef = useRef<number>(-1)
  const combobox = useCombobox()
  const content = ''

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          HTMLAttributes: {
            class: 'list-disc',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-decimal',
          },
        },
      }),
      Underline,
      Link,
      Superscript,
      Subscript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content,
  })

  const map = useMap()
  const placesLib = useMapsLibrary('places')

  const form = useCreateEventForm({
    initialValues: {
      eventName: '',
      eventStartTime: null,
      eventEndTime: null,
      eventLocation: '',
      eventPrice: 0,
      eventPlaceId: '',
      eventCategory: '',
    },

    validate: {
      eventName: (value) => (value.trim().length <= 0 ? 'Event name is required' : null),
      eventStartTime: (value) => (value == null ? 'Start date is required' : null),
      eventEndTime: (value) => (value == null ? 'End date is required' : null),
      eventLocation: (value) => (value == null ? 'Event location is required' : null),
      eventPrice: (value) => (value <= 0 ? 'Price is required' : null),
      eventPlaceId: (value) => (value.trim().length <= 0 ? 'Event location is required' : null),
      eventCategory: (value) => (value.trim().length <= 0 ? 'Event category is required' : null),
    },

    onValuesChange(v, pv) {
      if (v.eventLocation !== pv.eventLocation) {
        combobox.updateSelectedOptionIndex()
        const val = v.eventLocation

        window.clearTimeout(timeoutRef.current)
        form.setFieldValue('eventLocation', val)
        setPredictInput(val)
      }
    },
  })

  useEffect(() => {
    if (!placesLib || !map) return
  }, [placesLib, map])

  const [cameraProps, setCameraProps] = useState<MapCameraProps>(INITIAL_CAMERA)
  const handleCameraChange = useCallback((event: MapCameraChangedEvent) => {
    setCameraProps(event.detail)
  }, [])

  useEffect(() => {
    window.clearTimeout(timeoutRef.current)
    setLoading(true)
    if (!placesLib) {
      console.error('Google Maps Places Library not loaded')
      return
    }

    console.debug('predict input:', predictInput)
    if (predictInput && predictInput.length > 0) {
      timeoutRef.current = window.setTimeout(() => {
        setLoading(false)
        const autocompleteService = new placesLib.AutocompleteService()
        autocompleteService.getPlacePredictions(
          {
            input: predictInput,
            locationBias: {
              center: INITIAL_CAMERA.center,
              radius: 50e3, // 50km
            },
          },
          (results, status) => {
            if (status === placesLib.PlacesServiceStatus.OK && results) {
              setPredictions(results)
            } else {
              setPredictions([])
            }
          },
        )
      }, 1000)
    } else {
      console.debug('no predict input')
      setPredictions([])
      setLoading(false)
    }
  }, [predictInput, placesLib])

  // Fetch image urls
  useEffect(() => {
    const loadImages = async () => {
      if (!files) return console.log('No image files')
      const urls = await Promise.all(files.map((file) => fetchEventImageUrl(file.path!)))
      setImageUrls(urls.filter((url) => url !== null) as string[])
    }

    loadImages()
  }, [files])

  const previews: ReactElement[] = imageUrls.map((url, index) => {
    return (
      <div className="relative">
        <Mimage
          key={index}
          src={url}
          radius="md"
          fit="contain"
          h={100}
          w={100}
          onClick={() => {
            modals.open({
              title: 'Gallery',
              radius: 'md',
              size: 'xl',
              centered: true,
              children: (
                <AspectRatio ratio={1}>
                  <Mimage src={url} alt="Gallery" fit="contain" />
                </AspectRatio>
              ),
            })
          }}
        />
        {index !== 0 && (
          <Tooltip label="Set as thumbnail" position="top" withArrow>
            <ActionIcon
              size="sm"
              variant="filled"
              className="absolute top-0 left-0 rounded-md text-white bg-blue-500 hover:bg-blue-500/80"
              onClick={() => setThumbnail(index)}
            >
              <FontAwesomeIcon icon={faGears} size="xs" />
            </ActionIcon>
          </Tooltip>
        )}
        <Tooltip label="Remove image" position="top" withArrow>
          <CloseButton
            size="sm"
            className="absolute top-0 right-0 rounded-md text-white bg-red-500 hover:bg-red-500/80"
            icon={<FontAwesomeIcon icon={faX} size="xs" />}
            onClick={() => removeImage(index)}
          />
        </Tooltip>
      </div>
    )
  })

  function addFiles(selectedFiles: FileWithPath[]) {
    selectedFiles.forEach((file) => {
      const path = `event_uploads/${uuidv4()}`

      // Create a storage reference
      const storageRef = ref(storage, path)

      // Upload the file
      const uploadTask = uploadBytesResumable(storageRef, file)

      // Optional: Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Get upload progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setProgressBar(progress)
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused')
              break
            case 'running':
              console.log('Upload is running')
              break
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.error(error)
        },
        () => {
          // Handle successful uploads on complete
          // For instance, you could update your component state here to show the uploaded file
          console.log('Upload successful')
          setProgressBar(0)
          // Adding the selected files to the component state
          setFiles((prevFiles) => [...prevFiles, { ...file, path }])
        },
      )
    })
  }

  // remove image from the list
  function removeImage(index: number) {
    setFiles((prevFiles) => {
      // Get the file to be removed
      const fileToRemove = prevFiles[index]

      // Create a reference to the file to be deleted
      const fileRef = ref(storage, fileToRemove.path)

      // Delete the file
      deleteObject(fileRef)
        .then(() => {
          console.log(`File at ${fileToRemove.path} has been deleted successfully.`)
        })
        .catch((error) => {
          console.error('Error removing file: ', error)
        })

      // Filter out the file from the state
      return prevFiles.filter((_, i) => i !== index)
    })
    setImageUrls((prevUrls) => prevUrls.filter((_, i) => i !== index))
    // delete the image from the storage
  }

  // set image as thumbnail
  function setThumbnail(index: number) {
    // move the image to the first index
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles]
      const selectedFile = newFiles[index]
      newFiles.splice(index, 1)
      newFiles.unshift(selectedFile)
      return newFiles
    })
    setImageUrls((prevUrls) => {
      const newUrls = [...prevUrls]
      const selectedUrl = newUrls[index]
      newUrls.splice(index, 1)
      newUrls.unshift(selectedUrl)
      return newUrls
    })
  }

  async function onSubmit(value: CreateEventFormData) {
    try {
      setDisable(true)

      const { eventName, eventLocation, eventCategory, eventStartTime, eventEndTime, eventPrice, eventPlaceId } = value

      const rsp = await Api.sendCsrfRequest(
        '/event/createEventDraft',
        CreateEventDraftRspModel,
        new CreateEventDraftReqModel().fromJSONObject({
          name: eventName,
          description: editor?.getHTML() ?? '',
          images: files.map((file) => `${file.path}`),
          placeId: eventPlaceId,
          locationName: eventLocation,
          locationLat: eventLocationPos?.lat ?? 0,
          locationLng: eventLocationPos?.lng ?? 0,
          tags: tags.map((tag) => /[a-zA-Z0-9]+/.exec(tag)?.[0] ?? tag),
          category: eventCategory,
          website: '',
          beginTime: eventStartTime?.getTime() ?? 0,
          endTime: eventEndTime?.getTime() ?? 0,
          price: eventPrice,
          maxAttendees: 0,
        }),
      )
      if (rsp.getRetcode() !== Retcode.RET_SUCC) throw new Error(rsp.getMsg())

      // update event id to the data object
      const data = rsp.getData()
      if (data == null) throw new Error('data == null')

      navigate(isDraft ? '/' : `/event/${data.getEventId()}/publish`)
    } catch (err) {
      // Show error message
      setAlert(['danger', getErrorMessage(err)])
      setDisable(false)
    }
  }

  return (
    <Protected state={AuthState.LOGIN} redirect className="h-full">
      <DualNavLayout className="container mx-auto my-5 px-12 md:px-24 lg:px-32 xl:px-44">
        <h1 className="text-3xl font-bold mb-4">{t('title.create')}</h1>
        <CreateEventFormProvider form={form}>
          <form onSubmit={form.onSubmit((v) => onSubmit(v) as unknown)}>
            {/* Image upload dropzone */}
            <div className="mb-6">
              <Dropzone
                openRef={openRef}
                onDrop={addFiles}
                style={{ borderWidth: 2, paddingBottom: 50 }}
                radius="md"
                accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.webp]}
                maxSize={30 * 1024 ** 2}
                disabled={disable}
              >
                <div style={{ pointerEvents: 'none' }}>
                  <Group justify="center">
                    <Dropzone.Accept>
                      <FontAwesomeIcon icon={faDownload} size="lg" />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <FontAwesomeIcon icon={faX} size="lg" />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      <FontAwesomeIcon icon={faCloudDownload} size="lg" />
                    </Dropzone.Idle>
                  </Group>

                  <Text ta="center" mt="xl" className="font-sans text-lg font-bold">
                    <Dropzone.Accept>{t('upload.accept')}</Dropzone.Accept>
                    <Dropzone.Reject>{t('upload.reject')}</Dropzone.Reject>
                    <Dropzone.Idle>{t('upload.idle')}</Dropzone.Idle>
                  </Text>
                  <Text ta="center" mt="xs" c="dimmed" className="font-sans text-base">
                    <Trans i18nKey="upload.text" t={t}>
                      ?<i>?</i>?
                    </Trans>
                  </Text>
                </div>
              </Dropzone>

              {progressBar > 0 && <Progress value={progressBar} transitionDuration={200} animated className="mt-2" />}

              <div className={`mt-4 ${previews.length > 0 ? 'block' : 'hidden'}`}>
                <Malert
                  className="mt-2 font-sans text-lg"
                  variant="light"
                  color="blue"
                  title={t('upload.alertTitle')}
                  radius="md"
                  icon={<FontAwesomeIcon icon={faInfo} size="sm" />}
                >
                  <Trans i18nKey="upload.setThumbnail" t={t}>
                    ?<FontAwesomeIcon icon={faGears} size="sm" />?
                  </Trans>
                  <br />
                  <Trans i18nKey="upload.removeImage" t={t}>
                    ?<FontAwesomeIcon icon={faX} size="sm" />?
                  </Trans>
                </Malert>
                <ScrollArea w={'auto'} h={'150'} scrollbars="y" className="mt-2">
                  <Group>{previews}</Group>
                </ScrollArea>
              </div>
            </div>

            {/* Event name input */}
            <div>
              <TextInput
                className="mb-6"
                classNames={{ label: 'font-sans mb-2', input: 'font-sans' }}
                label={t('name.label')}
                required
                placeholder={t('name.placeholder')}
                disabled={disable}
                {...form.getInputProps('eventName')}
              />
            </div>

            {/* Event description input */}
            <div className="mb-6 block text-sm font-medium text-gray-900 dark:text-white">
              <Text className="font-sans mb-2 text-sm font-medium pointer-events-none">
                {t('description.label')} <span className="text-red-500">*</span>
              </Text>
              <RichTextEditor editor={editor}>
                <RichTextEditor.Toolbar sticky stickyOffset={60}>
                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Underline />
                    <RichTextEditor.Strikethrough />
                    <RichTextEditor.ClearFormatting />
                    <RichTextEditor.Highlight />
                    <RichTextEditor.Code />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.H1 />
                    <RichTextEditor.H2 />
                    <RichTextEditor.H3 />
                    <RichTextEditor.H4 />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Blockquote />
                    <RichTextEditor.Hr />
                    <RichTextEditor.BulletList />
                    <RichTextEditor.OrderedList />
                    <RichTextEditor.Subscript />
                    <RichTextEditor.Superscript />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Link />
                    <RichTextEditor.Unlink />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.AlignLeft />
                    <RichTextEditor.AlignCenter />
                    <RichTextEditor.AlignJustify />
                    <RichTextEditor.AlignRight />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Undo />
                    <RichTextEditor.Redo />
                  </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>

                <RichTextEditor.Content />
              </RichTextEditor>
            </div>

            {/* Event date input */}
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <DateTimePicker
                classNames={{ label: 'font-sans mb-2', input: 'font-sans' }}
                popoverProps={{ zIndex: 10000 }}
                label={t('datetime.label.begin')}
                required
                placeholder={t('datetime.placeholder')}
                disabled={disable}
                clearable
                {...form.getInputProps('eventStartTime')}
              />
              <DateTimePicker
                classNames={{ label: 'font-sans mb-2', input: 'font-sans' }}
                popoverProps={{ zIndex: 10000 }}
                label={t('datetime.label.end')}
                required
                placeholder={t('datetime.placeholder')}
                disabled={disable}
                clearable
                {...form.getInputProps('eventEndTime')}
              />
            </div>

            {/* Event tags input */}
            <div className="mb-6">
              <MultiSelectCreatable
                classNames={{ label: 'font-sans mb-2', input: 'font-sans' }}
                options={TAGS}
                label={t('tags.label')}
                placeholder={t('tags.placeholder')}
                onChange={(value) => {
                  setTags(value)
                }}
              />
            </div>

            {/* Event category input */}
            <div className="mb-6">
              <Select
                classNames={{ label: 'font-sans mb-2', input: 'font-sans' }}
                label={t('category.label')}
                required
                placeholder={t('category.placeholder')}
                data={[
                  { value: 'music', label: 'Music' },
                  { value: 'movies', label: 'Movies' },
                  { value: 'food', label: 'Food' },
                  { value: 'sports', label: 'Sports' },
                  { value: 'art', label: 'Art' },
                  { value: 'tech', label: 'Tech' },
                  { value: 'comedy', label: 'Comedy' },
                  { value: 'triathlon', label: 'Triathlon' },
                  { value: 'duathlon', label: 'Duathlon' },
                  { value: 'aquathlon', label: 'Aquathlon' },
                  { value: 'aquabike', label: 'Aquabike' },
                  { value: 'other', label: 'Other' },
                ]}
                {...form.getInputProps('eventCategory')}
              />
            </div>

            {/* Event location input */}
            <div className="mb-6">
              <Combobox
                onOptionSubmit={(optionValue) => {
                  setPredictInput(optionValue)

                  const selectedPredict = predictions.find((p) => p.description === optionValue)

                  form.setFieldValue('eventPlaceId', selectedPredict?.place_id ?? '')
                  form.setFieldValue('eventLocation', selectedPredict?.description ?? '')
                  setLoading(false)

                  if (!placesLib) return

                  const div = document.createElement('div')
                  const service = new placesLib.PlacesService(div)
                  service.getDetails({ placeId: selectedPredict?.place_id ?? '' }, (place, status) => {
                    if (status === placesLib.PlacesServiceStatus.OK && place) {
                      if (place?.geometry) {
                        setEventLocationPos({
                          lat: place.geometry.location?.lat() ?? 0,
                          lng: place.geometry.location?.lng() ?? 0,
                        })
                        setCameraProps({
                          center: {
                            lat: place.geometry.location?.lat() ?? 0,
                            lng: place.geometry.location?.lng() ?? 0,
                          },
                          zoom: 15,
                        })
                      }
                    }
                  })

                  combobox.closeDropdown()
                }}
                store={combobox}
                withinPortal={false}
                transitionProps={{ duration: 200, transition: 'pop' }}
              >
                <Combobox.Target>
                  <TextInput
                    classNames={{ label: 'font-sans mb-2', input: 'font-sans' }}
                    label={t('location.label')}
                    required
                    placeholder={t('location.placeholder')}
                    onChange={(e) => setPredictInput(e.currentTarget.value)}
                    value={predictInput}
                    onClick={() => combobox.openDropdown()}
                    onFocus={() => combobox.openDropdown()}
                    onBlur={() => combobox.closeDropdown()}
                    rightSection={loading ? <Loader size="1rem" /> : null}
                  />
                </Combobox.Target>

                <Combobox.Dropdown>
                  <Combobox.Options>
                    {predictions.length === 0 ? (
                      <Combobox.Empty>{t('location.notFound')}</Combobox.Empty>
                    ) : (
                      predictions.map((prediction) => (
                        <Combobox.Option value={`${prediction.description}`} key={prediction.place_id}>
                          {`${prediction.description}`}
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </Combobox.Dropdown>
              </Combobox>

              {eventLocationPos && (
                <AspectRatio ratio={16 / 9} className="z-0 mt-10">
                  <Map
                    id="create"
                    {...cameraProps}
                    onCameraChanged={handleCameraChange}
                    defaultCenter={eventLocationPos ?? cameraProps.center}
                    defaultZoom={10}
                    gestureHandling={'greedy'}
                    mapId={'63208d5c9c3fd3ba'}
                  >
                    {eventLocationPos && <AdvancedMarker position={eventLocationPos} />}
                  </Map>
                </AspectRatio>
              )}
            </div>

            {/* Event price input */}
            <div className="mb-6">
              <NumberInput
                classNames={{ label: 'font-sans mb-2', input: 'font-sans' }}
                label={t('price.label')}
                required
                placeholder={t('price.placeholder')}
                disabled={disable}
                rightSection={
                  <Select
                    disabled
                    data={currency}
                    rightSectionWidth={28}
                    defaultValue="hkd"
                    styles={{
                      input: {
                        fontWeight: 500,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        width: rem(110),
                        marginRight: rem(-2),
                      },
                    }}
                  />
                }
                rightSectionWidth={110}
                {...form.getInputProps('eventPrice')}
              />
            </div>

            {/*
            <div className="flex items-start mb-6">
              <Checkbox
                label={
                  <>
                    I accept{' '}
                    <Anchor href="#" target="_blank" inherit>
                      terms and conditions
                    </Anchor>
                  </>
                }
                {...form.getInputProps('termsOfService')}
              />
            </div>
            */}

            {/* Alert message */}
            {alert != null && <Alert type={alert[0]} message={alert[1]} />}

            {/* Submit buttons */}
            <Group justify="center" gap="xl" className="mt-6">
              <Button
                variant="light"
                type="submit"
                color="orange"
                rightSection={<FontAwesomeIcon icon={faEdit} size="sm" />}
                className={'bg-orange-400/30 font-sans'}
                disabled={disable}
                onClick={() => setIsDraft(true)}
              >
                {t('submit.draft')}
              </Button>
              <Button
                variant="light"
                type="submit"
                color="green"
                rightSection={<FontAwesomeIcon icon={faChevronRight} size="sm" />}
                className={'bg-green-400/30 font-sans'}
                disabled={disable}
                onClick={() => setIsDraft(false)}
              >
                {t('submit.next')}
              </Button>
            </Group>
          </form>
        </CreateEventFormProvider>
      </DualNavLayout>
    </Protected>
  )
}
