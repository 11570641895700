import { defaultIconUrl } from '@/constants/common'
import DateTime from '@/utils/datetime'
import { fetchEventImageUrl } from '@/utils/firebase'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import { faChevronRight, faHeart, faShare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from '@mantine/carousel'
import { ActionIcon, Avatar, Badge, Card, Center, Group, Image, Stack, Text, TypographyStylesProvider } from '@mantine/core'
import EventModel from '@shared/models/event'
import { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export type EventCardListProps = {
  heading: string
  eventList: EventModel[]
}

function ListItem({ data }: Readonly<{ data: EventModel }>) {
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null)
  const [organizerIcon, setOrganizerIcon] = useState<string>('')

  const beginTime = new DateTime(data.getBeginTime())

  useEffect(() => {
    async function loadImages() {
      const imagePath = JSON.parse(data.getImages())[0]
      setThumbnailUrl(await fetchEventImageUrl(imagePath))
      setOrganizerIcon(await fetchEventImageUrl(data.getOrganizer().getThumbnailUrl()) ?? defaultIconUrl)
    }

    loadImages()
  }, [data])

  return (
    <div className="relative grow-0 shrink-0 w-60 shadow-sm overflow-hidden">
      <Card withBorder shadow="sm" className="relative bg-dark rounded-lg px-2" style={{ height: 365 }}>
        <Card.Section className="mb-2">
          <Link to={`/event/${data.getId()}`}>
            <Image src={thumbnailUrl} className="w-full h-[180px] rounded-md" />
          </Link>
        </Card.Section>

        <Badge
          className="absolute top-2 right-3 pointer-events-none"
          variant="gradient"
          gradient={{ from: 'yellow', to: 'red' }}
          radius="md"
          size="md"
        >
          <p className="text-xs font-sans">{beginTime.toFormatString('D MMM')}</p>
        </Badge>

        <Stack justify="flex-start" h={150}>
          <Text lineClamp={3} component="div">
            <TypographyStylesProvider>
              <Link to={`/event/${data.getId()}`}>
                <Text className="mt-3 mb-1 font-medium text-xl text-white" truncate="end" fw={500}>
                  {data.getName()}
                </Text>
              </Link>
              <Text
                className="font-sans text-gray-300/70 text-sm font-normal"
                dangerouslySetInnerHTML={{ __html: data.getDescription() }}
              ></Text>
            </TypographyStylesProvider>
          </Text>
        </Stack>

        <Group justify="space-between" className="mb-2 bottom-1 top-auto">
          <Center>
            <Avatar src={organizerIcon} size={24} radius="xl" mr="xs" />
            <Text fz="sm" inline className="font-sans text-white">
              {data.getOrganizer().getDisplayName()}
            </Text>
          </Center>
        </Group>

        <Group gap={8} mr={0}>
          <ActionIcon variant="subtle" color="gray">
            <FontAwesomeIcon icon={faHeart} size="lg" className="text-red-500" />
          </ActionIcon>
          <ActionIcon variant="subtle" color="gray">
            <FontAwesomeIcon icon={faBookmark} size="lg" className="text-yellow-500" />
          </ActionIcon>
          <ActionIcon variant="subtle" color="gray">
            <FontAwesomeIcon icon={faShare} size="lg" className="text-blue-500" />
          </ActionIcon>
        </Group>
      </Card>
    </div>
  )
}

export default function EventCardList({ heading, eventList }: Readonly<EventCardListProps>): ReactElement {
  return (
    <div className="mt-4 px-4">
      <div className="font-bold text-xl mb-2">{heading}</div>
      <Carousel
        slideSize={{ base: '60%', sm: '15%' }}
        slideGap={{ base: 'xs' }}
        align="start"
        loop
        dragFree
        height={365}
        classNames={{
          control: 'dark:bg-transparent dark:text-white bg-gray-800/40 text-white rounded-full',
          controls: 'sm:-mx-12 max-sm:hidden',
        }}
        nextControlIcon={<FontAwesomeIcon icon={faChevronRight} size="sm" />}
        previousControlIcon={
          <FontAwesomeIcon icon={faChevronRight} size="sm" style={{ transform: 'rotate(180deg)' }} />
        }
      >
        {eventList.map((e) => (
          <Carousel.Slide key={e.getId()}>
            <ListItem data={e} />
          </Carousel.Slide>
        ))}
      </Carousel>
    </div>
  )
}
