import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { categories, Category } from '@/constants/common'
import { useTranslation } from 'react-i18next'
import { Button, Group, ScrollArea } from '@mantine/core'

const EventCategories = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const onClickCategory = (item: Category) => {
    navigate(`/category/${item.label.toLowerCase()}`)
  }

  return (
    <div className="flex flex-col mb-4">
      <p className="font-bold text-xl ml-4 mt-2 font-sans">{t('home.category')}</p>
      <ScrollArea scrollbars={'x'} type='never' className='w-full whitespace-nowrap'>
        {categories.map((item, index) => (
          <button
            key={index}
            onClick={() => onClickCategory(item)}
            className={`ml-4 my-3 bg-gray-200 hover:bg-gray-300 rounded-full px-6 py-3 dark:bg-white/30 dark:hover:bg-gray-500 dark:text-white`}
          >
            <Group>
              <FontAwesomeIcon icon={item.icon} size="lg" className={`text-black dark:text-white`} />
              <span className="font-sans">{t(`home.categories.${item.label.toLowerCase()}`)}</span>
            </Group>
          </button>
        ))}
      </ScrollArea>
    </div>
  )
}

export default EventCategories
