import ChatMessageModel from '@shared/models/chat/chat-message'
import { createContext } from 'react'

export interface IChatContext {
  roomId: bigint | null
  isLoaded: boolean
  firstSeqId: bigint
  lastSeqId: bigint
  minLoadedSeqId: bigint
  maxLoadedSeqId: bigint

  sendMessageList: ChatMessageModel[]
  failMessageList: ChatMessageModel[]
  recvMessageList: ChatMessageModel[]
  replyMessage: ChatMessageModel | null

  setFirstSeqId: (seqId: bigint) => void
  setLastSeqId: (seqId: bigint) => void
  setMinLoadedSeqId: (seqId: bigint) => void
  setMaxLoadedSeqId: (seqId: bigint) => void
  setReplyMessage: (message: ChatMessageModel | null) => void
  deleteMessage: (message: ChatMessageModel) => void

  resendMessage: () => void
  reducePullFreq: () => void
  resetPullFreq: () => void

  getScrollDistanceFromBottom: () => number
  isAtTop: () => boolean
  isAtBottom: () => boolean
  scrollToBottom: (smooth: boolean, immediate: boolean) => void
}

export const ChatContext = createContext<IChatContext>(null!)