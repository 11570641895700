import BinaryModelBase, { BinaryValueType } from '@shared/models/binary'

export interface PullChatReqJSONModel {
  roomId: string
  firstRoomId: string
  beginSeqId: string
  endSeqId: string
}

export default class PullChatReqModel extends BinaryModelBase<PullChatReqModel> {
  /// Public ///

  public constructor() {
    super()

    this.roomId = 0n
    this.firstRoomId = 0n
    this.beginSeqId = 0n
    this.endSeqId = 0n

    this.addField('RoomId', { type: BinaryValueType.INT64 })
    this.addField('FirstRoomId', { type: BinaryValueType.INT64 })
    this.addField('BeginSeqId', { type: BinaryValueType.INT64 })
    this.addField('EndSeqId', { type: BinaryValueType.INT64 })
  }

  public getRoomId(): bigint {
    return this.roomId
  }

  public getFirstRoomId(): bigint {
    return this.firstRoomId
  }

  public getBeginSeqId(): bigint {
    return this.beginSeqId
  }

  public getEndSeqId(): bigint {
    return this.endSeqId
  }

  public setRoomId(roomId: bigint): this {
    this.roomId = roomId
    return this
  }

  public setFirstRoomId(firstRoomId: bigint): this {
    this.firstRoomId = firstRoomId
    return this
  }

  public setBeginSeqId(beginSeqId: bigint): this {
    this.beginSeqId = beginSeqId
    return this
  }

  public setEndSeqId(endSeqId: bigint): this {
    this.endSeqId = endSeqId
    return this
  }

  public toJSONObject(): PullChatReqJSONModel {
    const { roomId, firstRoomId, beginSeqId, endSeqId } = this

    return {
      roomId: roomId.toString(),
      firstRoomId: firstRoomId.toString(),
      beginSeqId: beginSeqId.toString(),
      endSeqId: endSeqId.toString()
    }
  }

  public fromJSONObject(json: PullChatReqJSONModel): this {
    this.validate(json, 'roomId', 'string')
    this.validate(json, 'firstRoomId', 'string')
    this.validate(json, 'beginSeqId', 'string')
    this.validate(json, 'endSeqId', 'string')

    const { roomId, firstRoomId, beginSeqId, endSeqId } = json

    this.roomId = BigInt(roomId || '0')
    this.firstRoomId = BigInt(firstRoomId || '0')
    this.beginSeqId = BigInt(beginSeqId || '0')
    this.endSeqId = BigInt(endSeqId || '0')

    return this
  }

  /// Private ///

  private roomId: bigint
  private firstRoomId: bigint
  private beginSeqId: bigint
  private endSeqId: bigint

  /// Binary getter/setter ///

  public get RoomId(): bigint {
    return this.roomId
  }

  public get FirstRoomId(): bigint {
    return this.firstRoomId
  }

  public get BeginSeqId(): bigint {
    return this.beginSeqId
  }

  public get EndSeqId(): bigint {
    return this.endSeqId
  }

  public set RoomId(roomId: bigint) {
    this.roomId = roomId
  }

  public set FirstRoomId(firstRoomId: bigint) {
    this.firstRoomId = firstRoomId
  }

  public set BeginSeqId(beginSeqId: bigint) {
    this.beginSeqId = beginSeqId
  }

  public set EndSeqId(endSeqId: bigint) {
    this.endSeqId = endSeqId
  }
}