import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '@/assets/images/logo.png'
import { ReactElement } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import VerticalScrollLayout from './vertical-scroll'

export type FullScreenModalLayoutProps = {
  closable?: boolean
  className?: string
  children?: ReactElement[] | ReactElement
}

export function FullScreenModalLayout({ closable, className, children }: Readonly<FullScreenModalLayoutProps>): ReactElement {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <div className='flex flex-col h-full bg-white dark:bg-dark text-black dark:text-white'>
      {/* Top Navbar */}
      <nav className='bg-primary-700'>
        <div className="flex justify-between items-center mx-auto max-w-7xl h-16 px-2 sm:px-6 lg:px-8">
          <div className="flex flex-shrink-0 items-center">
            <img
              className="h-8 w-auto"
              src={logo}
              alt="Moment"
            />
          </div>
          <button
            className={closable ? 'p-2 rounded-full bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600' : 'hidden'}
            onClick={() => closable && (location.key === 'default' ? navigate('/') : navigate(-1))}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
      </nav>

      {/* Main Content */}
      <VerticalScrollLayout className={className}>{children}</VerticalScrollLayout>
    </div>
  )
}