import { ChatContext } from '@/context/chat'
import Auth from '@/utils/auth'
import { fetchEventImageUrlList } from '@/utils/firebase'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Text, Tooltip } from '@mantine/core'
import ChatRoomModel from '@shared/models/chat/chat-room'
import UserSimpleModel from '@shared/models/user/simple'
import { ReactElement, useContext, useEffect, useState } from 'react'

export type ChatRoomEntryProps = {
  room: ChatRoomModel
  onClick: () => void
}

export default function ChatRoomEntry({ room, onClick }: Readonly<ChatRoomEntryProps>): ReactElement {
  const [iconUrlMap, setIconUrlMap] = useState<{ [uid: string]: string }>({})
  const [memberList, setMemberList] = useState<UserSimpleModel[]>([])

  const { roomId } = useContext(ChatContext)

  const visibleMemberList = memberList.filter((_, i) => memberList.length <= 2 || i < 1)
  const hiddenMemberList = memberList.filter((_, i) => memberList.length > 2 && i >= 1)

  // Fetch icon url
  useEffect(() => {
    if (memberList.length === 0) return

    fetchEventImageUrlList(visibleMemberList.map(member => member.getThumbnailUrl())).then(iconUrlList => {
      if (visibleMemberList.length !== iconUrlList.length) {
        console.warn('mismatch member icon list size')
        return
      }

      const newIconUrlMap: { [uid: string]: string } = {}
      for (let i = 0; i < iconUrlList.length; i++) {
        const iconUrl = iconUrlList[i]
        if (iconUrl == null) continue

        newIconUrlMap[visibleMemberList[i].getId()] = iconUrl
      }

      setIconUrlMap(newIconUrlMap)
    })
  }, [memberList])

  // Wait for current user then set member list without current user
  useEffect(() => {
    Auth.getAuthState().then(() => {
      const { currentUser } = Auth
      setMemberList(room.getMemberList().filter(member => member.getId() !== currentUser?.getId()))
    })
  }, [])

  return (
    <Button
      className='w-full pl-1'
      variant={roomId === room.getId() ? 'filled' : 'outline'}
      size='lg'
      justify='space-between'
      leftSection={
        memberList.length > 1 ? (
          <Avatar.Group>
            {visibleMemberList.map(member => (
              <Tooltip key={member.getId()} withArrow label={member.getDisplayName()}>
                <Avatar src={iconUrlMap[member.getId()] ?? null} radius='xl' />
              </Tooltip>
            ))}
            {hiddenMemberList.length > 0 && (
              <Tooltip
                withArrow
                label={(<>{hiddenMemberList.map(member => (<Text key={member.getId()}>{member.getDisplayName()}</Text>))}</>)}
              >
                <Avatar radius='xl'>+{hiddenMemberList.length}</Avatar>
              </Tooltip>
            )}
          </Avatar.Group>
        ) : (
          <Tooltip withArrow label={visibleMemberList[0]?.getDisplayName() ?? 'Guest'}>
            <Avatar src={iconUrlMap[visibleMemberList[0]?.getId()]} radius='xl'></Avatar>
          </Tooltip>
        )
      }
      rightSection={<FontAwesomeIcon icon={faComment} />}
      onClick={onClick}
    >
      <Text className='overflow-hidden text-ellipsis font-sans'>
        {visibleMemberList.map(member => member.getDisplayName()).join(', ')}
        {hiddenMemberList.length > 0 && (` & ${hiddenMemberList.length} others`)}
      </Text>
    </Button>
  )
}