import { ApiSocketCmdId } from '@shared/models/api/socket'
import ModelBase from '@shared/models/base'
import BinaryModelBase, { BinaryValueType } from '@shared/models/binary'

export * from '@shared/models/api'

export interface ApiReqJSONModel {
  authToken?: string | null
  csrfToken?: string | null
  data: object | null
}

export interface ApiSocketReqJSONModel {
  cmdId: ApiSocketCmdId
  authToken?: string | null
  data: object | null
}

export class ApiReqModel<T extends ModelBase = ModelBase> extends ModelBase {
  /// Public ///

  public constructor(ctor?: new () => T) {
    super()

    this.dataCtor = ctor ?? null

    this.authToken = null
    this.csrfToken = null
    this.data = null
  }

  public getData(): T | null {
    return this.data
  }

  public setAuthToken(token: string | null): this {
    this.authToken = token
    return this
  }

  public setCsrfToken(token: string | null): this {
    this.csrfToken = token
    return this
  }

  public setData(value: T | null): this {
    this.data = value
    return this
  }

  public toJSONObject(): ApiReqJSONModel {
    const { authToken, csrfToken, data } = this

    const json: ApiReqJSONModel = {
      data: data?.toJSONObject() ?? null
    }

    if (authToken != null) json.authToken = authToken
    if (csrfToken != null) json.csrfToken = csrfToken

    return json
  }

  public fromJSONObject(json: ApiReqJSONModel): this {
    const { dataCtor } = this

    this.validate(json, 'authToken', 'string', true)
    this.validate(json, 'csrfToken', 'string', true)
    this.validate(json, 'data', 'object', dataCtor == null)

    const { authToken, csrfToken, data } = json

    this.authToken = authToken ?? null
    this.csrfToken = csrfToken ?? null

    if (dataCtor == null || data == null) {
      this.data = null
    } else {
      this.data = new dataCtor()
      this.data.fromJSONObject(data)
    }

    return this
  }

  /// Private ///

  private dataCtor: (new () => T) | null

  private authToken: string | null
  private csrfToken: string | null
  private data: T | null
}

export class ApiSocketReqModel<T extends BinaryModelBase = BinaryModelBase> extends BinaryModelBase<ApiSocketReqModel<T>> {
  /// Public ///

  public constructor(ctor?: new () => T) {
    super()

    this.dataCtor = ctor ?? null

    this.cmdId = ApiSocketCmdId.CMD_NONE
    this.authToken = null
    this.data = null

    this.addField('CmdId', { type: BinaryValueType.UINT16 })
    this.addField('AuthToken', { type: BinaryValueType.JWT, optional: true })
    this.addField('Data', { type: BinaryValueType.BIN, optional: true, ctor })
  }

  public getCmdId(): ApiSocketCmdId {
    return this.cmdId
  }

  public getData(): T | null {
    return this.data
  }

  public setCmdId(cmdId: ApiSocketCmdId): this {
    this.cmdId = cmdId
    return this
  }

  public setAuthToken(token: string | null): this {
    this.authToken = token
    return this
  }

  public setData(value: T | null): this {
    this.data = value
    return this
  }

  public toJSONObject(): ApiSocketReqJSONModel {
    const { cmdId, authToken, data } = this

    const json: ApiSocketReqJSONModel = {
      cmdId,
      data: data?.toJSONObject() ?? null
    }

    if (authToken != null) json.authToken = authToken

    return json
  }

  public fromJSONObject(json: ApiSocketReqJSONModel): this {
    const { dataCtor } = this

    this.validate(json, 'cmdId', 'number')
    this.validate(json, 'authToken', 'string', true)
    this.validate(json, 'data', 'object', true)

    const { cmdId, authToken, data } = json

    this.cmdId = cmdId
    this.authToken = authToken ?? null

    if (dataCtor == null || data == null) {
      this.data = null
    } else {
      this.data = new dataCtor()
      this.data.fromJSONObject(data)
    }

    return this
  }

  /// Private ///

  private dataCtor: (new () => T) | null

  private cmdId: ApiSocketCmdId
  private authToken: string | null
  private data: T | null

  /// Binary getter/setter ///

  public get CmdId(): number {
    return this.cmdId
  }

  public get AuthToken(): string | null {
    return this.authToken
  }

  public get Data(): T | null {
    return this.data
  }

  public set CmdId(value: number) {
    this.cmdId = value
  }

  public set AuthToken(value: string | null) {
    this.authToken = value
  }

  public set Data(value: T | null) {
    this.data = value
  }
}