import ModelBase from '@shared/models/base'

export enum Retcode {
  RET_FAIL = -1,
  // Generic retcode (0 - 99)
  RET_SUCC = 0,
  RET_RATE_LIMIT = 1,
  RET_NOT_FOUND = 2,
  RET_FOUND = 3,
  RET_INVALID_PARAMS = 4,
  RET_INVALID_STATE = 5,
  // Authentication retcode (100 - 199)
  RET_LOGIN_FAIL = 100,
  RET_AUTH_FAIL = 101,
  RET_LOGGED_IN = 102,
  RET_VERIFY_EMAIL = 103,
  RET_CHANGE_PASSWORD = 104,
  RET_FORBIDDEN = 105
}

export interface ApiRspJSONModel {
  retcode: number
  msg: string
  data: object | null
}

export class ApiRspModel<T extends ModelBase = ModelBase> extends ModelBase {
  /// Public ///

  public constructor(ctor?: new () => T) {
    super()

    this.dataCtor = ctor ?? null

    this.retcode = Retcode.RET_SUCC
    this.msg = 'OK'
    this.data = null
  }

  public getRetcode(): Retcode {
    return this.retcode
  }

  public getMsg(): string {
    return this.msg
  }

  public getData(): T | null {
    return this.data
  }

  public setRetcode(value: Retcode): this {
    this.retcode = value
    return this
  }

  public setMsg(value: string): this {
    this.msg = value
    return this
  }

  public setData(value: T | null): this {
    this.data = value
    return this
  }

  public toJSONObject(): ApiRspJSONModel {
    const { retcode, msg, data } = this

    return {
      retcode,
      msg,
      data: data?.toJSONObject() ?? null
    }
  }

  public fromJSONObject(json: ApiRspJSONModel): this {
    const { dataCtor } = this

    this.validate(json, 'retcode', 'number')
    this.validate(json, 'msg', 'string')
    this.validate(json, 'data', 'object', true)

    const { retcode, msg, data } = json

    if (Retcode[retcode] == null) throw new Error("invalid value for 'retcode'")

    this.retcode = retcode
    this.msg = msg

    if (dataCtor == null || data == null) {
      this.data = null
    } else {
      this.data = new dataCtor()
      this.data.fromJSONObject(data)
    }

    return this
  }

  /// Private ///

  private dataCtor: (new () => T) | null

  private retcode: Retcode
  private msg: string
  private data: T | null
}