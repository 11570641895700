import Alert, { AlertType } from '@/components/alert'
import PasswordRequirement from '@/components/password-requirement'
import Protected from '@/components/protected'
import ThirdParty from '@/components/third-party'
import { AuthState } from '@/constants/auth'
import { RegisterFormData, useRegisterForm } from '@/context/register-form'
import Auth from '@/utils/auth'
import { getErrorMessage } from '@/utils/error'
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Group, PasswordInput, Progress, Text, TextInput, Image } from '@mantine/core'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import logo from '@/assets/images/logo.png'
import logoSmall from '@/assets/images/logo_small.png'

export default function RegisterPage(): ReactElement {
  const { t } = useTranslation('auth')
  const [alert, setAlert] = useState<[AlertType, string] | null>(null)
  const [disable, setDisable] = useState(false)

  const form = useRegisterForm({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => (value.length >= 8 ? null : 'Password must be at least 8 characters'),
      confirmPassword: (value, values) => (value === values.password ? null : 'Passwords do not match'),
    },
  })

  const strength = Auth.getStrength(form.values.password)
  const checks = Auth.requirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(form.values.password)} />
  ))

  const bars = Array(3)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ section: { transitionDuration: '0ms' } }}
        value={form.values.password.length > 0 && index === 0 ? 100 : strength >= ((index + 1) / 4) * 100 ? 100 : 0}
        color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
        key={index}
        size={4}
      />
    ))

  async function onSubmit(value: RegisterFormData) {
    try {
      // evt.preventDefault()
      setDisable(true)

      const { email, password, confirmPassword } = value

      // Check for empty field(s)
      if (!email || !password) throw new Error('generic.missingField')

      // Check for confirm password
      if (password !== confirmPassword) throw new Error('auth.confirmMismatch')

      // Create user account and send verification email
      await Auth.createAccountAndSendVerification(email, password)
    } catch (err) {
      setAlert(['danger', getErrorMessage(err)])
      setDisable(false)
    }
  }

  return (
    <Protected state={AuthState.LOGOUT} redirect className='h-full'>
      <div className="container max-w-none py-0 mx-auto px-6 sm:px-14 md:px-20 lg:px-36 xl:px-80 h-full w-full inline-block overflow-auto">
        <div className={'flex justify-center px-7 py-14 items-center'}>
          <Image src={logo} alt="logo" className="rounded-xl hidden sm:block" />
          <div className="md:min-w-[480px]">
            <form className="space-y-4 max-sm:min-w-[360px]" onSubmit={form.onSubmit((v) => onSubmit(v) as unknown)}>
              <Image src={logoSmall} alt="logo" className="rounded-xl sm:hidden max-h-36 max-w-36 mx-auto" />
              <h1 className="font-sans text-3xl font-bold text-black dark:text-white">{t('mainHeading.register')}</h1>
              <h6 className="font-sans text-lg text-black dark:text-white">{t('subHeading.register')}</h6>
              {alert != null && <Alert type={alert[0]} message={alert[1]} />}
              <div className="space-y-2">
                <TextInput
                  type="email"
                  label={t('label.email')}
                  leftSection={<FontAwesomeIcon icon={faEnvelope} />}
                  size="md"
                  placeholder={t('placeholder.email')}
                  autoComplete="email"
                  required
                  classNames={{ label: 'font-sans mb-2', input: 'font-sans mb-4' }}
                  {...form.getInputProps('email')}
                />
                <PasswordInput
                  type="password"
                  label={t('label.password')}
                  leftSection={<FontAwesomeIcon icon={faKey} />}
                  size="md"
                  placeholder={t('placeholder.password')}
                  autoComplete="new-password"
                  required
                  classNames={{ label: 'font-sans mb-2', input: 'font-sans mb-4' }}
                  {...form.getInputProps('password')}
                />
                <Group gap={5} grow mt="xs" mb="md">
                  {bars}
                </Group>

                <PasswordRequirement label="label.requirementChar" meets={form.values.password.length >= 8} />
                {checks}
                <PasswordInput
                  type="password"
                  label={t('label.confirm')}
                  leftSection={<FontAwesomeIcon icon={faKey} />}
                  size="md"
                  placeholder={t('placeholder.confirm')}
                  autoComplete="new-password"
                  required
                  classNames={{ label: 'font-sans mb-2', input: 'font-sans mb-4' }}
                  {...form.getInputProps('confirmPassword')}
                />
              </div>
              <div>
                <Button
                  type="submit"
                  disabled={disable}
                  loading={disable}
                  fullWidth
                  className="bg-primary-300 hover:bg-primary-400 font-sans text-base"
                >
                  {t('action.register')}
                </Button>
              </div>
              <Text className="font-sans text-center text-base mt-4">
                {t('guide.login')}
                <Link to="/login" className="font-sans text-primary-300 hover:text-primary-400">
                  {' '}{t('link.login')}
                </Link>
              </Text>
              <ThirdParty separator="top" />
            </form>
          </div>
        </div>
      </div>
    </Protected>
  )
}
