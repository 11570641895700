import Alert, { AlertType } from '@/components/alert'
import Protected from '@/components/protected'
import ThirdParty from '@/components/third-party'
import { AuthState } from '@/constants/auth'
import Auth from '@/utils/auth'
import { getErrorMessage } from '@/utils/error'
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextInput, Image } from '@mantine/core'
import { FormEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Location, useLocation, useParams } from 'react-router-dom'
import logo from '@/assets/images/logo.png'
import logoSmall from '@/assets/images/logo_small.png'
import i18n from '@/i18n'

export interface LoginPageState {
  redirect?: string
}

export default function LoginPage(): ReactElement {
  const [alert, setAlert] = useState<[AlertType, string] | null>(null)
  const [disable, setDisable] = useState(false)
  const [input, setInput] = useState({ email: '', password: '' })
  const { lang } = useParams()

  const { state } = useLocation() as Location<LoginPageState>
  const { t } = useTranslation('auth')

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang)
  }, [lang])

  async function onSubmit(evt: FormEvent<HTMLFormElement>): Promise<void> {
    try {
      evt.preventDefault()
      setDisable(true)

      const { email, password } = input

      // Check for empty field(s)
      if (!email || !password) throw new Error('generic.missingField')

      // Sign in user account
      await Auth.loginByAccount(email, password, state?.redirect)
    } catch (err) {
      // Show error message
      setAlert(['danger', getErrorMessage(err)])
      setDisable(false)
    }
  }

  return (
    <Protected state={AuthState.LOGOUT} redirect className='h-full'>
      <div className="container max-w-none py-0 mx-auto px-6 sm:px-14 md:px-20 lg:px-36 xl:px-80 h-full w-full inline-block overflow-auto">
        <div className={'flex justify-center px-7 py-14 items-center'}>
          <Image src={logo} alt="logo" className="rounded-xl hidden sm:block" />
          <div className="md:min-w-[480px]">
            <form className="space-y-4 max-sm:min-w-[360px]" onSubmit={onSubmit}>
              <Image src={logoSmall} alt="logo" className="rounded-xl sm:hidden max-h-36 max-w-36 mx-auto" />
              <h1 className="font-sans text-3xl font-bold text-black dark:text-white">{t('mainHeading.login')}</h1>
              <h6 className="font-sans text-lg text-black dark:text-white">{t('subHeading.login')}</h6>
              {alert != null && <Alert type={alert[0]} message={alert[1]} />}
              <div className="space-y-2">
                <TextInput
                  type="email"
                  label={t('label.email')}
                  leftSection={<FontAwesomeIcon icon={faEnvelope} />}
                  size="md"
                  onChange={(evt) => setInput({ ...input, email: evt.target.value })}
                  placeholder={t('placeholder.email')}
                  autoComplete="email"
                  classNames={{ label: 'font-sans mb-2', input: 'font-sans mb-4' }}
                />
                <TextInput
                  type="password"
                  label={t('label.password')}
                  leftSection={<FontAwesomeIcon icon={faKey} />}
                  size="md"
                  onChange={(evt) => setInput({ ...input, password: evt.target.value })}
                  placeholder={t('placeholder.password')}
                  autoComplete="current-password"
                  classNames={{ label: 'font-sans mb-2', input: 'font-sans' }}
                />
              </div>
              <div className="flex justify-between items-center">
                <Link to="/reset" className="font-sans text-primary-300 hover:text-palette2-300 mb-3">
                  {t('link.reset')}
                </Link>
              </div>
              <div>
                <button
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font- font-sans text-white ${
                    disable ? 'bg-gray-400' : 'bg-primary-300 hover:bg-primary-400'
                  } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  type="submit"
                  disabled={disable}
                >
                  {t('action.login')}
                </button>
              </div>
              <p className="font-sans text-center text-base mt-4 text-black dark:text-gray-100">
                {t('guide.register')}{' '}
                <Link to="/register" className="font-sans text-primary-300 hover:text-primary-400">
                  {t('link.register')}
                </Link>
              </p>
              <ThirdParty separator="top" />
            </form>
          </div>
        </div>
      </div>
    </Protected>
  )
}
